// GLOBAL IMPORTS
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

// LOCAL IMPORTS
import { ButtonCallWaitress, ButtonRequestPayment } from '../common/Buttons'

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  left: 0;
  flex: 1 1 auto;
  padding: 1em;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`

ActionBar.displayName = 'ActionBar'
export default ActionBar

// ACTION BAR FORM
export const ActionBarCustomer = ({ onCallWaitress, onRequestPayment }) => {
  const handleCallWaitress = () => {
    if (onCallWaitress) {
      onCallWaitress()
    }
  }

  const handleRequestPayment = () => {
    if (onRequestPayment) {
      onRequestPayment()
    }
  }

  return (
    <ActionBar>
      <Row justify="end">
        <Col span={6}>
          <ButtonCallWaitress onClick={handleCallWaitress} />
        </Col>
        <Col span={6}>
          <ButtonRequestPayment onClick={handleRequestPayment} />
        </Col>
      </Row>
    </ActionBar>
  )
}

ActionBarCustomer.propTypes = {
  onCallWaitress: PropTypes.func,
  onRequestPayment: PropTypes.func,
}
