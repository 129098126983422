import React from 'react'

import { IRoute } from '../../routes/index'

export default ({ children }: IEmptyLayoutProps) => {
  return <>{children}</>
}

export interface IEmptyLayoutProps {
  children?: React.ReactNode
  authorizedRoutes?: IRoute[]
  deniedRoutes?: IRoute[]
  activeRoute?: IRoute
}
