// REACT
import React, { useCallback, useEffect, useRef } from 'react'

// LOCAL IMPORTS
import { Counter, Inputs, ListItem } from '../../common'
import { ViewForm, ViewList } from '../../view-layout'
import OrderOfferItem from './OrderOfferItem'

// REPOS
import { INote, IOrderOfferItem } from '../../../models/admin/orders'
import { TRenderItem, VirtualList } from '../../common/VirtualList'
import { useTranslation } from 'react-i18next'

const { TextArea } = Inputs

const OrderOfferForm = ({ orderOffers, onSubmit, onDiscard }: IOrderOfferFormProps) => {
  // STATE
  const selectedOffers = useRef<IOrderOfferItem[]>([])

  const handleChangeCount = useCallback((orderOffer: IOrderOfferItem, count: number) => {
    const offerIndex = selectedOffers.current.findIndex(
      ({ offer }) => offer._id === orderOffer.offer._id,
    )

    if (offerIndex === -1) {
      // NOT FOUND - ADD NEW
      selectedOffers.current.push({ ...orderOffer, amount: count })
    } else {
      // FOUND AND COUNT !== 0 - UPDATE
      selectedOffers.current[offerIndex].amount = count
    }
  }, [])

  const handleChangeNotes = useCallback((orderOffer: IOrderOfferItem, note: string) => {
    const offerIndex = selectedOffers.current.findIndex(
      ({ offer }) => offer._id === orderOffer.offer._id,
    )
    if (offerIndex === -1) {
      // NOT FOUND - ADD NEW
      selectedOffers.current.push({ ...orderOffer, note })
    } else {
      // FOUND AND COUNT !== 0 - UPDATE
      selectedOffers.current[offerIndex].note = note
    }
  }, [])
  const { t } = useTranslation()

  const renderItem: TRenderItem = useCallback(
    (item: IOrderOfferItem, index, refreshRender) => {
      // TRY USING THE SELECTED OFFER
      const selectedOrderOffer = selectedOffers.current.find(
        ({ offer }) => offer._id === item.offer._id,
      )
      const orderOffer = selectedOrderOffer || item

      const { offer, amount, pending, note, menu } = orderOffer
      const { _id, name, price } = offer

      const counterComponent = (
        <Counter
          initialCount={pending || amount}
          min={0}
          orientation="row"
          onChange={(count: number) => {
            handleChangeCount(item, count)
            refreshRender(index)
          }}
        />
      )

      const notesComponent = (
        <TextArea
          help={t('msg_insert_note')}
          helpPlacement="topLeft"
          placeholder={t('msg_insert_note')}
          autoSize={{ minRows: 1, maxRows: 1 }}
          onChange={(e) => {
            handleChangeNotes(orderOffer, e.target.value)
            refreshRender(index)
          }}
          defaultValue={note}
        />
      )

      return (
        <ListItem key={`${menu}-${_id}`}>
          <OrderOfferItem
            name={name}
            counter={counterComponent}
            notes={notesComponent}
            price={price}
          />
        </ListItem>
      )
    },
    [handleChangeNotes, handleChangeCount, t],
  )

  const getValidOffers = useCallback(
    (orderOffers: IOrderOfferItem[]) => orderOffers.filter(({ amount }) => amount > 0),
    [],
  )

  const handleSubmit = useCallback(() => {
    onSubmit?.(getValidOffers(selectedOffers.current))
  }, [onSubmit, getValidOffers, selectedOffers])

  useEffect(() => {
    selectedOffers.current = getValidOffers(orderOffers)
  }, [orderOffers, getValidOffers])

  const groupGen = useCallback((items: IOrderOfferItem[]) => {
    const group: { [key: string]: number[] } = {}

    items.forEach((item, index) => {
      const cat = item.offer.category?.name || ''

      if (!group[cat]) {
        group[cat] = []
      }

      group[cat].push(index)
    })

    return group
  }, [])

  return (
    <ViewForm onDone={onSubmit && handleSubmit} onDiscard={onDiscard}>
      <ViewList items={orderOffers} searchable={true}>
        <VirtualList renderItem={renderItem} groupBy={groupGen} bottomOffset={200} />
      </ViewList>
    </ViewForm>
  )
}

export interface IOrderOfferFormProps {
  orderOffers: IOrderOfferItem[]
  orderOfferNotes?: INote[]
  onSubmit?: (order: IOrderOfferItem[]) => unknown
  onDiscard?: () => unknown
}

export default OrderOfferForm
