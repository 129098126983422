// REACT
import React from 'react'

// GLOBAL IMPORTS

// LOCAL IMPORTS
import { ViewList } from '../view-layout'

import OrderList from './OrderList'

import { IOrderListItem, IOrderOfferItem, TOrderStatus } from '../../models/admin/orders'
import { IOrderOfferStatusResponse } from './offers/OrderOfferStatus'

const OrderListView = ({
  orders,
  orderStatusList = [],
  loading = false,
  group = false,
  groupByPending = false,
  actions = [],
  messageBtn,
  // EVENT HANDLERS

  onClickOrder,
  onAdd,
  onChangeOrderOfferStatus,
  onChangeOrderStatus,
}: IOrderListViewProps) => {
  return (
    <ViewList items={orders} loading={loading} searchable={true} messageBtn={messageBtn} actions={actions} onAdd={onAdd}>
      <OrderList
        group={group}
        orderStatusList={orderStatusList}
        onClickItem={onClickOrder}
        groupByPending={groupByPending}
        onChangeOrderOfferStatus={onChangeOrderOfferStatus}
        onChangeOrderStatus={onChangeOrderStatus}
      />
    </ViewList>
  )
}

export interface IOrderListViewProps {
  orders: IOrderListItem[]
  orderStatusList?: { title: string; value: TOrderStatus }[]
  loading?: boolean
  group?: boolean
  groupByPending?: boolean
  actions?: React.ReactNode[]
  messageBtn?: React.ReactNode
  // EVENT HANDLERS
  onClickOrder?: (order: IOrderListItem) => unknown
  onAdd?: () => void | Promise<void>
  onChangeOrderOfferStatus?: (
    order: IOrderListItem,
    offer: IOrderOfferItem,
    status: IOrderOfferStatusResponse,
  ) => unknown
  onChangeOrderStatus?: (order: IOrderListItem, status: TOrderStatus) => unknown
}

export type { IOrderOfferItem, IOrderOfferStatusResponse }

export default React.memo(OrderListView)
