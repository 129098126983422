// GLOBAL IMPORTS
import { Col, Form, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";

// LOCAL IMPORTS
import { Buttons, Inputs, Modal, Section } from "../common";
import { Container, ViewForm } from "../view-layout";

import OrderOfferForm from "./offers/OrderOfferForm";
import OrderOfferList from "./offers/OrderOfferList";

// INTERFACES
import { Devices, useDevice } from "../../hooks/device.hook";
import {
  EOrderOfferStatus,
  IOrderForm,
  IOrderOfferItem,
  TOrderStatus,
} from "models/admin/orders";
import { IOfferListItem } from "models/admin/offer.repository";
import { ITableListItem } from "models/admin/table.repository";
import { usePointer } from "../../hooks/state.hook";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const { InputSelect } = Inputs;
const { ButtonAdd } = Buttons;

// FUNCTIONAL COMPONENT
const OrderForm = ({
  order,
  loading,
  tables,
  offers,
  onSubmit,
  onDiscard,
  clientMarks,
  onTableChange,
}: IOrderFormProps) => {
  const [form] = Form.useForm();
  // STATE
  const [isOpenOfferForm, setIsOpenOfferForm] = useState<boolean>(false);
  const device = useDevice();
  const [selectedOffers, setSelectedOffers] = useState<IOrderOfferItem[]>([]);
  const [tableId, setTableId] = useState<string | null>(null);
  const { t } = useTranslation();

  const $selectedOffers = usePointer(selectedOffers);
  // DATA HANDLERS
  const parseOffers = useCallback(
    (offers: IOfferListItem[]): IOrderOfferItem[] => {
      return offers.map((offer) => {
        const offerInOrder = $selectedOffers.current.find(
          ({ offer: orderOffer }) => {
            const orderOfferId = orderOffer._id;

            return orderOfferId === offer._id;
          }
        );

        return {
          offer,
          amount: offerInOrder?.amount || 0,
          status: offerInOrder?.status || EOrderOfferStatus.PENDING,
          menu: offer.menu || "",
          note: offerInOrder?.note,
        };
      });
    },
    [$selectedOffers]
  );

  // EVENT HANDLERS
  const showOfferForm = useCallback(() => {
    setIsOpenOfferForm(true);
  }, []);

  const hideOfferForm = useCallback(() => {
    setIsOpenOfferForm(false);
  }, []);

  const handleDone = useCallback(() => {
    form.submit();
  }, [form]);

  const handleAddClient = useCallback(() => {
    form.setFieldsValue({
      orderMark: clientMarks[clientMarks.length - 1] + 1 || 1,
    });
  }, [form, clientMarks]);

  const handleSubmitForm = useCallback(
    (orderData: IOrderForm) => {
      if ($selectedOffers.current?.length) {
        const notes = $selectedOffers.current
          .filter(({ note }) => note)
          .map(({ offer, note }) => ({ offer: offer._id, note: note || "" }));

        const offers = $selectedOffers.current.map(
          ({ offer, status, menu, amount }) => {
            return {
              offer,
              status,
              menu,
              amount,
            };
          }
        );

        onSubmit?.({ ...orderData, offers, notes }, orderData?.orderMark || 1);
      }
    },
    [onSubmit, $selectedOffers]
  );

  const handleDiscard = useCallback(() => {
    form.resetFields();
    onDiscard?.();
  }, [onDiscard, form]);

  const handleTableChange = useCallback(
    (tableId: string) => {
      setTableId(tableId);
      onTableChange?.(tableId);
    },
    [onTableChange]
  );

  const handleSubmitOffers = useCallback(
    (orderOffers: IOrderOfferItem[]) => {
      setSelectedOffers(orderOffers);
      hideOfferForm();
    },
    [hideOfferForm]
  );

  const handleDiscardOffers = useCallback(() => {
    hideOfferForm();
  }, [hideOfferForm]);

  useEffect(() => {
    if (order && order.table) {
      setTableId(order.table);
    }
    const orderOffers = order?.offers || [];
    setSelectedOffers(orderOffers);

    return () => form.resetFields();
  }, [order, form]);

  return (
    <ViewForm
      loading={loading}
      onDone={onSubmit && handleDone}
      onDiscard={onDiscard && handleDiscard}
    >
      <Form
        form={form}
        onFinish={handleSubmitForm}
        className="padding-content"
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
        }}
      >
        <Row gutter={8}>
          <Col span={device === Devices.MOBILE ? 12 : 16}>
            {/* TABLE SELECTOR */}
            <Form.Item name="table" initialValue={tableId}>
              <InputSelect
                choices={tables.map(({ _id, identifier }) => ({
                  value: _id,
                  title: identifier,
                }))}
                help={t("msg_select_table")}
                placeholder={t("table")}
                helpPlacement="topRight"
                onChange={onTableChange && handleTableChange}
              />
            </Form.Item>
          </Col>
          <Col span={device === Devices.MOBILE ? 12 : 8}>
            {/* Order slot */}
            <TableCommanderBox>
              <Form.Item name="orderMark" style={{ paddingRight: 10 }}>
                <InputSelect
                  placeholder={t("client")}
                  choices={clientMarks.map((v) => ({
                    title: v.toString(),
                    value: v,
                  }))}
                  help={t("msg_select_client")}
                  helpPlacement="topRight"
                />
              </Form.Item>
              <ButtonAdd
                help={t("msg_insert_client")}
                helpPlacement="topRight"
                onClick={handleAddClient}
              >
                {""}
              </ButtonAdd>
            </TableCommanderBox>
          </Col>
        </Row>
        <Row style={{ flexGrow: 100 }}>
          <Container
            className="order-form-offers"
            justifyContent="stretch"
            alignItems="stretch"
            flexDirection="column"
          >
            {/* ORDER OFFERS */}
            <Section title={t("orders")} style={{ flexGrow: 1 }}>
              <Container
                className="order-form-offers-list"
                justifyContent="stretch"
                alignItems="stretch"
                flexDirection="column"
              >
                <Container scrollable className="">
                  <OrderOfferList items={selectedOffers} />
                </Container>
              </Container>
            </Section>
            <Container fixed className="padding-content">
              <ButtonAdd
                block
                onClick={showOfferForm}
                help={
                  offers?.length
                    ? t("msg_select_offer")
                    : t("msg_table_no_offers")
                }
                disabled={!offers?.length}
              >
                {t("msg_manage_orders")}
              </ButtonAdd>
            </Container>
          </Container>
        </Row>
      </Form>

      {/* OFFER SELECTOR */}
      <Modal
        title={t("msg_select_offer")}
        open={isOpenOfferForm}
        onClose={hideOfferForm}
        height="100%"
        destroyOnClose={true}
      >
        <OrderOfferForm
          orderOffers={parseOffers(
            offers.map(
              ({ _id, name, price, tags, category, isEnabled, menu }) => ({
                _id,
                name,
                price,
                tags,
                category,
                isEnabled,
                menu,
              })
            )
          )}
          onSubmit={handleSubmitOffers}
          onDiscard={handleDiscardOffers}
        />
      </Modal>
    </ViewForm>
  );
};

export interface IOrderFormProps {
  order: IOrderForm | null;
  loading?: boolean;
  tables: ITableListItem[];
  offers: IOfferListItem[];
  orderStatuses: { title: string; value: TOrderStatus }[];
  onSubmit?: (orderData: IOrderForm, clientNumber: number) => unknown;
  onDiscard?: () => unknown;
  clientMarks: number[];
  onTableChange?: (tableId: string) => unknown;
}

export default OrderForm;

// TODO: Refactor to isolate the component
const TableCommanderBox = styled.div`
  display: flex;
`;
