import {
  EAdminSections,
  EMPLOYEE_PERMISSIONS_MAPPING,
  EPermission,
  ERoles,
  IAccessors,
  IEmployee,
  IEmployeeForm,
  IEmployeeListItem,
  TAccessors,
  TP,
  TPermission,
  TRoles,
  TSections,
} from './types'
import EmployeeRepository from './employee.repository'

export type {
  // EMPLOYEE
  IEmployee,
  IEmployeeForm,
  IEmployeeListItem,
  // PERMISSIONS
  TPermission,
  TP,
  // ROLES
  TRoles,
  // SECTIONS
  TSections,
  // ACCESSORS
  IAccessors,
  TAccessors,
}

export { EPermission, ERoles, EAdminSections, EMPLOYEE_PERMISSIONS_MAPPING }

export const Roles: TRoles = {
  [ERoles.WAITRESS]: {
    [EAdminSections.DASHBOARD]: /*-----*/ [EPermission.READ],
    [EAdminSections.ORDERS]: /*--------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.MENUS]: /*---------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.PRODUCTS]: /*------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.OFFERS]: /*--------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.CATEGORIES]: /*----*/ [EPermission.READ],
    [EAdminSections.AREAS]: /*---------*/ [EPermission.READ],
    [EAdminSections.TABLES]: /*--------*/ [EPermission.READ],
    [EAdminSections.TAGS]: /*----------*/ [EPermission.READ],
    [EAdminSections.UNITS_MEASURE]: /*-*/ [EPermission.READ],
    [EAdminSections.SESSIONS]: /*------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.EMPLOYEES]: /*-----*/ [EPermission.READ],
    [EAdminSections.SETTINGS]: /*------*/ [],
    [EAdminSections.USERS]: /*---------*/ [],
    [EAdminSections.LICENSE]: /*-------*/ [],
    [EAdminSections.CONVERSIONS]: /*---*/ [EPermission.READ],
    [EAdminSections.REPORTS]: /*-------*/ [],
    [EAdminSections.INVENTORY]: /*-----*/ [],
    [EAdminSections.OFFERS_EXISTENCE]: [],
    [EAdminSections.REVIEWS]: [],
  },
  [ERoles.BARMAN]: {
    [EAdminSections.DASHBOARD]: /*-----*/ [EPermission.READ],
    [EAdminSections.ORDERS]: /*--------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.MENUS]: /*---------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.PRODUCTS]: /*------*/ [EPermission.READ],
    [EAdminSections.OFFERS]: /*--------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.CATEGORIES]: /*----*/ [EPermission.READ],
    [EAdminSections.AREAS]: /*---------*/ [EPermission.READ],
    [EAdminSections.TABLES]: /*--------*/ [EPermission.READ],
    [EAdminSections.TAGS]: /*----------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.UNITS_MEASURE]: /*-*/ [EPermission.READ],
    [EAdminSections.SESSIONS]: /*------*/ [],
    [EAdminSections.EMPLOYEES]: /*-----*/ [EPermission.READ],
    [EAdminSections.SETTINGS]: /*------*/ [],
    [EAdminSections.USERS]: /*---------*/ [],
    [EAdminSections.LICENSE]: /*-------*/ [],
    [EAdminSections.CONVERSIONS]: /*---*/ [EPermission.READ],
    [EAdminSections.REPORTS]: /*-------*/ [],
    [EAdminSections.INVENTORY]: /*-----*/ [],
    [EAdminSections.OFFERS_EXISTENCE]: [],
    [EAdminSections.REVIEWS]: [],
  },
  [ERoles.COOKER]: {
    [EAdminSections.DASHBOARD]: /*-----*/ [EPermission.READ],
    [EAdminSections.ORDERS]: /*--------*/ [EPermission.READ],
    [EAdminSections.MENUS]: /*---------*/ [],
    [EAdminSections.PRODUCTS]: /*------*/ [EPermission.READ],
    [EAdminSections.OFFERS]: /*--------*/ [EPermission.READ],
    [EAdminSections.CATEGORIES]: /*----*/ [EPermission.READ],
    [EAdminSections.AREAS]: /*---------*/ [],
    [EAdminSections.TABLES]: /*--------*/ [],
    [EAdminSections.TAGS]: /*----------*/ [],
    [EAdminSections.UNITS_MEASURE]: /*-*/ [],
    [EAdminSections.SESSIONS]: /*------*/ [],
    [EAdminSections.EMPLOYEES]: /*-----*/ [],
    [EAdminSections.SETTINGS]: /*------*/ [],
    [EAdminSections.USERS]: /*---------*/ [],
    [EAdminSections.LICENSE]: /*-------*/ [],
    [EAdminSections.CONVERSIONS]: /*---*/ [],
    [EAdminSections.REPORTS]: /*-------*/ [],
    [EAdminSections.INVENTORY]: /*-----*/ [],
    [EAdminSections.OFFERS_EXISTENCE]: [],
    [EAdminSections.REVIEWS]: [],
  },
  [ERoles.SECURITY]: {
    [EAdminSections.DASHBOARD]: /*-----*/ [EPermission.READ],
    [EAdminSections.ORDERS]: /*--------*/ [],
    [EAdminSections.MENUS]: /*---------*/ [],
    [EAdminSections.PRODUCTS]: /*------*/ [],
    [EAdminSections.OFFERS]: /*--------*/ [EPermission.READ],
    [EAdminSections.CATEGORIES]: /*----*/ [],
    [EAdminSections.AREAS]: /*---------*/ [],
    [EAdminSections.TABLES]: /*--------*/ [],
    [EAdminSections.TAGS]: /*----------*/ [],
    [EAdminSections.UNITS_MEASURE]: /*-*/ [],
    [EAdminSections.SESSIONS]: /*------*/ [],
    [EAdminSections.EMPLOYEES]: /*-----*/ [],
    [EAdminSections.SETTINGS]: /*------*/ [],
    [EAdminSections.USERS]: /*---------*/ [],
    [EAdminSections.LICENSE]: /*-------*/ [],
    [EAdminSections.CONVERSIONS]: /*---*/ [EPermission.READ],
    [EAdminSections.REPORTS]: /*-------*/ [],
    [EAdminSections.INVENTORY]: /*-----*/ [],
    [EAdminSections.OFFERS_EXISTENCE]: [],
    [EAdminSections.REVIEWS]: [],
  },
  [ERoles.ACCOUNTANT]: {
    [EAdminSections.DASHBOARD]: /*-----*/ [EPermission.READ],
    [EAdminSections.ORDERS]: /*--------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.MENUS]: /*---------*/ [EPermission.READ],
    [EAdminSections.PRODUCTS]: /*------*/ [EPermission.READ],
    [EAdminSections.OFFERS]: /*--------*/ [EPermission.READ],
    [EAdminSections.CATEGORIES]: /*----*/ [EPermission.READ],
    [EAdminSections.AREAS]: /*---------*/ [EPermission.READ],
    [EAdminSections.TABLES]: /*--------*/ [EPermission.READ, EPermission.WRITE],
    [EAdminSections.TAGS]: /*----------*/ [EPermission.READ],
    [EAdminSections.UNITS_MEASURE]: /*-*/ [EPermission.READ],
    [EAdminSections.SESSIONS]: /*------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.EMPLOYEES]: /*-----*/ [EPermission.READ],
    [EAdminSections.SETTINGS]: /*------*/ [EPermission.READ],
    [EAdminSections.USERS]: /*---------*/ [EPermission.READ],
    [EAdminSections.LICENSE]: /*-------*/ [EPermission.READ],
    [EAdminSections.CONVERSIONS]: /*---*/ [EPermission.READ],
    [EAdminSections.REPORTS]: /*-------*/ [EPermission.READ],
    [EAdminSections.INVENTORY]: /*-----*/ [],
    [EAdminSections.OFFERS_EXISTENCE]: [],
    [EAdminSections.REVIEWS]: [],
  },
  [ERoles.ADMIN]: {
    [EAdminSections.DASHBOARD]: /*-----*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.ORDERS]: /*--------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.MENUS]: /*---------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.PRODUCTS]: /*------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.OFFERS]: /*--------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.CATEGORIES]: /*----*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.AREAS]: /*---------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.TABLES]: /*--------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.TAGS]: /*----------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.UNITS_MEASURE]: /*-*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.SESSIONS]: /*------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.EMPLOYEES]: /*-----*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.SETTINGS]: /*------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.USERS]: /*---------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.LICENSE]: /*-------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.CONVERSIONS]: /*---*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.REPORTS]: /*-------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.INVENTORY]: /*-----*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.OFFERS_EXISTENCE]: [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
    [EAdminSections.REVIEWS]: /*-------*/ [EPermission.READ, EPermission.WRITE, EPermission.DELETE],
  },
}

export const Sections = [
  EAdminSections.DASHBOARD,
  EAdminSections.ORDERS,
  EAdminSections.MENUS,
  EAdminSections.PRODUCTS,
  EAdminSections.OFFERS,
  EAdminSections.CATEGORIES,
  EAdminSections.AREAS,
  EAdminSections.TABLES,
  EAdminSections.TAGS,
  EAdminSections.UNITS_MEASURE,
  EAdminSections.SESSIONS,
  EAdminSections.EMPLOYEES,
  EAdminSections.SETTINGS,
  EAdminSections.USERS,
  EAdminSections.LICENSE,
  EAdminSections.CONVERSIONS,
  EAdminSections.REPORTS,
  EAdminSections.INVENTORY,
  EAdminSections.OFFERS_EXISTENCE,
  EAdminSections.REVIEWS,
]

export default EmployeeRepository
