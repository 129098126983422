// GLOBAL IMPORTS
import React from 'react'

// LOCAL IMPORTS
import authRoutes from './auth.routes'
import Routes, { IRoutesProps } from '../Routes'

const AuthRoutes = ({ layout, notFoundUrl, forbiddenUrl }: IAuthRoutesProps) => {
  return (
    <Routes
      layout={layout}
      forbiddenUrl={forbiddenUrl}
      notFoundUrl={notFoundUrl}
      routes={authRoutes}
    />
  )
}

export type IAuthRoutesProps = Pick<IRoutesProps, 'notFoundUrl' | 'forbiddenUrl' | 'layout'>

export default AuthRoutes
