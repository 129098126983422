import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

// LOCAL IMPORT
import { Buttons, DeleteConfirmation } from '../common'
import ActionBar from './ActionBar'
import ViewLayout from './ViewLayout'

const { ButtonEdit, ButtonDelete } = Buttons

interface IViewDetailsProps extends React.HTMLAttributes<HTMLDivElement> {
  onEdit?: () => void
  onDelete?: () => void
  loading?: boolean
  children?: React.ReactNode
}

interface IActionBarDetailsProps {
  onEdit?: () => void
  onDelete?: () => void
}
// ACTION BAR DETAILS
const ActionBarDetails = ({ onEdit, onDelete }: IActionBarDetailsProps) => {
  const [deleteConfirmationVisibility, setDeleteConfirmationVisibility] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleDiscardDelete = () => {
    setDeleteConfirmationVisibility(false)
  }

  const handleClickDelete = () => {
    setDeleteConfirmationVisibility(true)
  }

  const handleDelete =
    onDelete &&
    (() => {
      setDeleteConfirmationVisibility(false)
      onDelete()
    })

  const handleEdit =
    onEdit &&
    (() => {
      if (deleteConfirmationVisibility) {
        setDeleteConfirmationVisibility(false)
      }
      onEdit()
    })

  return onEdit || onDelete ? (
    <ActionBar>
      {onEdit ? <ButtonEdit style={{ flex: '1 1 auto' }} onClick={handleEdit} /> : null}
      {onDelete && (
        <DeleteConfirmation
          visible={deleteConfirmationVisibility}
          title={t('msg_confirm_delete')}
          message={t('msg_confirm_delete_message')}
          onConfirm={handleDelete}
          onDiscard={handleDiscardDelete}
        >
          <ButtonDelete style={{ flex: '1 1 auto' }} onClick={handleClickDelete} />
        </DeleteConfirmation>
      )}
    </ActionBar>
  ) : null
}

const ViewDetails = ({ children: Children, loading, onEdit, onDelete }: IViewDetailsProps) => {
  // STATE
  const handleDelete =
    onDelete &&
    (() => {
      onDelete()
    })
  const actionBar = <ActionBarDetails onEdit={onEdit} onDelete={handleDelete} />

  return (
    <ViewLayout loading={loading} actionBar={actionBar}>
      {Children}
    </ViewLayout>
  )
}

export default ViewDetails
