export enum EAdminSections {
  DASHBOARD /*-------*/ = 'dashboard',
  MENUS /*-----------*/ = 'menus',
  OFFERS /*----------*/ = 'offers',
  PRODUCTS /*--------*/ = 'products',
  CATEGORIES /*------*/ = 'categories',
  TAGS /*------------*/ = 'tags',
  ORDERS /*----------*/ = 'orders',
  AREAS /*-----------*/ = 'areas',
  TABLES /*----------*/ = 'tables',
  EMPLOYEES /*-------*/ = 'employees',
  SETTINGS /*--------*/ = 'settings',
  UNITS_MEASURE /*---*/ = 'units_measure',
  USERS /*-----------*/ = 'users',
  SESSIONS /*--------*/ = 'sessions',
  LICENSE /*---------*/ = 'license',
  CONVERSIONS /*-----*/ = 'conversions',
  REPORTS /*---------*/ = 'reports',
  INVENTORY /*-------*/ = 'inventories',
  OFFERS_EXISTENCE /**/ = 'offers_existence',
  REVIEWS /*---------*/ = 'reviews',
}

export enum EPublicSections {
  ORDERS = 'orders',
  MENUS = 'menus',
}

export enum ERoles {
  WAITRESS /*--*/ = 'ROLE_WAITRESS',
  BARMAN /*----*/ = 'ROLE_BARMAN',
  COOKER /*----*/ = 'ROLE_COOKER',
  SECURITY /*--*/ = 'ROLE_SECURITY',
  ACCOUNTANT /**/ = 'ROLE_ACCOUNTANT',
  ADMIN /*-----*/ = 'ROLE_ADMIN',
}
