// GLOBAL IMPORT
import React from 'react'
import styled from 'styled-components'

// LOCAL IMPORTS
import { Loader } from '../common'
import Container from './Container'

// INTERFACES
interface IViewLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  topBar?: React.ReactNode
  actionBar?: React.ReactNode
  loading?: boolean
}
// STYLED COMPONENTS
const LayoutWrapper = styled.div`
  display: flex;
  flex: 1 !important;
  flex-direction: column;
  overflow: hidden !important;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
`

const TopBar = styled.div`
  width: 100%;
  padding: 0.5em 1em;
  display: flex;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
  justify-content: flex-end;
  flex: 0 1 auto;
`
const ActionBarContainer = styled.div`
  border-top: 1px solid #f0f0f0;
  flex: 1 1 auto;
  display: flex;
`
const ViewLayout = ({
  children: Content,
  topBar: TopBarContent,
  actionBar: ActionBar,
  loading,
  ...props
}: IViewLayoutProps) => {
  const TopBarComponent = TopBarContent ? (
    <Container fixed>
      <TopBar>{TopBarContent}</TopBar>
    </Container>
  ) : null

  const ActionBarComponent = ActionBar ? (
    <Container className="view-layout-actionbar" fixed flexDirection="row">
      <ActionBarContainer>{ActionBar}</ActionBarContainer>
    </Container>
  ) : null

  return (
    <LayoutWrapper className="view-layout-wrapper" {...props}>
      <Loader loading={loading}>
        <Container
          className="view-layout-container"
          justifyContent="space-between"
          alignItems="stretch"
          flexDirection="column"
        >
          {TopBarComponent}
          <Container
            className="view-layout-central-container"
            alignItems="stretch"
            justifyContent="stretch"
            flexDirection="column"
          >
            <Container
              className="view-layout-central-container-scrollable"
              justifyContent="stretch"
              alignItems="stretch"
              scrollable
              flexDirection="column"
            >
              {Content}
            </Container>
          </Container>
          {ActionBarComponent}
        </Container>
      </Loader>
    </LayoutWrapper>
  )
}

export default ViewLayout
