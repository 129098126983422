import i18n from 'i18n'
import { lazy, Suspense } from 'react'

const LazyTagPage = lazy(() => import("./TagPage"));
const LazyAreaPage = lazy(() => import("./AreaPage"));
const LazyMenuPage = lazy(() => import("./MenuPage"));
const LazyOfferPage = lazy(() => import("./OfferPage"));
const LazyDashboard = lazy(() => import("./Dashboard"));
const LazyOrderPage = lazy(() => import("./OrderPage"));
const LazyTablePage = lazy(() => import("./TablePage"));
const LazyReviewPage = lazy(() => import("./ReviewPage"));
const LazyReportsPage = lazy(() => import("./ReportPage"));
const LazyProductPage = lazy(() => import("./ProductPage"));
const LazySettingPage = lazy(() => import("./SettingPage"));
const LazyEmployeePage = lazy(() => import("./EmployeePage"));
const LazyCategoryPage = lazy(() => import("./CategoryPage"));
const LazyConversionPage = lazy(() => import("./ConversionPage"));
const LazyOfferExistencePage = lazy(() => import("./OfferExistencePage"));
const LazyUnitsOfMeasurePage = lazy(() => import("./UnitsOfMeasurePage"));

export const TagPage = () => (<Suspense fallback="Loading..."><LazyTagPage /></Suspense>)
export const AreaPage = () => (<Suspense fallback="Loading..."><LazyAreaPage /></Suspense>)
export const MenuPage = () => (<Suspense fallback="Loading..."><LazyMenuPage /></Suspense>)
export const OfferPage = () => (<Suspense fallback="Loading..."><LazyOfferPage /></Suspense>)
export const Dashboard = () => (<Suspense fallback="Loading..."><LazyDashboard /></Suspense>)
export const OrderPage = () => (<Suspense fallback="Loading..."><LazyOrderPage /></Suspense>)
export const TablePage = () => (<Suspense fallback="Loading..."><LazyTablePage /></Suspense>)
export const ReviewPage = () => (<Suspense fallback="Loading..."><LazyReviewPage /></Suspense>)
export const ReportsPage = () => (<Suspense fallback="Loading..."><LazyReportsPage /></Suspense>)
export const ProductPage = () => (<Suspense fallback="Loading..."><LazyProductPage /></Suspense>)
export const SettingPage = () => (<Suspense fallback="Loading..."><LazySettingPage /></Suspense>)
export const EmployeePage = () => (<Suspense fallback="Loading..."><LazyEmployeePage /></Suspense>)
export const CategoryPage = () => (<Suspense fallback="Loading..."><LazyCategoryPage /></Suspense>)
export const ConversionPage = () => (<Suspense fallback="Loading..."><LazyConversionPage /></Suspense>)
export const OfferExistencePage = () => (<Suspense fallback="Loading..."><LazyOfferExistencePage /></Suspense>)
export const UnitsOfMeasurePage = () => (<Suspense fallback="Loading..."><LazyUnitsOfMeasurePage /></Suspense>)


export enum PageMode {
  LISTING = '',
  ADDING = 'adding',
  EDITING = 'editing',
  VIEWING = 'viewing',
  DELETING = 'deleting',
  NOTIFICATIONS = 'notifications',
}

export const formTitle = (mode: PageMode, text: string) => {
  switch (mode) {
    case PageMode.ADDING:
      return `${i18n.t('add')} ${text}:`
    case PageMode.EDITING:
      return `${i18n.t('editTxt')} ${text}:`
    case PageMode.VIEWING:
      return `${i18n.t('detail')}:`
  }

  return i18n.t('loadingTxt')
}
