import { ActionButton } from 'components/notifications/NotificationAlert'
import { Badge } from 'antd'
import { IconNotifications } from 'components/common/Icons'
import { useGlobal } from 'reactn'
import React from 'react'
import styled from 'styled-components'

interface NotificationButtonProps {
  onClick(): void
}

export function NotificationButton(props: NotificationButtonProps) {
  const [notified] = useGlobal('notified')
  const { onClick } = props
  return (
    <NotificationBox>
      <Badge dot={notified} style={{ width: 8, height: 8 }}>
        <ActionButton
          shape="circle"
          style={{ boxShadow: 'none', border: 'none' }}
          icon={<IconNotifications className="text-primary" style={{ fontSize: '25px' }} />}
          onClick={onClick}
        />
      </Badge>
    </NotificationBox>
  )
}

const NotificationBox = styled.div`
  position: fixed;
  left: 15px;
  bottom: 13px;
`
