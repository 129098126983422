import { EAdminSections, ERoles } from 'types'

export enum EPermission {
  READ /*------*/ = 'read',
  WRITE /*-----*/ = 'write',
  DELETE /*----*/ = 'delete',
}

export type TPermission = EPermission

export { EAdminSections, ERoles }

export const EMPLOYEE_PERMISSIONS_MAPPING: { [key in EPermission]: string } = {
  [EPermission.DELETE]: 'Eliminar',
  [EPermission.READ]: 'Ver',
  [EPermission.WRITE]: 'Modificar',
}

export type TSections = EAdminSections

export type IAccessors<T> = Record<EAdminSections, T>

export type TAccessors = IAccessors<TP>

export type TP = TPermission[] // Type Permissions

export type TRoles = Record<ERoles, TAccessors>

export interface IPermissions {
  id: /*------*/ string
  name: /*----*/ string
  category: /**/ string
}

type PermissionRecord<K extends keyof any, T> = {
  [P in K]?: T
}

export interface IEmployeeForm {
  email: /*-------*/ string;
  username: /*----*/ string;
  roles: /*-------*/ ERoles[];
  permissions?: /**/ PermissionRecord<TSections, TPermission[]>;
  firstName: /*---*/ string;
  lastName: /*----*/ string;
  isEnabled: /*---*/ boolean;
  password: /*----*/ string;
  liveChatEnabled?: /*----*/ boolean;
}

export type IEmployeeListItem = IEmployee
export interface IEmployee extends IEmployeeForm {
  _id: string;
  userId: string;
}
