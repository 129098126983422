// GLOBAL IMPORTS
import React from 'react'

// LOCAL IMPORTS
import { IPageProps, Page } from 'components/common/page'

// CUSTOM HOOKS
const AdminPage = ({ ...props }: IAdminPageProps) => {
  return <Page {...props} />
}

export type IAdminPageProps = IPageProps

export default AdminPage
