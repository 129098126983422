// GLOBAL IMPORT
import { Grid } from 'antd'

// BREAKPOINT HOOK
const { useBreakpoint } = Grid
export { useBreakpoint }

export enum Devices {
  MOBILE /*-*/ = 'mobile',
  TABLET /*-*/ = 'tablet',
  DESKTOP /**/ = 'desktop',
}
export enum DeviceBreakpoints {
  MOBILE /*-*/ = 'sm',
  TABLET /*-*/ = 'lg',
  DESKTOP /**/ = 'xl',
}

// USE DEVICE
export const useDevice = () => {
  const screens = useBreakpoint()

  if (!screens[DeviceBreakpoints.MOBILE]) {
    return Devices.MOBILE
  } else if (!screens[DeviceBreakpoints.TABLET]) {
    return Devices.TABLET
  } else {
    return Devices.DESKTOP
  }
}
