import React, { useCallback, useEffect, useState } from 'react'
import styled, { CSSProperties } from 'styled-components'

// LOCAL IMPORTS
import { ButtonAdd, ButtonMinus } from './Buttons'
import { InputNumber } from './Inputs'

const Counter = ({
  min,
  max,
  count,
  initialCount = 0,
  orientation = 'row',
  onChange,
  inputStyle,
  ...props
}: ICounterProps) => {
  const [_count, setCount] = useState(count || initialCount)

  useEffect(() => {
    if (count) {
      setCount(count)
    }
  }, [count])

  const handleDecrement = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      setCount((v) => {
        onChange?.(v - 1)
        return v - 1
      })
    },
    [onChange],
  )

  const handleIncrement = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()
      setCount((v) => {
        onChange?.(v + 1)
        return v + 1
      })
    },
    [onChange],
  )

  const handleChange = useCallback(
    (count?: number | string) => {
      if (typeof count === 'number') {
        setCount(count)
        onChange?.(count)
      }
    },
    [onChange],
  )

  return (
    <CounterContainer orientation={orientation} {...props}>
      <ButtonContainer>
        <ButtonMinus
          iconOnly={true}
          disabled={min !== undefined && _count === min}
          onClick={onChange && handleDecrement}
        />
      </ButtonContainer>
      <InputContainer
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <InputNumber
          style={inputStyle}
          value={_count}
          min={min}
          max={max}
          onChange={onChange && handleChange}
        />
      </InputContainer>
      <ButtonContainer>
        <ButtonAdd
          iconOnly={true}
          disabled={max !== undefined && _count === max}
          onClick={onChange && handleIncrement}
        />
      </ButtonContainer>
    </CounterContainer>
  )
}

const CounterContainer = styled.div<{ orientation: TOrientation }>`
  display: flex;
  flex-direction: ${(props) => props.orientation};
  justify-content: center;
  align-items: center;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
  flex: 0 0 auto;
`

const ButtonContainer = styled.div`
  flex: 0 1 auto;
`

const InputContainer = styled.div`
  flex: 0 0 auto;
  text-align: center;
  align-self: center;
  justify-content: center;
`

export type TOrientation = 'row' | 'column' | 'row-reverse' | 'column-reverse'

export interface ICounterProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  min?: number
  max?: number
  count?: number
  initialCount?: number
  orientation?: TOrientation
  onChange?: (count: number) => unknown
  inputStyle?: CSSProperties
}

export default Counter
