import { IEmployee, IEmployeeForm, IEmployeeListItem } from './types'
import Axios from 'axios'
import BaseRepository, { HttpRequester } from '../../base.repository'

class EmployeeRepository extends BaseRepository<IEmployee, IEmployeeListItem, IEmployeeForm> {
  constructor(httpRequester: HttpRequester = Axios) {
    super(httpRequester, 'admin/employees')
  }

  // TODO: Implement this in the good way
  toForm(employee: IEmployeeListItem | null, defaults = {}): IEmployeeForm {
    if (employee) {
      return { ...employee, ...defaults }
    } else {
      return {
        email: '',
        username: '',
        roles: [],
        firstName: '',
        lastName: '',
        isEnabled: true,
        password: '',
        ...defaults,
      }
    }
  }
}

export default EmployeeRepository
