import {
  MdAlarm as clock,
  MdKeyboardArrowDown as Down,
  MdGroup as Employee,
  MdAdd,
  MdArrowBack,
  MdArrowForward,
  MdAttachMoney,
  MdChat,
  MdChevronRight,
  MdClear,
  MdCreate,
  MdDelete,
  MdDone,
  MdFilterList,
  MdFormatListBulleted,
  MdMenu,
  MdNotifications,
  MdPhoneInTalk,
  MdRemove,
  MdSearch,
  MdShoppingCart,
  // MdImportContacts as MenuBook,
  MdRestaurant as Restaurant,
  MdKeyboardArrowUp as Up,
} from "react-icons/md";

import { FiPhoneCall as CallWaitress } from "react-icons/fi";

import {
  AiOutlineDollar as BillPayment,
  AiOutlineClear as Clear,
  AiOutlineRead as MenuBook,
  AiOutlineStar as Star,
  AiOutlineLogin as StatusUpdate,
} from "react-icons/ai";
import {
  RiLogoutCircleRLine as Logout,
  RiPrinterFill as PrinterFill,
} from "react-icons/ri";

import {
  HiClipboardCopy as ClipboardCopy,
  HiOutlineUser,
  HiOutlineUserGroup,
} from "react-icons/hi";

import { VscCloseAll as CloseAll } from "react-icons/vsc";
import { GiStack as CurrentExistence } from "react-icons/gi";
import {
  BsCheckCircle as Delivered,
  BsClockHistory as InProgress,
  BsXCircle as Rejected,
} from "react-icons/bs";
import {
  FaDownload as ExportCSV,
  FaLayerGroup as Group,
  FaSortNumericDownAlt as MinAmount,
} from "react-icons/fa";
import { GrDocumentUpdate as Inventory } from "react-icons/gr";
import ADIcon from "@ant-design/icons";
import React from "react";

const Icon = ({ children, ...props }: any) => (
  <ADIcon {...props}>{children}</ADIcon>
);

export default Icon;

const buildIcon = (props: any, component: any) => (
  <Icon {...props} component={component} />
);

// A
// B
export const IconBack = (props: any) => buildIcon(props, MdArrowBack);
// C
export const IconCallWaitress = (props: any) => <CallWaitress {...props} />;
export const IconClear = (props: any) => <Clear {...props} />;
export const IconClock = (props: any) => buildIcon(props, clock);
export const IconClose = (props: any) => buildIcon(props, MdClear);
export const IconCopy = (props: any) => buildIcon(props, ClipboardCopy);
export const IconCurrentExistence = (props: any) =>
  buildIcon(props, CurrentExistence);
// D
export const IconDelete = (props: any) => buildIcon(props, MdDelete);
export const IconDelivered = (props: any) => <Delivered {...props} />;
export const IconDiscard = (props: any) => buildIcon(props, MdArrowBack);
export const IconDone = (props: any) => buildIcon(props, MdDone);
export const IconDown = (props: any) => buildIcon(props, Down);

// E
export const IconEdit = (props: any) => buildIcon(props, MdCreate);
export const IconEmployees = (props: any) => buildIcon(props, Employee);
export const IconExport = (props: any) => buildIcon(props, ExportCSV);
// F
export const IconFoodMenu = (props: any) =>
  buildIcon(props, MdFormatListBulleted);
export const IconFilter = (props: any) => buildIcon(props, MdFilterList);
// G
export const IconGo = (props: any) => buildIcon(props, MdChevronRight);
export const IconGroup = (props: any) => buildIcon(props, Group);
// H
// I
export const IconIn = (props: any) => buildIcon(props, MdArrowForward);
export const IconInProgress = (props: any) => <InProgress {...props} />;
export const IconInventory = (props: any) => buildIcon(props, Inventory);
// J
// K
// L
export const IconLogout = (props: any) => buildIcon(props, Logout);
// M
export const IconMenu = (props: any) => buildIcon(props, MdMenu);
export const IconMenuBook = (props: any) => <MenuBook {...props} />;
export const IconMinAmount = (props: any) => buildIcon(props, MinAmount);
export const IconMinus = (props: any) => buildIcon(props, MdRemove);

// N
export const IconNotifications = (props: any) =>
  buildIcon(props, MdNotifications);
export const IconNote = (props: any) => buildIcon(props, MdChat);
// O
export const IconOut = (props: any) => buildIcon(props, MdArrowBack);
// P
export const IconPayment = (props: any) => buildIcon(props, MdAttachMoney);
export const IconBillPayment = (props: any) => buildIcon(props, BillPayment);
export const IconPlus = (props: any) => buildIcon(props, MdAdd);
export const IconPhone = (props: any) => buildIcon(props, MdPhoneInTalk);
export const IconPrinter = (props: any) => buildIcon(props, PrinterFill);
// Q
// R
export const IconRejected = (props: any) => <Rejected {...props} />;
export const IconRestaurant = (props: any) => buildIcon(props, Restaurant);
// S
export const IconStatusUpdate = (props: any) => <StatusUpdate {...props} />;
export const IconCloseAll = (props: any) => <CloseAll {...props} />;
export const IconShoppingCart = (props: any) =>
  buildIcon(props, MdShoppingCart);
export const IconSearch = (props: any) => buildIcon(props, MdSearch);
export const IconStar = (props: any) => buildIcon(props, Star);
// T
// U
export const IconUser = (props: any) => <HiOutlineUser {...props} />;
export const IconUserGroup = (props: any) => <HiOutlineUserGroup {...props} />;

export const IconUp = (props: any) => buildIcon(props, Up);
// V
// W
// X
// Y
// Z
