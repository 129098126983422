// REACT
import React, { useState } from 'react'

// GLOBAL IMPORTS
import { Popover, Typography } from 'antd'
import styled from 'styled-components'

// LOCAL IMPORTS
import { Badge, Buttons, Counter, CountTag, PushButton } from '../../common'
import { EOrderOfferStatus, TOrderOfferStatus } from '../../../models/admin/orders'
import { useTranslation } from 'react-i18next'

const { Paragraph, Title } = Typography
const { ButtonDone, ButtonDiscard } = Buttons

// LOCAL IMPORTS
const OrderOfferItem = ({ label, min, max, count, onChange }: IOrderOfferStatusItemProps) => {
  return (
    <OrderOfferItemContainer>
      <OrderOfferItemLabelContainer>
        <Paragraph>{label}</Paragraph>
      </OrderOfferItemLabelContainer>
      <OrderOfferItemCounterContainer>
        {onChange ? (
          <Counter
            inputStyle={{ width: 60 }}
            min={min}
            max={max}
            count={count}
            onChange={onChange}
          />
        ) : (
          <CountTag amount={count} />
        )}
      </OrderOfferItemCounterContainer>
    </OrderOfferItemContainer>
  )
}

export const OrderOfferStatus = ({
  title,
  from,
  count = 0,
  onDiscard,
  onChange,
}: IOrderOfferStatusProps) => {
  const [statusCount, setStatusCount] = useState<IOrderOfferStatusCount>({
    pending: 0,
    delivered: 0,
    rejected: 0,
  })

  const resetStatusCount = () => {
    setStatusCount({
      pending: 0,
      delivered: 0,
      rejected: 0,
    })
  }

  const getMaxCount = () => {
    const { pending = 0, delivered = 0, rejected = 0 } = statusCount
    return count - (pending + delivered + rejected)
  }

  const handleSubmit =
    onChange &&
    (() => {
      onChange({
        from,
        status: {
          ...statusCount,
          [from]: getMaxCount(),
        },
      })
      resetStatusCount()
    })

  const handleChange = (key: 'pending' | 'delivered' | 'rejected', count: number) => {
    setStatusCount({
      ...statusCount,
      [key]: count,
    })
  }

  const handleDiscard =
    onDiscard &&
    (() => {
      resetStatusCount()
      onDiscard()
    })

  const { t } = useTranslation()
  return (
    <OrderOfferStatusContainer
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
    >
      {/* TITLE */}
      <OrderOfferStatusItemTitle>
        <Title level={5}>
          {title} {getMaxCount()}
        </Title>
      </OrderOfferStatusItemTitle>
      {/* STATUS ITEMS */}
      {onChange && (
        <OrderOfferStatusItemsContainer>
          {/* PENDING */}
          {from !== EOrderOfferStatus.PENDING && (
            <OrderOfferItem
              label={t('status_pending')}
              min={0}
              max={(statusCount.pending || 0) + getMaxCount()}
              count={statusCount.pending || 0}
              onChange={(count: number) => handleChange('pending', count)}
            />
          )}
          {/* DELIVERED */}
          {from !== EOrderOfferStatus.DELIVERED && (
            <OrderOfferItem
              label={t('status_delivered')}
              min={0}
              max={(statusCount.delivered || 0) + getMaxCount()}
              count={statusCount.delivered || 0}
              onChange={(count: number) => handleChange('delivered', count)}
            />
          )}
          {/* REJECTED */}
          {from !== EOrderOfferStatus.REJECTED && (
            <OrderOfferItem
              label={t('status_rejected')}
              min={0}
              max={(statusCount.rejected || 0) + getMaxCount()}
              count={statusCount.rejected || 0}
              onChange={(count: number) => handleChange('rejected', count)}
            />
          )}
        </OrderOfferStatusItemsContainer>
      )}
      {/* ACTIONS */}
      {handleSubmit && (
        <OrderOfferStatusActionsContainer>
          {handleDiscard && <ButtonDiscard onClick={handleDiscard} />}
          {handleSubmit && <ButtonDone type="primary" onClick={handleSubmit} />}
        </OrderOfferStatusActionsContainer>
      )}
    </OrderOfferStatusContainer>
  )
}

export const OrderOfferStatusPopover = ({
  icon,
  title,
  field,
  count,
  onSubmit,
}: IOrderOfferStatusPopoverProps) => {
  // STATE
  const [visible, setVisible] = useState<boolean>(false)

  const handleChangeCount =
    onSubmit &&
    ((status: IOrderOfferStatusResponse) => {
      setVisible(false)
      onSubmit(status)
    })

  return (
    <Popover
      trigger="click"
      destroyTooltipOnHide={true}
      content={
        <OrderOfferStatus
          title={title}
          from={field}
          count={count}
          onChange={handleChangeCount}
          onDiscard={() => setVisible(false)}
        />
      }
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <Badge
        count={<span className="custom-badge">{count}</span>}
        showZero={true}
        offset={[0, -5]}
        className="order-offer-status"
      >
        <PushButton type="text" icon={icon} />
      </Badge>
    </Popover>
  )
}
// STYLED COMPONENTS
const OrderOfferStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const OrderOfferStatusItemTitle = styled.div`
  padding: 0.5em 0;
`

const OrderOfferStatusItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
`

const OrderOfferStatusActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`

const OrderOfferItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 auto;
  & > :not(:first-child) {
    margin-left: 1em;
  }
`

const OrderOfferItemLabelContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
`
const OrderOfferItemCounterContainer = styled.div`
  display: flex;
  /* max-width: 5em; */
  flex: 0 0 auto;
`
export interface IOrderOfferStatusCount {
  pending?: number
  delivered?: number
  rejected?: number
  [index: string]: number | undefined
}
export interface IOrderOfferStatusResponse {
  status: IOrderOfferStatusCount
  from: TOrderOfferStatus
}

// INTERFACES
export interface IOrderOfferStatusPopoverProps {
  title: string
  icon: React.ReactNode
  field: TOrderOfferStatus
  count?: number
  onSubmit?: (status: IOrderOfferStatusResponse) => unknown
}

export interface IOrderOfferStatusProps {
  title: string
  from: TOrderOfferStatus
  count?: number
  onChange?: (orderOfferStatus: IOrderOfferStatusResponse) => unknown
  onDiscard?: () => unknown
}

export interface IOrderOfferStatusItemProps {
  label: string
  min: number
  max: number
  count: number
  onChange?: (count: number) => unknown
}
