export const useStorage = <T>(key: string) => {
  const persist = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const remove = () => {
    localStorage.removeItem(key)
  }

  const get = (): T | string | null => {
    const storedItem = localStorage.getItem(key)
    try {
      return JSON.parse(storedItem || '') as T
    } catch (e) {
      return storedItem
    }
  }

  return {
    persist,
    remove,
    get,
  }
}
