import React, { useCallback, useLayoutEffect, useMemo } from "react";
import { Container } from "../view-layout";
import { useScroll } from "hooks/scroll.hook";

const ScrollSpy = ({ children, activeSection }: IScrollSpyProps) => {
  const { scrollTo, setContainerRef } = useScroll();

  const spiedChildren = useMemo((): React.ReactNode[] | null => {
    if (children) {
      const spiedChildrenArr: React.ReactElement[] = [];
      React.Children.forEach(children, (element) => {
        if (!React.isValidElement(element)) return;
        const { props } = element;
        spiedChildrenArr.push(
          React.cloneElement(element, {
            ...props,
            //eslint-disable-next-line
            key: props.id as string,
            //eslint-disable-next-line
            active: activeSection === props.id,
          })
        );
      });
      return spiedChildrenArr;
    }
    return null;
  }, [children, activeSection]);

  /*const handleSectionChange = useCallback(
    (sectionId: string) => {
      if (onChange) {
        detachScroll()
        onChange(sectionId)
      }
    },
    [onChange, detachScroll],
  )*/

  /*const detectCurrentSection = useCallback(() => {
    const spiedSections = spiedId.map((id) => document.getElementById(id))

    let targetSectionId: string | null = null
    // TEST EACH SPIED SECTION
    spiedSections
      .filter((spiedSection) => !!spiedSection) // Filter out null elements
      .forEach((spiedSection) => {
        if (spiedSection) {
          const isSectionActive = isElementActive(spiedSection, offset)
          if (isSectionActive) {
            targetSectionId = spiedSection?.getAttribute('id') || null
          }
        }
      })
    if (targetSectionId && targetSectionId !== activeSection) {
      handleSectionChange(targetSectionId)
    }
  }, [handleSectionChange, activeSection, offset, htmlElement, isElementActive])*/

  /*const attachScrollListener = useCallback(() => {
    attachScroll(detectCurrentSection, 200)
  }, [detectCurrentSection, attachScroll])

  const detachScrollListener = useCallback(() => {
    detachScroll()
  }, [detachScroll])*/

  const goTo = useCallback(
    (section: string | undefined | null) => {
      if (section) {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          scrollTo(sectionElement.offsetTop, () => {
            // attachScrollListener()
          });
        }
      }
    },
    [/*attachScrollListener*/ scrollTo]
  );

  useLayoutEffect(() => {
    goTo(activeSection);
    /*return () => {
      detachScrollListener()
    }*/
  }, [goTo, activeSection /*attachScrollListener, detachScrollListener*/]);

  return (
    <Container
      className="scrollspy-container-scrollable scroll-smooth padding-content"
      scrollable
      flexDirection="column"
      wrap="nowrap"
      alignItems="stretch"
      ref={setContainerRef}
    >
      {spiedChildren}
    </Container>
  );
};

interface IScrollSpyProps {
  children?: React.ReactNode;
  section?: string | null;
  activeSection?: string | null;
  offset?: number;
  onChange?: (spyId: string) => void | Promise<void>;
}

export default ScrollSpy;
