import { HttpRequester } from '../../base.repository'
import { ISessionData } from '../sessions/sessions.repository'
import Axios from 'axios'

class Customer {
  httpRequester: HttpRequester
  endpoint: string

  constructor(httpRequester: HttpRequester = Axios) {
    this.httpRequester = httpRequester
    this.endpoint = '/client-notifications'
  }

  async sendOrderPermissionRequest(): Promise<IOrderPermissionResponse> {
    try {
      const { data }: { data: IOrderPermissionResponse } = await this.httpRequester.get(
        `${this.endpoint}/permission-to-order`,
      )
      return data
    } catch (err) {
      throw new Error('Error requesting permission to order')
    }
  }

  sendCallWaitressRequest() {
    return this.httpRequester.get(`${this.endpoint}/call-waitress`)
  }

  sendBillRequest(scope: string) {
    return this.httpRequester.get(`${this.endpoint}/bill-payment?billType=${scope}`)
  }

  allowToOrder(sessionId: string, allow: boolean): Promise<ISessionData> {
    return this.httpRequester
      .post(`admin/sessions/${sessionId}/can-order/${allow}`)
      .then(({ data }) => data)
      .catch(console.error)
  }

  deleteSessionsByTableId(
    tableId: string,
    orderMark = 0,
  ): Promise<{ matchedItems: number; deletedItems: number }> {
    return this.httpRequester
      .delete(`admin/sessions/table/${tableId}`, { params: { orderMark } })
      .then(({ data }) => data)
  }
}

export default Customer

interface IOrderPermissionResponse {
  sessionId: string
  message: string
  tableIdentifier: string
  employeesNotified: string[]
}
