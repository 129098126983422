// REACT
import { useCallback, useMemo } from "react";

import { matchPath } from "react-router";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Path from "path";

// CUSTOM HOOKS
import { IUserPermissions, useAuth } from "./auth.hook";
import { ERoles } from "models/admin/employees";
import { INavItem } from "../components/common/nav";
import { IRoute } from "../routes/types.d";
import { usePointer } from "./state.hook";

const checkPermission = (
  userPermissions: IUserPermissions = {},
  permissions?: string,
  permissionType = "read"
) => {
  if (!permissions) return true; // Not secure route

  return permissions && userPermissions[permissions]?.includes(permissionType);
};
const isAuthorized = (userPermissions: IUserPermissions) => (route: IRoute) => {
  return checkPermission(userPermissions, route.permissions);
};

export { useParams, useLocation };
export const useRoutes = (routes: IRoute[]) => {
  const { pathname } = useLocation();
  const { user } = useAuth() || {};

  const userPermissions = useMemo(() => user?.permissions || {}, [user]);

  const checkAuthorized = useMemo(
    () => isAuthorized(userPermissions),
    [userPermissions]
  );

  const authorizedRoutes: IRoute[] = useMemo(
    () => routes.filter(checkAuthorized),
    [routes, checkAuthorized]
  );

  const deniedRoutes: IRoute[] = useMemo(
    () =>
      routes.filter((route) => {
        return !checkAuthorized(route);
      }),
    [checkAuthorized, routes]
  );

  const activeRoute = useMemo(
    () =>
      authorizedRoutes.find(({ path }) => {
        const didMatch = matchPath(pathname, path);
        return !!(didMatch && didMatch.isExact);
      }),
    [authorizedRoutes, pathname]
  );

  const securedRoutes = useMemo(
    () => (!user ? routes.filter(({ permissions }) => permissions) : []),
    [routes, user]
  );

  const _routes = useMemo(
    () => ({ deniedRoutes, authorizedRoutes, activeRoute, securedRoutes }),
    [deniedRoutes, authorizedRoutes, activeRoute, securedRoutes]
  );

  return _routes;
};

export const useBack = () => {
  const history = useHistory();

  return { goBack: history.goBack, hasBack: !!history.length };
};

export const useLogin = () => {
  const history = useHistory();

  const goLogin = () => history.replace("/security/login");

  return { goLogin };
};

export const useGoTo = () => {
  const history = useHistory();

  const goTo = useCallback(
    (url: string, replace = false) => {
      history[replace ? "replace" : "push"](url);
    },
    [history]
  );

  return { goTo };
};

export const useHash = () => {
  const location = useLocation();
  const history = useHistory();

  const hash = useMemo(() => {
    return location.hash.replace("#", "");
  }, [location]);

  const $hash = usePointer(hash);
  const $history = usePointer(history);
  const $location = usePointer(location);

  const set = useCallback(
    (newHash?: string, replace = false) => {
      if (!newHash && $hash.current) {
        $history.current.replace($location.current.pathname);
      } else if (newHash !== $hash.current) {
        $history.current[replace ? "replace" : "push"](
          `${$location.current.pathname}#${newHash}`
        );
      }
    },
    [$hash, $history, $location]
  );

  return useMemo(() => ({ setHash: set, hash }), [set, hash]);
};

const recursiveDFS = (
  routes: IRoute[],
  navItemsDefinition?: INavDefinition,
  userPermissions?: IUserPermissions,
  userRoles?: string[]
): INavItem[] => {
  if (!navItemsDefinition) {
    return [];
  }

  return (
    Object.keys(navItemsDefinition)
      // Generate NavItems from routes
      .map((navItemId: string) => {
        const navItem = navItemsDefinition[navItemId];
        const { permissions, label, icon, children, link: navLink } = navItem;
        if (!checkPermission(userPermissions, permissions)) {
          return undefined;
        }
        const hasChildren = children && Object.keys(children).length;
        const link = hasChildren
          ? undefined
          : navLink || routes.find(({ id }) => id === navItemId)?.path;
        const sectionChildren = hasChildren
          ? recursiveDFS(routes, children, undefined, userRoles)
          : undefined;

        return link || (!link && sectionChildren && sectionChildren.length)
          ? {
              label,
              icon,
              link,
              id: navItemId,
              children: sectionChildren,
            }
          : undefined;
      })
      // Filter to remove undefined routes which mean not available
      .filter((item) => {
        if (
          item?.id === "ordersPaid" &&
          !(
            userRoles?.includes(ERoles.ACCOUNTANT) ||
            userRoles?.includes(ERoles.ADMIN)
          )
        ) {
          return false;
        }

        return !!item;
      }) as INavItem[]
  );
};

export const useRoute2Nav = (
  routes: IRoute[],
  navDefinition: INavDefinition
): INavItem[] => {
  const { user } = useAuth() || {};
  return useMemo(() => {
    return recursiveDFS(routes, navDefinition, user?.permissions, user?.roles);
  }, [user, navDefinition, routes]);
};

export const concatPaths = (baseUrl: string, path: string) => {
  return Path.join(baseUrl, path);
};

export interface INavItemDefinition
  extends Pick<INavItem, "label" | "icon" | "link"> {
  children?: { [key: string]: INavItemDefinition };
  permissions?: string;
}

export interface INavDefinition {
  [key: string]: INavItemDefinition;
}

export type { IRoute };
