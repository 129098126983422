// GLOBAL IMPORTS
import { Divider, Typography } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";

// LOCAL IMPORTS
import { Buttons, Icons, ListItem, TimeCounter } from "../common";
import { OrderStatusPopover, TOrderStatus } from "./OrderStatus";
import OrderOfferList, {
  IOrderOfferItem,
  IOrderOfferStatusResponse,
} from "./offers/OrderOfferList";

// INTERFACES
import { EOrderStatus, IOrderListItem } from "../../models/admin/orders";
import { ERoles } from "models/admin/employees";
import { useAuth } from "hooks/auth.hook";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";

const { IconClock } = Icons;
const { ButtonStatusUpdate } = Buttons;
const { Title } = Typography;

const OrderListItem = ({
  order,
  onClick,
  orderStatusList,
  onChangeStatus,
  onChangeOfferStatus,
}: IOrderListItemProps) => {
  const { table, offers, createdAt, closedAt, orderMark } = order;

  const { orderStatus } /*----------*/ = useParams<{
    orderStatus: EOrderStatus;
  }>();
  const { user } = useAuth() || {};
  const { identifier: tableId } = table || {};

  const { t } = useTranslation();

  const time = useMemo(() => {
    if (orderStatus === EOrderStatus.IN_PROGRESS) {
      return <TimeCounter initialDate={createdAt} />;
    }

    const options = {
      // day: 'numeric',
      // month: 'numeric',
      // year: 'numeric',
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    } as const;

    return (
      <span style={{ fontWeight: "bold", paddingTop: 8 }}>
        {new Intl.DateTimeFormat("es", options).format(
          new Date(closedAt || new Date())
        )}
      </span>
    );
  }, [createdAt, orderStatus, closedAt]);

  return (
    <ListItem onClick={onClick}>
      <OrderContainer>
        {/* HEADERS */}
        <OrderHeader>
          <OrderTableContainer>
            <Title level={5}>
              {tableId && (
                <>
                  <TableSpan>{t("table")}</TableSpan>
                  {tableId || "?"}
                  {(orderMark && (
                    <>
                      <TableSpan style={{ paddingLeft: 8 }}>
                        {t("client")}
                      </TableSpan>{" "}
                      #{orderMark}
                    </>
                  )) ||
                    null}
                </>
              )}
            </Title>
          </OrderTableContainer>
          <OrderHeaderRightSide>
            {/* TIME */}
            <OrderTimeContainer>
              <IconClock className="text-primary" />
              {time}
            </OrderTimeContainer>
            {/* STATUS SELECTOR */}
            {user?.roles?.includes(ERoles.ADMIN) &&
              orderStatus == EOrderStatus.IN_PROGRESS && (
                <OrderStatusSelector onClick={(e) => e.stopPropagation()}>
                  <Divider type="vertical" />
                  <OrderStatusPopover
                    orderStatus={order.status}
                    statusList={orderStatusList}
                    onChange={onChangeStatus}
                  >
                    <ButtonStatusUpdate
                      iconOnly={true}
                      type="text"
                      help={t("msg_change_status")}
                      className="text-primary"
                      size="large"
                    />
                  </OrderStatusPopover>
                </OrderStatusSelector>
              )}
          </OrderHeaderRightSide>
        </OrderHeader>
        <Divider />
        {/* CONTENT */}
        <OrderOfferListContainer>
          <OrderOfferList
            items={offers}
            onChangeItem={onChangeOfferStatus}
            billApproved={order.billApproved}
            orderStatus={orderStatus}
          />
        </OrderOfferListContainer>
      </OrderContainer>
    </ListItem>
  );
};

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  flex: 1 1 auto;
`;

const OrderHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
`;

const OrderTableContainer = styled.div``;

const OrderHeaderRightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`;

const OrderTimeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`;

const OrderStatusSelector = styled.div`
  display: flex;
  align-items: center;
`;

const OrderOfferListContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: stretch;
`;

const TableSpan = styled.span`
  font-size: 0.8em;
  opacity: 0.5;
  padding-right: 0.5em;
`;

export interface IOrderListItemProps {
  order: IOrderListItem;
  groupByPending?: boolean;
  orderStatusList?: { title: string; value: TOrderStatus }[];
  onClick?: () => unknown;
  onChangeOfferStatus?: (
    orderOffer: IOrderOfferItem,
    status: IOrderOfferStatusResponse
  ) => unknown;
  onChangeStatus?: (status: TOrderStatus) => unknown;
}

export type { IOrderOfferItem, IOrderOfferStatusResponse, TOrderStatus };
export default OrderListItem;
