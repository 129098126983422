import { ActionConfirmation } from 'components/common/ActionConfirmation'
import { ButtonClear } from 'components/common/Buttons'
import { useTranslation } from 'react-i18next'
import React from 'react'

export interface ClearSessionsCtrlProps {
  onClear(): void
}

export function ClearSessionsCtrl(props: ClearSessionsCtrlProps) {
  const { onClear } = props
  const { t } = useTranslation()

  return (
    <ActionConfirmation
      title={t('msg_clear_table_sessions_title')}
      message={t('msg_clear_table_sessions_description')}
      onConfirm={onClear}
      onDiscard={() => {
        /*noop*/
      }}
    >
      <ButtonClear
        key="clear-table"
        style={{ border: 'none' }}
        className="text-primary"
        help={t('msg_clear_active_sessions')}
        helpPlacement="bottom"
        iconOnly={true}
      />
    </ActionConfirmation>
  )
}
