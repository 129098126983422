import { List as ADList } from 'antd'
import { ListProps } from 'antd/lib/list'
import React from 'react'

const List = ({ items = [], renderItem, className, ...props }: IListProps<any>) => {
  return (
    <ADList
      size="small"
      className={`${className || ''} width-full`.trim()}
      {...props}
      dataSource={items}
      renderItem={renderItem}
    />
  )
}

interface IListProps<T> extends ListProps<T> {
  items?: T[]
  renderItem: (item: T) => JSX.Element
}

export default List
