// REACT
import React, { useEffect, useState } from 'react'

// GLOBAL

// LOCAL
import PushButton, { IPushButtonProps } from './PushButton'

const ToggleButton = ({ active: defaultActive, onChange, ...props }: IToggleButtonProps) => {
  const [active, setActive] = useState<boolean>(false)

  const handleToggle = () => {
    const toggledActive = !active
    setActive(toggledActive)
    if (onChange) {
      onChange(toggledActive)
    }
  }
  useEffect(() => {
    setActive(!!defaultActive)
  }, [defaultActive])

  return <PushButton {...props} type={active ? 'primary' : 'ghost'} onClick={handleToggle} />
}

export interface IToggleButtonProps extends Omit<IPushButtonProps, 'onChange'> {
  active?: boolean
  onChange?: (status: boolean) => unknown
}

export default ToggleButton
