import { IOrderListItem, IOrderOfferItem, TOrderStatus } from '../../models/admin/orders'
import { IOrderOfferStatusResponse } from './OrderListView'
import { IOrderStats } from './OrderStats'
import { VirtualList } from '../common/VirtualList'
import OrderListItem from './OrderListItem'
import React, { useCallback } from 'react'

const OrderList = (props: IOrderListProps) => {
  const {
    group,
    groupByPending,
    items: orders = [],
    orderStatusList = [],
    onClickItem,
    onChangeOrderOfferStatus,
    onChangeOrderStatus,
  } = props

  const renderOrderListItem = useCallback(
    (order: IOrderListItem) => {
      const handleClickOrderListItem = onClickItem
        ? () => {
            onClickItem(order)
          }
        : undefined

      const handleChangeOrderOfferStatus =
        onChangeOrderOfferStatus &&
        ((offer: IOrderOfferItem, status: IOrderOfferStatusResponse) => {
          onChangeOrderOfferStatus(order, offer, status)
        })

      const handleChangeOrderStatus =
        onChangeOrderStatus && order.canUpdateOrder
          ? (status: TOrderStatus) => onChangeOrderStatus(order, status)
          : undefined

      const _order = order

      if (groupByPending) {
        const _offers: IOrderOfferItem[] = []
        order.offers.forEach((offer) => {
          if (offer?.pending && offer.pending > 0) {
            _offers.push(offer)
          }
        })

        _order.offers = _offers
      }

      return (
        <>
          {(_order.offers.length && (
            <OrderListItem
              key={_order._id}
              order={_order}
              orderStatusList={orderStatusList}
              onClick={handleClickOrderListItem}
              onChangeOfferStatus={handleChangeOrderOfferStatus}
              onChangeStatus={handleChangeOrderStatus}
            />
          )) ||
            null}
        </>
      )
    },
    [onChangeOrderOfferStatus, groupByPending, onChangeOrderStatus, onClickItem, orderStatusList],
  )

  const groupGen = useCallback((items: IOrderListItem[]) => {
    const group: { [key: string]: number[] } = {}

    items.forEach((item, index) => {
      const cat = item?.table?.isDeliveryTable
        ? 'C#' + item?.orderMark?.toString()
        : item?.table?.identifier || '???'

      if (!group[cat]) {
        group[cat] = []
      }

      group[cat].push(index)
    })
    return group
  }, [])

  return (
    <VirtualList
      items={orders}
      sortAsc={true}
      renderItem={renderOrderListItem}
      groupBy={(group && groupGen) || undefined}
    />
  )

  // const renderSections = () =>
  //   Object.entries(itemsByTable).map(([tableIdentifier, orders]) => {
  //     return renderSection(tableIdentifier, tableIdentifier, orders)
  //   })

  // return <SmartTab sectionTabs={sectionTabs}>{renderSections()}</SmartTab>
}

export interface IOrderListProps {
  group?: boolean
  groupByPending?: boolean
  items?: IOrderListItem[]
  orderStats?: IOrderStats
  orderStatusList?: { title: string; value: TOrderStatus }[]
  onClickItem?: (order: IOrderListItem) => unknown
  onChangeOrderStatus?: (order: IOrderListItem, status: TOrderStatus) => unknown
  onChangeOrderOfferStatus?: (
    order: IOrderListItem,
    offer: IOrderOfferItem,
    status: IOrderOfferStatusResponse,
  ) => unknown
}
export default OrderList
