import { AlignLeftOutlined } from '@ant-design/icons'
import { Button, Checkbox, Popover, Spin } from 'antd'
import { ButtonBillPayment } from 'components/common/Buttons'
import { IBill } from 'models/admin/table.repository'
import { IconPrinter } from 'components/common/Icons'
import { InputNumber, TextArea } from 'components/common/Inputs'
import { PaymentBill } from './PaymentBill'
import { useCallback, useState } from 'reactn'
import { usePointer } from 'hooks/state.hook'
import { useTranslation } from 'react-i18next'
import Modal from 'antd/lib/modal/Modal'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

export interface BillPaymentCtrlProps {
  bill: IBill | null
  onShowBill(): void
  onConfirm(
    discount: number,
    includedTip?: number,
    discountNote?: string,
    componentToPrint?: unknown,
  ): void
  loadingBill: boolean
  billVisibility: boolean
  onCancel(): void
}

export function BillPaymentCtrl(props: BillPaymentCtrlProps) {
  const { onShowBill, onConfirm, billVisibility, loadingBill, onCancel, bill } = props
  const [discount, setDiscount] = useState(0)
  const [showPopoverDiscountNote, setShowPopoverDiscountNote] = useState(false)
  const [includedTip, setIncludedTip] = useState(bill?.includedTip)
  const { t } = useTranslation()

  const _includedTip = bill?.includedTip
  const componentToPrintRef = useRef(null)
  const discountNoteRef = useRef('')
  const $includedTip = usePointer(includedTip)

  const $discount = usePointer(discount)

  const handleOk = useCallback(
    (e, toPrint = false) => {
      const contentToPrint = toPrint ? componentToPrintRef.current : null
      e.stopPropagation()
      onConfirm?.($discount.current, $includedTip.current, discountNoteRef.current, contentToPrint)
    },
    [onConfirm, $discount, $includedTip],
  )

  const handleDiscount = useCallback((value) => {
    setDiscount(value)
  }, [])

  useEffect(() => {
    if (!billVisibility) {
      setDiscount(0)
      setIncludedTip(undefined)
    }
  }, [billVisibility])

  useEffect(() => {
    setIncludedTip(_includedTip)
  }, [_includedTip, billVisibility])

  const handleOkAndPrint = useCallback(
    (e) => {
      handleOk(e, true)
    },
    [handleOk],
  )

  const handleDiscountNote = useCallback(() => {
    if (bill) {
      bill.discountNote = discountNoteRef.current || ''
    }
    setShowPopoverDiscountNote(false)
  }, [bill])

  const handleVisibleChange = useCallback((visble) => {
    setShowPopoverDiscountNote(visble)
  }, [])

  return (
    <>
      <ButtonBillPayment
        key="bill-payment"
        style={{ border: 'none' }}
        className="text-primary"
        help={t('msg_generate_bill')}
        helpPlacement="bottom"
        iconOnly={true}
        onClick={onShowBill}
      />
      <Modal
        visible={billVisibility}
        closable={false}
        maskClosable={false}
        footer={
          <FooterWrapper>
            <FooterRow>
              <div>
                <InputNumber
                  value={discount}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace('%', '') || ''}
                  onChange={handleDiscount}
                />
                <Popover
                  placement="topLeft"
                  content={
                    <div style={{ width: '250px' }}>
                      <TextArea
                        help={t('msg_discount_note')}
                        helpPlacement="top"
                        rows={3}
                        cols={8}
                        onChange={(e) => {
                          const value = e.target.value
                          discountNoteRef.current = value
                        }}
                      />
                      <Button
                        style={{ marginTop: '0.5em' }}
                        type="primary"
                        onClick={handleDiscountNote}
                      >
                        {t('msg_add_note')}
                      </Button>
                    </div>
                  }
                  title={t('msg_add_discount_note')}
                  trigger="click"
                  visible={showPopoverDiscountNote}
                  onVisibleChange={handleVisibleChange}
                >
                  <Button
                    // type="primary"
                    onClick={handleDiscountNote}
                    icon={<AlignLeftOutlined />}
                    style={{ marginLeft: '.5em' }}
                    disabled={discount === 0}
                  />
                </Popover>

                {(bill?.includedTip && (
                  <div style={{ display: 'inline-block', marginLeft: 10 }}>
                    <Checkbox
                      checked={!!includedTip}
                      onChange={(v) => setIncludedTip(v.target.checked ? bill?.includedTip : 0)}
                    >
                      {t('msg_tip_included')}
                    </Checkbox>
                  </div>
                )) ||
                  null}
              </div>
            </FooterRow>
            <FooterRow>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onCancel}>{t('cancel')}</Button>
                <Button type="primary" onClick={handleOk}>
                  {t('approveTxt')}
                </Button>
                <Button type="primary" icon={<IconPrinter />} onClick={handleOkAndPrint}>
                  {t('approveTxt')}
                </Button>
              </div>
            </FooterRow>
          </FooterWrapper>
        }
      >
        <Spin spinning={loadingBill}>
          <div ref={componentToPrintRef}>
            <PaymentBill discount={discount} bill={bill} includedTip={includedTip} />
          </div>
        </Spin>
      </Modal>
    </>
  )
}

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5em;
`
