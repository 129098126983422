export async function decodeImageFile(file: File): Promise<string> {
  validateImageFile(file)
  return await getBase64(file)
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export const validateImageFile = (
  file: File,
  types: string[] = ['image/jpeg', 'image/jpg', 'image/png'],
  maxSizeInMb = 2,
): boolean => {
  // TESTING FILE TYPE
  if (!testFileType(file, types)) {
    throw new Error('Unsupported file type')
  }

  // TESTING FILE SIZE
  if (!testFileSize(file, mbToBytes(maxSizeInMb))) {
    throw new Error(`File exceed the max allowed size of ${maxSizeInMb} MB`)
  }

  return true
}

const testFileType = (file: File, types: string[]): boolean => {
  const { type } = file
  if (types.includes(type)) {
    return true
  }
  return false
}

const testFileSize = (file: File, maxSizeInBytes: number): boolean => {
  const { size } = file
  if (size < maxSizeInBytes) {
    return true
  }
  return false
}

const mbToBytes = (sizeInMb: number): number => {
  if (sizeInMb && typeof sizeInMb === 'number') {
    return sizeInMb * Math.pow(1024, 2)
  }
  throw new Error(`Unsupported sizeInMb variable type ${typeof sizeInMb} should be number`)
}
