import Namespace from '../namespace'

// MODULES
import Areas from './areas'
import Categories from './categories'
import Conversions from './conversions'
import Dashboard from './dashboard'
import Employees from './employees'
import Inventories from './inventories'
import Menus from './menus'
import Offers from './offers'
import OffersExistence from './offers_existence'
import Orders from './orders'
import Products from './products'
import Reports from './reports'
import Reviews from './reviews'
import Settings from './settings'
import Tables from './tables'
import Tags from './tags'
import Units from './units_measure'

const modules = [
  Dashboard,
  Menus,
  Offers,
  Products,
  Categories,
  Tags,
  Orders,
  Areas,
  Tables,
  Employees,
  Settings,
  Units,
  Conversions,
  Reports,
  Inventories,
  OffersExistence,
  Reviews,
]

export default new Namespace('Admin', modules)
