// GLOBAL IMPORTS
import React, { useCallback } from "react";
// INTERFACES
import { EOrderStatus, IOrderOfferItem } from "../../../models/admin/orders";
// LOCAL IMPORTS
import { List } from "../../common";
import OrderOfferListItem, {
  IOrderOfferStatusResponse,
} from "./OrderOfferListItem";

const OrderOfferList = ({
  items,
  onClickItem,
  onChangeItem,
  billApproved,
  orderStatus,
}: IOrderOfferListProps) => {
  const renderOfferItem = useCallback(
    (item: IOrderOfferItem) => {
      const handleClick = onClickItem ? () => onClickItem(item) : undefined;
      const handleChange = onChangeItem
        ? (countResponse: IOrderOfferStatusResponse) =>
            onChangeItem(item, countResponse)
        : undefined;

      return (
        <OrderOfferListItem
          key={item.offer._id}
          item={item}
          onClick={handleClick}
          onChange={handleChange}
          billApproved={billApproved}
          orderStatus={orderStatus}
        />
      );
    },
    [onClickItem, onChangeItem, billApproved, orderStatus]
  );

  return <List items={items} renderItem={renderOfferItem} />;
};

export interface IOrderOfferListProps {
  items: IOrderOfferItem[];
  groupByPending?: boolean;
  searchable?: boolean;
  onClickItem?: (item: IOrderOfferItem) => unknown;
  onChangeItem?: (
    item: IOrderOfferItem,
    countResponse: IOrderOfferStatusResponse
  ) => unknown;
  renderItem?: (item: IOrderOfferItem) => JSX.Element;
  billApproved?: boolean;
  orderStatus?: EOrderStatus;
}

export type { IOrderOfferStatusResponse, IOrderOfferItem };

export default OrderOfferList;
