import en from './en.json'
import es from './es.json'

// export const resources = Object.assign({ en, es })

export const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
}
