import React, { useEffect, useState } from 'react'

// LOCAL IMPORTS
import { ButtonDiscard, ButtonDone } from '../common/Buttons'
import ActionBar from './ActionBar'
import ViewLayout from './ViewLayout'

export const ActionBarForm = ({ onDiscard, onDone }: IActionBarFormProps) => {
  const handleDiscard = () => {
    if (onDiscard) {
      onDiscard()
    }
  }

  const handleDone = () => {
    if (onDone) {
      onDone()
    }
  }

  return (
    <ActionBar>
      <ButtonDiscard style={{ flex: '1 1 auto' }} onClick={handleDiscard} />
      <ButtonDone type="primary" style={{ flex: '1 1 auto' }} onClick={handleDone} />
    </ActionBar>
  )
}

const ViewForm = ({ children: Children, loading, onDone, onDiscard }: IViewFormProps) => {
  const [hidden, setHidden] = useState(false)
  useEffect(() => {
    function reziseHandler() {
      if (window.innerHeight < 500) {
        setHidden(true)
      } else {
        setHidden(false)
      }
    }
    window.addEventListener('resize', reziseHandler)
    return () => window.removeEventListener('resize', reziseHandler)
  }, [])

  const ActionBar =
    (onDone || onDiscard) && !hidden ? (
      <ActionBarForm onDone={onDone} onDiscard={onDiscard} />
    ) : null

  return (
    <ViewLayout loading={loading} actionBar={ActionBar}>
      {Children}
    </ViewLayout>
  )
}

// INTERFACES
interface IActionBarFormProps {
  onDone?: () => unknown
  onDiscard?: () => unknown
}

interface IViewFormProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean
  onDone?: () => unknown
  onDiscard?: () => unknown
}

export default ViewForm
