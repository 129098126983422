import { Divider, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
const { Title } = Typography

const SectionWrapper = styled.div`
  width: 100%;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow: hidden; */
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1 !important;
  flex-direction: column;
  position: relative;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  & > :not(:first-child) {
    margin-left: 1em;
  }
`
const DividerContainer = styled.div`
  display: flex;
  flex: 1 !important;
`

const HeaderActionsContainer = styled.div`
  display: flex;
  flex: 0 1;
  align-items: center;
`
const Section = ({ title, children, headerActions, id, active, ...props }: ISectionProps) => {
  return (
    <SectionWrapper>
      <SectionContainer id={id} {...props}>
        <HeaderContainer>
          <DividerContainer>
            <Divider orientation="left">
              <Title
                className={active ? 'header-active section-header' : 'section-header'}
                level={5}
              >
                {title}
              </Title>
            </Divider>
          </DividerContainer>
          <HeaderActionsContainer>{headerActions || null}</HeaderActionsContainer>
        </HeaderContainer>
        <ContentContainer>{children}</ContentContainer>
      </SectionContainer>
    </SectionWrapper>
  )
}

export interface ISectionProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  children?: React.ReactNode
  active?: boolean
  headerActions?: React.ReactNode
}

export default Section
