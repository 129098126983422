// REACT
// GLOBAL
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { message } from "antd";
import { showWarning } from "components/common/Notification";
import axios from "axios";
import jwtDecode from "jwt-decode";
// LOCAL IMPORTS
import { IPrice } from "../models";
import { useStorage } from "./storage.hook";

// <!-- Auth Context for App injection -->
export const AuthContext = createContext<IAuth | null>(null);

// <!-- useAuth Hook -->
export function useAuth(): IAuth | null {
  return useContext<IAuth | null>(AuthContext);
}

const channel = (window as any).NativeChannel;

/**
 * `useAuthStorage` Hook (do not use this directly in the components, use `useAuth` instead)
 */
export function useAuthStorage(): IAuth {
  const userStorage /*--------*/ = useStorage<IUser>("user");
  const refreshTokenStorage /**/ = useStorage<string>("refreshToken");

  const storedUser /*--------*/ = userStorage.get() as IUser;
  const storedRefreshToken /**/ = refreshTokenStorage.get();

  const [user, _setUser]: UserState /*-----*/ = useState<IUser | null>(
    storedUser
  );
  const [refreshToken, _setRefreshToken] /**/ = useState<string | null>(
    storedRefreshToken
  );

  const refreshPool = useRef<Array<{
    reject: (value: string) => void;
    resolve: (value: string) => void;
  }> | null>(null);

  const isLoggedIn = !!(user && user.token);

  useEffect(() => {
    if (isLoggedIn) {
      channel?.postMessage("sign-in");
    } else {
      channel?.postMessage("sign-out");
    }
  }, [isLoggedIn]);

  const login = async (username: string, password: string): Promise<IJwt> => {
    try {
      const { data } = await axios.post("/auth/login", {
        username,
        password,
      });

      data.expTimeStamp = Date.now();

      setUser(data);
      setRefreshToken(data.refreshToken);

      return data;
    } catch (err: any) {
      if (err.response?.status === 402) {
        const _message: string = err.response.data.message;
        showWarning({
          message: _message.includes("expired")
            ? "Su licencia ha expirado. Contacte al proveedor de licencias"
            : "Su licencia no es válida. Contacte al proveedor de licencias",
        });
        //Todo show message and redirect to license
      }
      throw err;
    }
  };

  const initTablePermissions = () => {
    return {
      tableMenus: ["read"],
      tableOrders: ["read"],
      tableOffers: ["read", "write"],
    };
  };

  const loginTable = async (tableName: string): Promise<IJwt> => {
    const { data } = await axios.get("/tables/" + tableName);

    data.expTimeStamp = Date.now();

    data.permissions = initTablePermissions();

    setUser(data);
    setRefreshToken(data.refreshToken);

    return data;
  };

  const refresh = async (pasive = false): Promise<string> => {
    const Axios = axios.create({
      baseURL: `/backend`,
    });

    if (refreshPool.current) {
      return new Promise((resolve, reject) => {
        refreshPool.current?.push({ resolve, reject });
      });
    }

    refreshPool.current = [];

    try {
      const response = await Axios.post("/auth/token/refresh", {
        refreshToken,
        sessionId: user?.sessionId,
      });

      refreshPool.current?.forEach(({ resolve }) => {
        resolve(response.data?.token);
      });

      refreshPool.current = null;

      const data = response?.data;

      if (!data) {
        if (!pasive) {
          logout();
        } else {
          throw new Error("401");
        }
        return "";
      }

      data.expTimeStamp = Date.now();

      if (user?.tableId) {
        data.permissions = initTablePermissions();
      }

      setUser({
        sessionId: user?.sessionId,
        tableId: user?.tableId,
        isInfoTable: user?.isInfoTable,
        isDeliveryTable: user?.isDeliveryTable,
        liveChatEnabled: user?.liveChatEnabled,
        handledDeliveryTime: user?.handledDeliveryTime,
        deliveryLocations: user?.deliveryLocations,
        ...data,
      });

      return data.token;
    } catch (err: any) {
      refreshPool.current?.forEach(({ reject }) => {
        reject(err);
      });
      refreshPool.current = null;

      if (err.message === "401") {
        throw err;
      }
      if (err.response?.status >= 400) {
        logout();
      } else {
        message.error("Error conectando con el servidor");
      }
      throw err;
    }
  };

  const logout = () => {
    axios.delete(`/admin/sessions/session/${user?.sessionId}`);
    setUser(null);
    setRefreshToken(null);
  };

  const getToken = () => {
    //TODO: Handle browsers with bad time zone
    if (!user) {
      return undefined;
    }

    const isExpired = checkExpTimestamp(user.iat, user.exp, user.expTimeStamp);

    if (isExpired) {
      return refresh();
    }
    return Promise.resolve(user.token);
  };

  const setUser = (data?: IJwt | null) => {
    if (!data) {
      _setUser(null);
      userStorage.remove();
      return;
    }

    const {
      token,
      sessionId,
      tableId,
      expTimeStamp,
      isInfoTable,
      isDeliveryTable,
      liveChatEnabled,
      deliveryLocations,
      handledDeliveryTime,
    } = data;
    const decodedToken = jwtDecode<IDecodedToken>(token);
    const user = {
      ...decodedToken,
      permissions: formatPermissions(
        data.permissions || decodedToken.permissions
      ),
      token,
      tableId,
      sessionId,
      isInfoTable,
      isDeliveryTable,
      liveChatEnabled,
      expTimeStamp,
      deliveryLocations,
      handledDeliveryTime,
    };
    _setUser(user);

    userStorage.persist(user);
  };

  const setRefreshToken = (refreshToken?: string | null) => {
    if (!refreshToken) {
      _setRefreshToken("");
      refreshTokenStorage.remove();
      return;
    }
    _setRefreshToken(refreshToken);
    refreshTokenStorage.persist(refreshToken);
  };

  const getHeader = async (): Promise<{ Authorization?: string }> => {
    const token = await getToken();

    return token ? { Authorization: `Bearer ${token}` } : {};
  };

  const checkPermission = (section: string, permission: string) => {
    const { permissions } = user || {};
    return !!permissions?.[section]?.includes(permission);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.user = user;

  return {
    user,
    login,
    logout,
    refresh,
    setUser,
    updateUser: _setUser,
    getHeader,
    isLoggedIn,
    loginTable,
    refreshToken,
    setRefreshToken,
    checkPermission,
  };
}

// <!-- utility functions -->
export const formatPermissions = (
  userPermissions: IUserPermissions | ITokenPermission[]
): { [key: string]: string[] } => {
  const permissions: { [key: string]: string[] } = {};

  if (!(userPermissions instanceof Array)) {
    return userPermissions;
  }

  try {
    userPermissions?.forEach((item) => {
      if (!permissions[item?.category]) {
        permissions[item?.category.toLowerCase()] = [];
      }
      permissions[item?.category.toLowerCase()].push(item?.name);
    });

    return permissions;
  } catch (err) {
    return permissions;
  }
};

// <!-- interfaces -->

interface IJwt {
  token: /*-----------*/ string;
  tableId?: /*--------*/ string;
  sessionId: /*-------*/ string;
  refreshToken: /*----*/ string;
  permissions?: /*----*/ IUserPermissions;
  isDeliveryTable?: /**/ boolean;
  isInfoTable?: /**/ boolean;
  handledDeliveryTime?: /**/ boolean;
  deliveryLocations?: /**/ {
    _id: string;
    name: string;
    price: IPrice;
  }[];
  expTimeStamp: /*----*/ number;
  liveChatEnabled?: /*----*/ boolean;
}

interface IDecodedToken {
  id: /*--------------*/ string;
  iat: /*-------------*/ number;
  exp: /*-------------*/ number;
  roles?: /*----------*/ string[];
  username?: /*-------*/ string;
  permissions: /*-----*/ ITokenPermission[] | IUserPermissions;
}

interface ITokenPermission {
  id: /*--------------*/ string;
  name: /*------------*/ string;
  category: /*--------*/ string;
}

export interface IUserPermissions {
  [key: string]: string[];
}
export interface IUser
  extends Omit<IDecodedToken, "permissions">,
    Omit<IJwt, "refreshToken"> {
  permissions: IUserPermissions;
  expTimeStamp: number;
}

type UserState = [
  IUser | null,
  React.Dispatch<React.SetStateAction<IUser | null>>
];

export interface IAuth {
  user: /*--------*/ IUser | null;
  isLoggedIn: /*--*/ boolean;
  refreshToken: /**/ string | null;

  // FUNCTIONS
  login: /*----------*/ (username: string, password: string) => Promise<IJwt>;
  logout: /*---------*/ () => void;
  refresh: /*--------*/ (pasive?: boolean) => Promise<string>;
  setUser: /*--------*/ (data?: IJwt | null) => void;
  getHeader: /*------*/ () => Promise<{ Authorization?: string }>;
  updateUser: /*-----*/ React.Dispatch<React.SetStateAction<IUser | null>>;
  loginTable: /*-----*/ (tableId: string) => Promise<IJwt>;
  setRefreshToken: /**/ (refreshToken: string | null) => void;
  checkPermission: /**/ (section: string, permission: string) => boolean;
}

function checkExpTimestamp(iat: number, exp: number, expTimeStamp: number) {
  const timeRemaining =
    expTimeStamp + exp * 1000 - iat * 1000 - Date.now() - 60000;

  return timeRemaining < 0;
}
