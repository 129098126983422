import { ActionButton } from "./NotificationAlert";
import { Badge, Divider, Empty, notification, Space, Typography } from "antd";
import { Loader } from "components/common";
import { Modal } from "components/common";
import { NotificationAlert } from "./NotificationAlert";
import { useAuth } from "hooks/auth.hook";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useGlobal, useMemo, useState } from "reactn";

// <!-- REPOSITORIES -->
import {
  CheckOutlined,
  ControlOutlined,
  DeleteOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { IBill, ITableListItem } from "models/admin/table.repository";
import { IconFilter } from "../common/Icons";
import {
  INotification,
  SessionsRepository,
} from "models/public/sessions/sessions.repository";
import { InputSelect } from "../common/Inputs";
import {
  showBillResponse,
  showOrderTicketResponse,
} from "storage/notification.storage";
import { showError, showSuccess } from "components/common/Notification";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CustomerRepository from "models/public/customer";
import styled from "styled-components";
import TableRepository from "models/admin/table.repository";

import { IBill as IBillOrders } from "models/admin/orders";
import i18n from "i18n";

const { Link } = Typography;

const customerRepo = new CustomerRepository();
const sessionsRepo = new SessionsRepository();
const tablesRepo = new TableRepository();

export const ViewNotifications = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose?: () => void;
}) => {
  const { user } = useAuth() || {};

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<INotification<unknown>[]>(
    []
  );
  const [tables, setTables] = useState<ITableListItem[]>([]);
  const [selectedItems, setSelected] = useState<string[]>([]);
  const [mode, setMode] = useState<"" | "selecting">("");
  const [selectedFilter, setSelectedFilter] = useState("-1");
  const [notified, setNotified] = useGlobal("notified");
  //LANGUAGE TRANSLATION
  const { t } = useTranslation();
  const sessionId = user?.sessionId || "";

  const loadSessions = useCallback(async (background = false) => {
    background || setLoading(true);
    try {
      const _notifications = await sessionsRepo.getNotifications();
      setNotifications(_notifications);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const loadTables = useCallback(async (background = false) => {
    background || setLoading(true);
    try {
      const _tables = await tablesRepo.list();
      setTables(_tables);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (open) {
      loadSessions().then();
      if (!user?.tableId) {
        loadTables().then();
      }
    } else {
      setSelectedFilter("-1");
    }
  }, [open, loadSessions, loadTables, user?.tableId]);

  useEffect(() => {
    if (notified && open) {
      setNotified(false).then();
      loadSessions(true).then();
      if (!user?.tableId) {
        loadTables().then();
      }
    }
  }, [notified, setNotified, open, loadSessions, loadTables, user?.tableId]);

  function handleClose() {
    setMode("");
    setSelected([]);
    onClose?.();
  }

  async function deleteItem(id: string) {
    setLoading(true);
    try {
      const { notifications } = await sessionsRepo.removeNotification(
        sessionId,
        [id]
      );
      setNotifications(notifications);
    } catch (error) {
      showError({ message: t("msg_error_notification_deleting") });
    }
    setLoading(false);
  }

  function handleClick(id: string) {
    if (mode !== "selecting") return;

    setSelected((selected) => {
      const _selectedItems = [...selected];
      const index = _selectedItems.findIndex((_id) => _id === id);
      if (index === -1) {
        _selectedItems.push(id);
      } else {
        _selectedItems.splice(index, 1);
      }
      return _selectedItems;
    });
  }

  function clearSelection() {
    setSelected([]);
    setMode("");
  }

  async function handleDelete() {
    if (mode === "selecting") {
      selectedItems.length && (await deleteSelected());
      setMode("");
      return;
    }

    if (notifications.length) {
      setMode("selecting");
    }
  }

  function selectAll() {
    if (selectedItems.length === filteredNotifications.length) {
      setSelected([]);
      return;
    }
    setSelected(filteredNotifications.map((n) => n._id));
  }

  // eslint-disable-next-line
  async function deleteSelected() {
    setLoading(true);
    try {
      const { notifications } = await sessionsRepo.removeNotification(
        sessionId,
        selectedItems
      );
      if (notification) setNotifications(notifications);
    } catch (_error) {
      showError({ message: t("msg_error_notifications_deleting") });
    }
    setLoading(false);
  }

  const filterChoices = useMemo(() => {
    const notificationsFrom: string[] = [];
    notifications.forEach((notification) => {
      if (!notificationsFrom.includes(notification.from)) {
        notificationsFrom.push(notification.from);
      }
    });
    const filteredTables = tables
      .map(({ user, identifier }) => ({
        value: user as string,
        title: identifier,
      }))
      .filter((table) => {
        return notificationsFrom.includes(table.value);
      });
    filteredTables.unshift({ value: "system", title: t("system") });
    filteredTables.unshift({ value: "-1", title: t("unfiltered") });
    return filteredTables;
  }, [tables, notifications, t]);

  const filteredNotifications = useMemo(() => {
    if (selectedFilter === "-1") {
      return notifications;
    }
    return notifications.filter((notification) => {
      return notification.from === selectedFilter;
    });
  }, [notifications, selectedFilter]);

  const NotificationsTopBar = (
    <>
      <TopBarBox>
        <div>{t("notificationsTxt")}:</div>
        <div style={{ paddingRight: 32, margin: "-10px 0px -25px" }}>
          {(notifications?.length && (
            <Space size={8}>
              {mode === "" || (
                <TopBarButtonBox onClick={selectAll}>
                  <ActionButton shape="circle" icon={<CheckOutlined />} />
                  <div style={{ fontSize: 10, textAlign: "center" }}>
                    {selectedItems.length === filteredNotifications.length
                      ? t("nothing")
                      : t("all")}
                  </div>
                </TopBarButtonBox>
              )}
              {mode === "" || (
                <TopBarButtonBox onClick={clearSelection}>
                  <ActionButton shape="circle" icon={<RollbackOutlined />} />
                  <div style={{ fontSize: 10, textAlign: "center" }}>
                    {t("cancelTxt")}
                  </div>
                </TopBarButtonBox>
              )}
              <TopBarButtonBox onClick={handleDelete}>
                <Badge
                  count={
                    <CounterBadge>{selectedItems?.length || 0}</CounterBadge>
                  }
                  size="small"
                  offset={[-5, 8]}
                  showZero
                  style={{ opacity: mode === "" ? 0 : 1 }}
                >
                  <ActionButton shape="circle" icon={<DeleteOutlined />} />
                  <div style={{ fontSize: 10, textAlign: "center" }}>
                    {t("removeTxt")}
                  </div>
                </Badge>
              </TopBarButtonBox>
            </Space>
          )) ||
            null}
        </div>
      </TopBarBox>
      {!user?.tableId && (
        <FilterBox>
          <FilterLabel>
            <IconFilter
              className={"text-primary"}
              style={{ fontSize: "16px" }}
            />
          </FilterLabel>
          <InputSelect
            choices={filterChoices}
            value={selectedFilter}
            onChange={(e) => {
              clearSelection();
              setSelectedFilter(e);
            }}
          />
        </FilterBox>
      )}
    </>
  );

  return (
    <Modal
      title={NotificationsTopBar}
      open={open}
      closable
      onClose={handleClose}
    >
      <Loader loading={loading}>
        <PerfectScrollbar>
          <div className="padding-content">
            {filteredNotifications?.length ? (
              filteredNotifications.map((item) => {
                const NotificationTemplate =
                  NotificationTemplates[
                    item.eventType as keyof typeof NotificationTemplates
                  ] || NotificationTemplates["defaultNotification"];
                return (
                  <div key={item._id}>
                    <NotificationTemplate
                      {...(item as any)}
                      mode={mode}
                      onDelete={deleteItem}
                      selected={selectedItems.includes(item._id)}
                      onClick={
                        mode === "selecting"
                          ? () => handleClick(item._id)
                          : undefined
                      }
                    />
                    <Divider />
                  </div>
                );
              })
            ) : (
              <EmptyItem></EmptyItem>
            )}
          </div>
        </PerfectScrollbar>
      </Loader>
    </Modal>
  );
};
const FilterLabel = styled.div`
  margin: 8px 5px 0px 0px;
  display: flex;
`;
const FilterBox = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
const TopBarBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopBarButtonBox = styled.div`
  text-align: center;
  line-height: 1;
  cursor: pointer;
  /* margin: -90px 0px; */
`;

const EmptyItem = () => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t("noNotifications")}
      />
      <Divider />
    </>
  );
};

// const ActionsBox = styled.div``
const NotificationTemplates = {
  billPayment: function BillPayment(
    props: INtController<IDefaultNotification>
  ) {
    const setCmdData = useGlobal("commandCenter")[1];

    const handleClick = () => {
      if (props.onClick) {
        props.onClick();
      } else {
        setCmdData({ ...props, billType: "full" }).then();
      }
    };

    return (
      <NotificationAlert
        mode={props.mode}
        selected={props.selected}
        message={i18n.t("msg_table_requesting_bill", {
          id: props.note.table?.identifier,
        })}
        type="warning"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={handleClick}
        actions={({ close }) => (
          <ActionButton
            icon={<ControlOutlined />}
            onClick={() => {
              handleClick();
              close();
            }}
          />
        )}
      />
    );
  },

  partialBillPayment: function PartialBillPayment(
    props: INtController<IDefaultNotification>
  ) {
    const setCmdData = useGlobal("commandCenter")[1];

    const handleClick = () => {
      if (props.onClick) {
        props.onClick();
      } else {
        setCmdData({ ...props, billType: "partial" }).then();
      }
    };
    return (
      <NotificationAlert
        mode={props.mode}
        selected={props.selected}
        message={i18n.t("msg_client_from_table_requestin_bill", {
          cId: props.note.clientNumber,
          id: props.note.table?.identifier,
        })}
        type="warning"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={handleClick}
        actions={({ close }) => (
          <ActionButton
            icon={<ControlOutlined />}
            onClick={() => {
              handleClick();
              close();
            }}
          />
        )}
      />
    );
  },

  billResponse: function BillResponse(props: INtController<IBill>) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();
    return (
      <NotificationAlert
        mode={props.mode}
        selected={props.selected}
        message={
          <>
            <Link onClick={() => showBillResponse(props.note.message)}>
              {props.note.message
                ? t("accountReadyTxt")
                : t("request_your_account")}
            </Link>
          </>
        }
        type={props.note.message ? "success" : "info"}
        timeStamp={props.createdAt}
        onClick={props.onClick}
        onDelete={() => props.onDelete(props._id)}
      />
    );
  },

  callWaitress(props: INtController<IDefaultNotification>) {
    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={i18n.t("msg_table_calling_waitress", {
          id: props.note.table?.identifier,
        })}
        type="warning"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },

  permissionToOrder: function PermissionToOrder(
    props: INtController<IDefaultNotification>
  ) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();
    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={i18n.t("msg_table_permission_to_order", {
          id: props.note.table?.identifier,
        })}
        type="warning"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
        actions={({ close }) => (
          <>
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();
                customerRepo
                  .allowToOrder(props.note.sessionId, true)
                  .then((_res) => {
                    showSuccess({ message: t("permissionApprovedTxt") });
                    close();
                  });
              }}
            >
              {t("approveTxt")}
            </ActionButton>
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();
                customerRepo
                  .allowToOrder(props.note.sessionId, false)
                  .then((_res) => {
                    showSuccess({ message: t("permissionDeniedTxt") });
                    close();
                  });
              }}
            >
              {t("deniedTxt")}
            </ActionButton>
          </>
        )}
      />
    );
  },

  canOrderResponse: function CanOrderResponse(
    props: INtController<{ canOrder: boolean }>
  ) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();
    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={
          props.note.message.canOrder
            ? t("successOrderPermissionResp")
            : t("deniedOrderPermissionResp")
        }
        type="success"
        onDelete={() => props.onDelete(props._id)}
        timeStamp={props.createdAt}
        onClick={props.onClick}
      />
    );
  },

  menuOfferUpdated: function MenuOfferUpdated(
    props: INtController<IMenuOfferEvent>
  ) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();
    const offerName = props.note.message.offerName;
    const offerStatus = props.note.message.offerStatus;
    const message = offerStatus
      ? t("offerUpdatedTxt", { offer: offerName })
      : t("disabledOfferTxt", { offer: offerName });
    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={message}
        type="info"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },

  menuOfferDeleted: function MenuOfferDeleted(
    props: INtController<IMenuOfferEvent>
  ) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();
    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={t("offerDeletedTxt", { offer: props.note.message.offerName })}
        type="info"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },

  menuUpdated: function MenuUpdated(props: INtController<IMenuEvent>) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();
    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={t("menuUpdatedTxt", { menu: props.note.message.name })}
        type="info"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },

  menuDeleted: function MenuDeleted(props: INtController<IMenuEvent>) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();
    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={t("menuRemovedTxt", { menu: props.note.message.name })}
        type="info"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },

  offerAlertAfter: function OfferAlertAfter(
    props: INtController<IMenuOfferEvent>
  ) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();

    const message = t("alertAfter", {
      amount: props?.note?.message.amount,
      offerName: props?.note?.message.offerName,
    });

    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={message}
        type="warning"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },

  offerNonExistent: function OfferNonExistent(
    props: INtController<IMenuOfferEvent>
  ) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();

    const message = t("nonExistent", {
      amount: props?.note?.message.amount,
      offerName: props?.note?.message.offerName,
    });
    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={message}
        type="warning"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },

  offerMinimalExistence: function OfferMinimalExistence(
    props: INtController<IMenuOfferEvent>
  ) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();

    const message = t("minimalExistence", {
      amount: props?.note?.message.amount,
      offerName: props?.note?.message.offerName,
    });

    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={message}
        type="warning"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },

  orderTicket: function OrderTicket(
    props: INtController<{
      phone: string;
      email: string;
      orderShortId: string;
      bill: IBillOrders;
    }>
  ) {
    //LANGUAGE TRANSLATION
    const { t } = useTranslation();

    const handleClick = () => {
      if (props.onClick) {
        props.onClick();
      } else {
        showOrderTicketResponse(props.note.message, t);
      }
    };

    const message = t("view_order_ticket");

    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={message}
        type="info"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={handleClick}
      />
    );
  },

  liveChat: function LiveChat({ _id, selected, mode, note, createdAt, onClick, onDelete }: INtController<string>) {
    return (
      <NotificationAlert
        selected={selected}
        mode={mode}
        message={note.message}
        type="info"
        timeStamp={createdAt}
        onDelete={() => onDelete(_id)}
        onClick={onClick}
      />
    );
  },

  defaultNotification: function DefaultNotification(
    props: INtController<IDefaultNotification>
  ) {
    const msg = props?.note?.message;

    const message = useMemo(() => {
      if (typeof msg !== "string") return "Unknown notification";
      return msg;
    }, [msg]);

    return (
      <NotificationAlert
        selected={props.selected}
        mode={props.mode}
        message={message}
        type="error"
        timeStamp={props.createdAt}
        onDelete={() => props.onDelete(props._id)}
        onClick={props.onClick}
      />
    );
  },
};

const CounterBadge = styled.span`
  background: red;
  border-radius: 50px;
  font-size: 11px;
  padding: 2px 4px;
  color: white;
`;

export type IDefaultNotification = string;

export interface IMenuEvent {
  _id: string;
  name: string;
}

export interface IMenuOfferEvent {
  offerId: string;
  offerName: string;
  menus: string[];
  offerStatus?: boolean;
  amount?: number;
}

export interface INtController<T> extends INotification<T> {
  onDelete: (id: string) => void;
  selected: boolean;
  onClick(): void;
  mode: "" | "selecting";
}
