import { buildTime } from '../notifications/NotificationAlert'
import { Button, Col, Form, Input, Row, TimePicker } from 'antd'
import { IconPrinter } from 'components/common/Icons'
import { IDeliverySpecs } from 'models/admin/orders/types'
import { InputSelect, InputText } from 'components/common/Inputs'
import { Modal } from 'components/common'
import { useAuth } from '../../hooks/auth.hook'
import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import React, { useRef, useState } from 'reactn'

interface FormUserDataProps {
  resolver: null | ((p?: IDeliverySpecs) => void)
  edit?: boolean
  userData?: IDeliverySpecs
}

const colStyle = {
  padding: '0 8px',
}

const storedUserData = (() => {
  const cache = localStorage.getItem('user-data')
  if (!cache) {
    return {}
  }
  const parsedCache = JSON.parse(cache)

  parsedCache.deliveryTime = moment(parsedCache.deliveryTime || moment().add(30, 'minutes'))
  parsedCache.shippingLocation = parsedCache.shippingLocation?._id || ''

  return parsedCache
})()

export const FormUserData = (props: FormUserDataProps) => {
  const [valueTimePicker, setValueTimePicker] = useState(null)

  const onChangeTimePicker = (time: any) => setValueTimePicker(time)

  const { user } = useAuth() || {}
  const { resolver, edit = true, userData } = props

  //LANGUAGE TRANSLATION
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const componentToPrintRef = useRef(null)

  const onFinish = (values: any) => {
    if (!values.email) {
      values.email = undefined
    }

    if (values.phoneNumber) {
      values.phoneNumber = values.phoneNumber.startsWith('+53')
        ? values.phoneNumber
        : '+53' + values.phoneNumber
    }

    if (!values.deliveryTime) {
      values.deliveryTime = moment().add(30, 'minutes')
    }

    if (values.shippingLocation) {
      const deliveryLocation = user?.deliveryLocations?.find(
        (dl) => dl._id === values.shippingLocation,
      )
      if (deliveryLocation) {
        values.shippingLocation = { name: deliveryLocation.name, _id: deliveryLocation._id }
      } else {
        values.shippingLocation = null
      }
    }

    localStorage.setItem('user-data', JSON.stringify(values))

    resolver?.(JSON.parse(JSON.stringify(values)))

    if (userData) form.resetFields() // Maybe this line should be replaced with 'form.setFieldValue(userData || storedUserData)'
  }

  const validateMessages = {
    required: '${label} ' + t('is_required'),
    types: {
      email: t('is_not_valid'),
    },
  }

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  })

  const onClose = () => {
    resolver?.()
  }

  const getDeliveryLocationData = (deliveryLocId: string | undefined) => {
    if (!deliveryLocId) return ''
    const deliveryLocation = user?.deliveryLocations?.find((dl) => dl._id === deliveryLocId)

    return `(${deliveryLocation?.price.amount} ${deliveryLocation?.price.unit.shortName})`
  }

  if (!edit) {
    return (
      <Modal open={!!resolver} title={t('delivery_details')} position="center" onClose={onClose}>
        <div className="padding-content" ref={componentToPrintRef}>
          <Row>
            <Col span={24} style={colStyle}>
              {user?.deliveryLocations && user?.deliveryLocations.length ? (
                <p>
                  {t('shipping_location')}:{' '}
                  {`${userData?.shippingLocation?.name} ${getDeliveryLocationData(
                    userData?.shippingLocation._id,
                  )}`}
                </p>
              ) : null}

              {user?.handledDeliveryTime ? (
                <p>
                  {t('delivery_time')}: {buildTime(userData?.deliveryTime?.toString() || '')}
                </p>
              ) : null}

              <p>
                {t('name')}: {userData?.name}, {userData?.lastName}
              </p>

              <p>
                {t('phone_number')}:
                <a href={`tel:${userData?.phoneNumber}`}>{userData?.phoneNumber}</a>
              </p>

              <p>
                {t('email')}: <a href={`mailto:${userData?.email}`}>{userData?.email}</a>
              </p>

              <p>
                {t('address')}: {userData?.address}
              </p>
            </Col>
          </Row>
        </div>
        <div className="padding-content" style={{ paddingTop: 0 }}>
          <Row>
            <Col span={24} style={colStyle}>
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  icon={<IconPrinter />}
                  onClick={handlePrint}
                >
                  {t('print')}
                </Button>

                <Button type="primary" htmlType="submit" onClick={onClose}>
                  {t('close')}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    )
  }

  return (
    <Modal open={!!resolver} title={t('fill_your_data')} position="center" onClose={onClose}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="padding-content"
        initialValues={userData || storedUserData}
      >
        <Row>
          <Col span={12} style={colStyle}>
            <Form.Item name={'name'} label={t('name')} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} style={colStyle}>
            <Form.Item name={'lastName'} label={t('last_name')} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={colStyle}>
            <Form.Item
              name={'phoneNumber'}
              label={t('phone_number')}
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/(^(\+53)?[0-9]{8})/),
                  message: t('invalid_phone'),
                },
              ]}
            >
              <InputText prefix={'+53'} placeholder="52010101" maxLength={8} />
            </Form.Item>

            <Form.Item name={'email'} label={t('email')} rules={[{ type: 'email' }]}>
              <Input />
            </Form.Item>

            {user?.handledDeliveryTime ? (
              <Form.Item
                name={'deliveryTime'}
                label={t('delivery_time')}
                rules={[{ type: 'object' as const }]}
              >
                <TimePicker
                  format="h:mm a"
                  defaultValue={moment().add(30, 'minutes')}
                  value={valueTimePicker || moment().add(30, 'minutes')}
                  onChange={onChangeTimePicker}
                />
              </Form.Item>
            ) : null}

            {user?.deliveryLocations && user?.deliveryLocations.length ? (
              <Form.Item
                name={'shippingLocation'}
                label={t('shipping_location')}
                rules={[{ required: true }]}
              >
                <InputSelect
                  choices={user?.deliveryLocations.map((dl) => {
                    return { title: `${dl.name} ${getDeliveryLocationData(dl._id)}`, value: dl._id }
                  })}
                />
              </Form.Item>
            ) : null}

            {/* TODO: HANDLE THE LOCATION DELIVERIES */}

            <Form.Item name={'address'} label={t('address')} rules={[{ required: true }]}>
              <Input.TextArea />
            </Form.Item>

            <Form.Item>
              <div style={{ textAlign: 'right' }}>
                <Button type="primary" htmlType="submit">
                  {t('accept')}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
