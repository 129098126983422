import { lazy, Suspense } from 'react'

const LazyMenuPage = lazy(() => import("./MenuPage"));
const LazyOrderPage = lazy(() => import("./OrderPage"));
const LazyLicensePage = lazy(() => import("./LicensePage"));
const LazyThankyouPage = lazy(() => import("./ThankyouPage"));
const LazyLoginTablePage = lazy(() => import("./LoginTablePage"));

export const MenuPage = () => (<Suspense fallback="Loading..."><LazyMenuPage /></Suspense>)
export const OrderPage = () => (<Suspense fallback="Loading..."><LazyOrderPage /></Suspense>)
export const LicensePage = () => (<Suspense fallback="Loading..."><LazyLicensePage /></Suspense>)
export const ThankyouPage = () => (<Suspense fallback="Loading..."><LazyThankyouPage /></Suspense>)
export const LoginTablePage = () => (<Suspense fallback="Loading..."><LazyLoginTablePage /></Suspense>)

export enum MenuPageMode {
  LISTING = '',
  ORDERING = 'ordering',
  VIEWING = 'viewing',
}

export enum OrderPageMode {
  LISTING = '',
  PAYING = 'paying',
}
