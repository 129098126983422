import { ActionBar } from '../view-layout'
import { Popover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'
import { useTranslation } from 'react-i18next'
import PushButton from './PushButton'
import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import Title from 'antd/lib/typography/Title'

export interface ActionConfirmationProps extends PopoverProps {
  okText?: string
  cancelText?: string
  actions?: ReactNode
  title?: string
  message: string | ReactNode
  onConfirm?(): void
  onDiscard?(): void
  maxWidth?: number
}

export function ActionConfirmation(props: ActionConfirmationProps) {
  const { t } = useTranslation()
  const [_visible, _onVisibleChange] = useState(false)

  const {
    onDiscard,
    onConfirm,
    children,
    placement = 'top',
    trigger = 'click',
    okText,
    cancelText,
    actions,
    title,
    message,
    visible = _visible,
    maxWidth = 350,
  } = props

  let { onVisibleChange } = props

  if (onVisibleChange === undefined) {
    onVisibleChange = _onVisibleChange
  }

  const handleClick = (status: boolean) => () => {
    onVisibleChange?.(false)
    if (status) onConfirm?.()
    if (!status) onDiscard?.()
  }

  const _actions = (onConfirm || onDiscard) && (
    <ActionBar>
      {onDiscard && (
        <PushButton style={{ width: '100%' }} onClick={handleClick(false)}>
          {cancelText || t('cancel')}
        </PushButton>
      )}
      {onConfirm && (
        <PushButton style={{ width: '100%' }} onClick={handleClick(true)} type="primary">
          {okText || t('confirm')}
        </PushButton>
      )}
    </ActionBar>
  )

  const _content = (
    <Container style={{ maxWidth }}>
      <div className="padding-content">
        <Title className="text-primary" level={4}>
          {title}
        </Title>
      </div>
      <div className="padding-content">{message}</div>
      <Footer>{actions || _actions}</Footer>
    </Container>
  )

  return (
    <Popover
      content={_content}
      placement={placement}
      trigger={trigger}
      onVisibleChange={onVisibleChange}
      visible={visible}
    >
      {children}
    </Popover>
  )
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 0.9);
`

const Footer = styled.div`
  display: flex;
  justify-content: stretch;
`
