import { Alert, Button, Popconfirm, Popover } from "antd";
import { ButtonProps } from "antd/lib/button";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import React, { ReactNode, useMemo, useRef } from "react";
import styled, { CSSProperties } from "styled-components";

interface NotificationAlertProps {
  message: ReactNode;
  type: "info" | "warning" | "success" | "error";
  timeStamp: string;
  mode: "" | "selecting";
  selected: boolean;
  onClick?(): void;
  actions?: (args: { close: () => void }) => ReactNode;
  onDelete?: () => void;
}

export function NotificationAlert(props: NotificationAlertProps) {
  const {
    message,
    type,
    timeStamp,
    onDelete,
    actions,
    mode,
    selected,
    onClick,
  } = props;
  const { t } = useTranslation();

  const el = useRef<HTMLButtonElement>(null);

  function handleClick() {
    if (onClick) {
      onClick();
    } else {
      el.current?.click();
    }
  }

  function close() {
    el.current?.click();
  }

  const icon = useMemo(() => {
    if (type === "info") return <InfoCircleOutlined />;
    if (type === "success") return <InfoCircleOutlined />;
    if (type === "error") return <InfoCircleOutlined />;
    return <ExclamationCircleOutlined />;
  }, [type]);

  return (
    <NotificationBox
      onClick={handleClick}
      bordered={mode === "selecting"}
      selected={selected}
    >
      <Alert
        style={messageBoxStyle}
        message={<div>{message}</div>}
        icon={icon}
        type={type}
        showIcon
      />
      <TimeBox>{buildTime(timeStamp)}</TimeBox>
      <Popover
        placement="topRight"
        style={{ padding: 0 }}
        content={
          <div style={{ margin: -8, display: "flex", gap: 5 }}>
            {actions?.({ close })}
            <Popconfirm
              title={
                <>
                  {t("msg_delete_notification_may_loose_event")}
                  <br /> {t(
                    "msg_delete_notification_may_loose_event_1"
                  )} <br /> {t("msg_continue")}
                </>
              }
              placement="bottomRight"
              onConfirm={(e) => {
                e?.stopPropagation();
                onDelete?.();
              }}
              onCancel={(e) => e?.stopPropagation()}
              okText={t("yes")}
              cancelText={t("no")}
            >
              {onDelete && (
                <ActionButton
                  onClick={(e) => e.stopPropagation()}
                  icon={<DeleteOutlined />}
                />
              )}
            </Popconfirm>
          </div>
        }
        trigger="click"
      >
        <Button
          ref={el}
          style={moreBtnStyle}
          onClick={(e) => e.stopPropagation()}
          type="text"
          icon={<MoreOutlined />}
        />
      </Popover>
    </NotificationBox>
  );
}

export const ActionButton = (props: ButtonProps) => (
  <Button style={{ border: "none", boxShadow: "none" }} {...props}>
    {props.children}
  </Button>
);

const NotificationBox = styled.div<{ bordered: boolean; selected: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 3px;
  position: relative;
  padding-right: 35px;
  cursor: pointer;

  /* &:hover {
    background: #cce8ff;
  } */
  ${({ bordered }) => bordered && "background: #f1f1f1;"}
  ${({ selected }) => selected && "background: #cce8ff;"}
`;

const TimeBox = styled.div`
  margin-left: auto;
  margin-top: -8px;
  padding: 0 4px;
  color: #a60068;
  align-self: flex-end;
`;

const messageBoxStyle: CSSProperties = {
  background: "transparent",
  border: "none",
};

const moreBtnStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  right: 6,
  bottom: 0,
  margin: "auto",
};

export function buildTime(time: string, completeDate = false) {
  try {
    const date = new Date(time);
    const defaultOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    } as const;
    const options = completeDate
      ? ({
          ...defaultOptions,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        } as const)
      : defaultOptions;

    return new Intl.DateTimeFormat("en", options).format(date);
  } catch (error) {
    return "";
  }
}
