// REACT
// GLOBAL
import { Typography } from 'antd'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
// LOCAL
import { Buttons } from '../../common'
import { IPrice } from '../../../models'
import { useTranslation } from 'react-i18next'

const { ButtonNote } = Buttons
const { Title, Paragraph } = Typography

const OrderOfferItem = ({ name, summary, notes, counter, price }: IOrderOfferItemProps) => {
  const [showNotes, setShowNotes] = useState<boolean>(true)
  const { t } = useTranslation()

  const handleNoteClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      setShowNotes(!showNotes)
    },
    [showNotes],
  )

  return (
    <ItemContainer>
      <ContentContainer>
        <ButtonNoteContainer>
          <ButtonNote
            disabled={!notes}
            iconOnly={true}
            type="text"
            className="text-primary"
            help={showNotes ? t('msg_hide_notes') : t('msg_show_notes')}
            onClick={handleNoteClicked}
          />
        </ButtonNoteContainer>
        <TitleContainer>
          <Title level={5}>{name}</Title>
          <Paragraph ellipsis={{ rows: 2 }}>{summary}</Paragraph>
        </TitleContainer>
      </ContentContainer>
      <ActionsContainer style={{ width: price ? '100%' : 'auto' }} inline={!price}>
        {price && (
          <div style={{ fontSize: 17, fontWeight: 'bold' }} className={'text-primary'}>
            {price?.amount} {price?.unit?.shortName}
          </div>
        )}
        <CountContainer onClick={(e) => e.stopPropagation()}>{counter}</CountContainer>
      </ActionsContainer>
      {notes && <NoteContainer isVisible={showNotes}>{notes}</NoteContainer>}
    </ItemContainer>
  )
}

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 1em 0 0 0;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`

const ButtonNoteContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
`

const CountContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  padding: 15px 15px 0;
  margin: -15px -15px 0;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  & > * {
    flex: 0 0 100%;
  }
`

const ActionsContainer = styled.div<{ inline: boolean }>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: ${(props: any) => (props.inline ? 'flex-end' : 'space-between')};
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`

const NoteContainer = styled.div<{ isVisible?: boolean }>`
  width: 100%;
  overflow: hidden;
  height: ${(props) => (props.isVisible ? 'auto' : 0)};
  transition: all 0.5s ease-in-out;
  padding: 1em 0;
`
export interface IOrderOfferItemProps {
  name: string
  summary?: string
  counter?: React.ReactNode
  notes?: React.ReactNode
  price?: IPrice
}

export default React.memo(OrderOfferItem)
