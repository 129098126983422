// REACT
import React, { useState } from 'react'

// GLOBAL IMPORTS
import { IPopoverProps, Popover } from '../common'

// LOCAL IMPORTS
import { List, ListItem } from '../common'
import { TOrderStatus } from '../../models/admin/orders'

const OrderStatusList = ({ statusList, onChange }: IOrderStatusListProps) => {
  const renderItem = ({ title, value }: { title: string; value: TOrderStatus }) => {
    const handleClick = onChange && (() => onChange(value))
    return <ListItem key={value} title={title} onClick={handleClick} />
  }
  return <List items={statusList} renderItem={renderItem} />
}

export const OrderStatus = ({ orderStatus, statusList, onChange }: IOrderStatusProps) => {
  const getAvailableStatus = (
    orderStatus: TOrderStatus,
    statusList: { title: string; value: TOrderStatus }[],
  ): { title: string; value: TOrderStatus }[] => {
    return statusList.filter(({ value }) => value !== orderStatus)
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <OrderStatusList
        statusList={getAvailableStatus(orderStatus, statusList)}
        onChange={onChange}
      />
    </div>
  )
}

export const OrderStatusPopover = ({
  orderStatus,
  statusList,
  onChange,
  ...props
}: IOrderStatusPopoverProps) => {
  // STATE
  const [visible, setVisible] = useState<boolean>(false)

  const handleChange =
    onChange &&
    ((status: TOrderStatus) => {
      onChange(status)
      setVisible(false)
    })
  return orderStatus && statusList && statusList.length && onChange ? (
    <Popover
      {...props}
      trigger="click"
      content={
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <OrderStatus orderStatus={orderStatus} statusList={statusList} onChange={handleChange!} />
      }
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
    />
  ) : null
}

export interface IOrderStatusPopoverProps extends IPopoverProps {
  orderStatus?: TOrderStatus
  statusList?: { title: string; value: TOrderStatus }[]
  onChange?: (status: TOrderStatus) => unknown
}

export interface IOrderStatusProps {
  orderStatus: TOrderStatus
  statusList: { title: string; value: TOrderStatus }[]
  onChange: (status: TOrderStatus) => unknown
}

export interface IOrderStatusListProps {
  statusList: { title: string; value: TOrderStatus }[]
  onChange: (status: TOrderStatus) => unknown
}

export type { TOrderStatus }
