import { useCallback } from 'react'

export const useHtmlElement = () => {
  const getRect = useCallback((container: Element | null): DOMRect | null => {
    return container ? container.getBoundingClientRect() : null
  }, [])

  const getHeight = useCallback((container: HTMLElement | null): number | null => {
    return container ? container.clientHeight : null
  }, [])

  const getTop = useCallback((container: HTMLElement | null): number | null => {
    return container ? container.offsetTop : null
  }, [])

  const getBottom = useCallback(
    (container: HTMLElement | null): number | null => {
      const top = getTop(container)
      const height = getHeight(container) || 0
      return container && top ? top + height : null
    },
    [getTop, getHeight],
  )

  const getByClassName = (className: string): Element[] => {
    const elements = document.getElementsByClassName(className)
    const elementsAsArray = []
    if (elements) {
      for (let i = 0; i < elements.length; ++i) {
        if (elements.item(i) !== null) {
          elementsAsArray.push(elements.item(i))
        }
      }
    }
    return elementsAsArray as Element[]
  }

  const copyToClipboard = useCallback((content: string): void => {
    const el = document.createElement('textarea')
    el.value = content
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }, [])

  return {
    getRect,
    getTop,
    getBottom,
    getHeight,
    getByClassName,
    copyToClipboard,
  }
}
