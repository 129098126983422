// GLOBAL IMPORTS
import React from 'react'

// LOCAL IMPORTS
import { IconDone } from './Icons'
import { IListItemProps } from './ListItem'
const SelectableListItem = ({
  isSelected,
  onSelect,
  children,
  className,
  ...props
}: ISelectableListItemProps) => {
  if (children) {
    const firstChildren = React.Children.toArray(children)[0] as React.ReactElement<any>

    const childrenClone = React.cloneElement(firstChildren, {
      ...props,
      className: `${className || ''} ${isSelected ? 'selected' : ''} ${
        onSelect ? 'clickable' : ''
      }`.trim(),
      actions: isSelected
        ? [<IconDone key="icon-done" style={{ fontSize: '1.5em' }} />]
        : undefined,
      onClick: onSelect,
    })

    return childrenClone
  }
  return null
}

export interface ISelectableListItemProps extends IListItemProps {
  isSelected: boolean
}
export default SelectableListItem
