import { lazy, Suspense } from 'react'

const LazyTestPage = lazy(() => import("./Test.page"));
const LazyErrorPage = lazy(() => import("./ErrorPage"));
const LazyLoginPage = lazy(() => import("./LoginPage"));
const LazyDeniedPage = lazy(() => import("./DeniedPage"));
const LazyNotFoundPage = lazy(() => import("./NotFoundPage.jsx"));

export const TestPage = () => (<Suspense fallback="Loading..."><LazyTestPage /></Suspense>)
export const LoginPage = () => (<Suspense fallback="Loading..."><LazyLoginPage /></Suspense>)
export const ErrorPage = () => (<Suspense fallback="Loading..."><LazyErrorPage /></Suspense>)
export const DeniedPage = () => (<Suspense fallback="Loading..."><LazyDeniedPage /></Suspense>)
export const NotFoundPage = () => (<Suspense fallback="Loading..."><LazyNotFoundPage /></Suspense>)
