// REACT
import { Button, DatePicker, Popover, Result } from 'antd'
import { ClockCircleOutlined, ControlOutlined, MessageFilled } from '@ant-design/icons'
import styled from 'styled-components'
// LOCAL IMPORTS
import { Buttons, Modal, Notification, PushButton } from 'components/common'
import {
  IOrderOfferItem,
  IOrderOfferStatusResponse,
  OrderDetails,
  OrderForm,
  OrderListView,
} from 'components/orders'
import { useAuth } from 'hooks/auth.hook'
// HOOKS
import { Devices, useDevice } from 'hooks/device.hook'
import EmployeeRepository, { ERoles, IEmployee } from 'models/admin/employees'
import { Page } from 'layouts/admin'
import { useBack, useHash, useParams } from 'hooks/route.hook'
// REPOS
import { IOfferListItem } from 'models/admin/offer.repository'
import moment from 'moment'
import OrderRepository, {
  EOrderOfferStatus,
  EOrderStatus,
  IOrder,
  IOrderForm,
  IOrderListItem,
  OrderStatus,
  TOrderOfferStatus,
  TOrderStatus,
} from 'models/admin/orders'
import { useCallback, useEffect, useMemo, useState } from 'react'
import TableRepository, { ITable, ITableListItem } from 'models/admin/table.repository'

// GLOBAL
import { formTitle, PageMode } from '.'
import { FormUserData } from 'components/orders/FormUserData'
import { IDeliverySpecs } from 'models/admin/orders/types'
import { ISMessage, useSocketConfirmation } from '../../storage/socket.storage'
import { Link } from 'react-router-dom'
import { SessionsRepository } from '../../models/admin/sessions.repository'
import { TFunction, useTranslation } from 'react-i18next'
import { useGlobal, useRef } from 'reactn'
import { usePointer } from 'hooks/state.hook'
import i18n from 'i18n'
import { FormSendMessage } from 'components/orders/FormSendMessage';
import { useMemoizedFn } from 'ahooks';
import LiveChatRepository, { ILiveChatMessage } from 'models/admin/live-chat.repository';

const { RangePicker } = DatePicker
const { showSuccess, showError } = Notification
const { ButtonGroup, ButtonPendingGroup } = Buttons

// REPOSITORIES
const orderRepo = new OrderRepository()
const tableRepo = new TableRepository()
const sessionsRepo = new SessionsRepository()
const employeeRepo = new EmployeeRepository();
const liveChatRepo = new LiveChatRepository();

const DATE_FORMAT = 'YYYY-MM-DD'

const OrderPage = () => {
  const device = useDevice()
  const { goBack } = useBack()
  const $goBack = usePointer(goBack)
  const { hash, setHash } = useHash()
  const [socket] = useGlobal('socket')
  const setCmd = useGlobal('commandCenter')[1]
  const { checkPermission, user } = useAuth() || {}
  const sendConfirmation = useSocketConfirmation(socket)
  const [loading, setLoading] = useState<boolean>(false)
  const [order, setOrder] = useState<IOrder | null>(null)
  const { orderStatus } = useParams<{ orderStatus: EOrderStatus }>()
  const [orders, setOrders] = useState<IOrderListItem[]>([])
  const [tables, setTables] = useState<ITableListItem[]>([])
  const [offers, setOffers] = useState<IOfferListItem[]>([])
  const [groupByTable, setGroupByTable] = useState<boolean>(false)
  const [groupByPending, setGroupByPending] = useState<boolean>(false)
  const [showFilterPopover, setShowFilterPopover] = useState<boolean>(false)
  const [showChat, setShowChat] = useState<boolean>(false);
  const refreshOrders = useRef<(backgroundUpdate?: boolean | undefined) => Promise<void>>()
  const [clientMarks, setClientMarks] = useState<number[]>([])
  const [formDataBind, setFormDataBind] = useState<null | ((p?: IDeliverySpecs) => void)>(null)
  const [employees, setEmployess] = useState<IEmployee[]>([]);

  // PERMISSIONS
  const [permissions, setPermissions] /*--*/ = useState<{
    canRead: /*--*/ boolean
    canWrite: /*-*/ boolean
    canDelete: /**/ boolean
  }>({ canRead: false, canWrite: false, canDelete: false })

  const [rangeDate, setRangeDate] = useState<string[]>([
    moment(new Date()).format(DATE_FORMAT),
    moment(new Date()).add(1, 'day').format(DATE_FORMAT),
  ])

  // MEMOS
  const mode = useMemo((): PageMode => {
    return (hash || PageMode.LISTING) as PageMode
  }, [hash])

  // CALLBACKS
  const setMode = useCallback(
    (mode: PageMode) => {
      setHash(mode)
    },
    [setHash],
  )

  // DATA HANDLERS
  const initPermissions = useCallback(() => {
    setPermissions({
      canRead /*--*/: checkPermission ? checkPermission('orders', 'read') /*--*/ : false,
      canWrite /*-*/: checkPermission ? checkPermission('orders', 'write') /*-*/ : false,
      canDelete /**/: checkPermission ? checkPermission('orders', 'delete') /**/ : false,
    })
  }, [checkPermission])

  //LANGUAGE TRANSLATION
  const { t } = useTranslation()

  const activeTables = useMemo(() => {
    return orders
      .map((order) => {
        return order.table
      })
      .reduce((tables, item) => {
        if (
          !tables.find((_item) => {
            if (_item && item) {
              return _item._id === item._id
            }
            return false
          })
        ) {
          tables.push(item)
        }
        return tables
      }, [] as ITable[])
  }, [orders])

  const $tables = usePointer(activeTables)
  const $rawTables = usePointer(tables)

  const filterOrderForCookerAndBarman = useCallback(
    (order: IOrderListItem | IOrder) => {
      if (
        user &&
        (user.roles?.includes(ERoles.BARMAN) || user.roles?.includes(ERoles.COOKER)) &&
        orderStatus === EOrderStatus.IN_PROGRESS
      ) {
        const { offers } = order

        const offersInProgress = offers.filter((offer) => offer[EOrderOfferStatus.PENDING] !== 0)
        return offersInProgress.length
          ? {
              ...order,
              offers: offersInProgress,
            }
          : null
      }
      return order
    },
    [user, orderStatus],
  )

  const listEmployees = useMemoizedFn(async () => {
    const emptyList = Array.from<IEmployee>({ length: 1 });
    try {
      const list = (await employeeRepo.list()).filter(({ liveChatEnabled }) => liveChatEnabled);
      return list.length ? list : emptyList;
    } catch (error) {
      showError({
        message /*----*/: t('errListingOrders'),
        description /**/: t('msg_error_order_listing'),
      });
      return emptyList;
    }
  });

  const listOrders = useCallback(async (): Promise<IOrderListItem[]> => {
    try {
      const orderList = await orderRepo.list({
        status: orderStatus,
        ...(orderStatus === EOrderStatus.IN_PROGRESS
          ? {}
          : { startDate: rangeDate[0], endDate: rangeDate[1] }),
      })
      return orderList
        .map((order) => filterOrderForCookerAndBarman(order))
        .filter((order) => !!order) as IOrderListItem[]
    } catch (err) {
      showError({
        message /*----*/: t('errListingOrders'),
        description /**/: t('msg_error_order_listing'),
      })
      return []
    }
  }, [orderStatus, filterOrderForCookerAndBarman, rangeDate, t])

  const getOrder = useCallback(
    async (orderId = ''): Promise<IOrder | null> => {
      try {
        const orderItem = await orderRepo.get(orderId)

        if (orderItem) {
          return filterOrderForCookerAndBarman(orderItem)
        }
        return orderItem
      } catch (err) {
        showError({
          message /*----*/: t('msg_error_visualizating'),
          description /**/: t('msg_error_order_visualization'),
        })

        return null
      }
    },
    [filterOrderForCookerAndBarman, t],
  )

  const insertOrder = useCallback(
    async (newOrderData: IOrderForm, clientNumber: number): Promise<IOrder | null> => {
      try {
        const newOrder = await orderRepo.insert(newOrderData, { orderMark: clientNumber })
        showSuccess({
          message /*----*/: t('msg_success_inserting'),
          description /**/: t('msg_success_order_inserting'),
        })

        return newOrder
      } catch (err) {
        showError({
          message /*----*/: t('msg_error_inserting'),
          description /**/: t('msg_error_order_inserting'),
        })

        return null
      }
    },
    [t],
  )

  const updateOrder = useCallback(
    async (orderId = '', orderData: IOrderForm): Promise<IOrder | null> => {
      try {
        const updatedOrder = await orderRepo.update(orderId, orderData)

        showSuccess({
          message /*----*/: t('msg_success_menu_update'),
          description /**/: t('msg_success_order_update'),
        })

        return updatedOrder
      } catch (err) {
        showError({
          message /*----*/: t('msg_error_updating'),
          description /**/: t('msg_error_order_update'),
        })

        return null
      }
    },
    [t],
  )

  const updateOrderStatus = useCallback(
    async (orderId: string, status: TOrderStatus) => {
      try {
        await orderRepo.updateOrderStatus(orderId, status)
        showSuccess({
          message /*----*/: t('msg_success_menu_update'),
          description /**/: t('msg_success_order_update'),
        })
      } catch (err) {
        showError({
          message /*----*/: t('msg_error_updating'),
          description /**/: t('msg_error_order_update'),
        })
      }
    },
    [t],
  )

  const updateOrderOfferStatus = useCallback(
    async (
      orderId: string,
      offerId: string,
      status: { from: TOrderOfferStatus; to: TOrderOfferStatus; count: number },
    ) => {
      try {
        const { from, to, count } = status
        await orderRepo.updateOrderOfferStatus(orderId, offerId, {
          currentStatus: from,
          toStatus: to,
          count,
        })
      } catch (err) {
        showError({
          message /*----*/: t('msg_error_updating'),
          description /**/: t('msg_error_order_update_status'),
        })
      }
    },
    [t],
  )

  const listTables = useCallback(async (): Promise<ITableListItem[]> => {
    try {
      return await tableRepo.list()
    } catch (err) {
      showError({
        message /*----*/: t('errListingOrders'),
        description /**/: t('msg_error_table_list'),
      })
      return []
    }
  }, [t])

  const listOffers = useCallback(
    async (tableId: string): Promise<IOfferListItem[]> => {
      try {
        const menus = await tableRepo.getMenus(tableId)
        const offerList: IOfferListItem[] = []

        // MERGE OFFERS FROM DIFFERENT MENUS
        menus.forEach(({ _id, offers }) => {
          const menuOffers: IOfferListItem[] = []
          Object.values(offers).forEach((categoryOffers) => menuOffers.push(...categoryOffers))

          menuOffers.forEach((offer) => {
            offer.menu = _id
          })

          offerList.push(...menuOffers)
        })

        return offerList
      } catch (err) {
        showError({
          message /*----*/: t('errListingOrders'),
          description /**/: t('msg_error_offers_listing'),
        })
        return []
      }
    },
    [t],
  )

  const getTableClient = useCallback(
    async (tableId: string) => {
      try {
        return await sessionsRepo.getTableClients(tableId)
      } catch (e) {
        showError({
          message /*----*/: t('msg_error_tableclients_list'),
          description /**/: t('msg_error_tableclients_listing'),
        })
        return []
      }
    },
    [t],
  )

  // EVENT HANDLERS
  const showOrderList = useCallback(
    async (backgroundUpdate?: boolean) => {
      backgroundUpdate || setMode(PageMode.LISTING)
      backgroundUpdate || setLoading(true)
      setOrders(await listOrders())
      backgroundUpdate || setLoading(false)
    },
    [listOrders, setMode],
  )

  useEffect(() => {
    refreshOrders.current = showOrderList
  }, [showOrderList])

  const loadOffers = useCallback(
    async (tableId: string) => {
      setOffers(await listOffers(tableId))
    },
    [listOffers],
  )

  const loadTableClients = useCallback(
    async (tableId: string) => {
      setClientMarks(await getTableClient(tableId))
    },
    [getTableClient],
  )

  const loadFormDependencies = useCallback(
    async (tableId?: string) => {
      // LOAD TABLES TO BE USED IN FORM
      if (tables.length === 0) {
        setTables(await listTables())
      }

      if (tableId) {
        await loadOffers(tableId)
      }
    },
    // eslint-disable-next-line
    [loadOffers],
  )

  const showOrderDetails = useCallback(
    async (order?: IOrderListItem) => {
      if (order && order?._id) {
        setMode(PageMode.VIEWING)
        setLoading(true)
        setOrder(await getOrder(order?._id))
        await loadFormDependencies(order?.table?._id)
        setLoading(false)
      } else {
        showError({
          message /*----*/: t('msg_error_visualizating'),
          description /**/: t('msg_error_order_visualization'),
        })
      }
    },
    [getOrder, setMode, loadFormDependencies, t],
  )

  const showOrderForm = useCallback(
    async (order?: IOrderListItem) => {
      setLoading(true)
      let modifiedOrder

      if (order && order._id) {
        setMode(PageMode.EDITING)
        modifiedOrder = await getOrder(order._id)
        setOrder(modifiedOrder)
      } else {
        setMode(PageMode.ADDING)
        setOrder(null)
      }

      await loadFormDependencies(order?.table?._id)

      setLoading(false)
    },
    [getOrder, loadFormDependencies, setMode],
  )

  const $order = usePointer(order)

  const showFormUserData = useCallback(() => {
    return new Promise<IDeliverySpecs>((res) => {
      setFormDataBind(() => res)
    }).then((res) => {
      setFormDataBind(null)
      return res
    })
  }, [setFormDataBind])

  const handleSubmitForm = useCallback(
    async (orderData: IOrderForm, clientNumber: number): Promise<void> => {
      //Get table details
      const table = $rawTables.current.find((t) => t._id === orderData.table)

      //If delivery table, fill the userData
      if (table?.isDeliveryTable) {
        const userData = await showFormUserData()

        if (!userData) {
          return
        }

        orderData.deliverySpecs = userData
      }

      $goBack.current()
      setLoading(true)

      if ($order.current && $order.current._id) {
        await updateOrder($order.current._id, orderData)
      } else {
        await insertOrder(orderData, clientNumber)
      }

      setLoading(false)
    },
    [$order, insertOrder, updateOrder, $goBack, $rawTables, showFormUserData],
  )

  const handleCloseFormModal = useCallback(() => {
    $goBack.current()
  }, [$goBack])

  const handleCloseDetailsModal = useCallback(() => {
    $goBack.current()
  }, [$goBack])

  const handleTableChange = useCallback(
    async (tableId: string) => {
      setLoading(true)
      await loadOffers(tableId)
      await loadTableClients(tableId)
      setLoading(false)
    },
    [loadOffers, loadTableClients],
  )

  const handleChangeOrderOfferStatus = useCallback(
    async (
      order: IOrderListItem,
      offer: IOrderOfferItem,
      { status, from }: IOrderOfferStatusResponse,
    ) => {
      setLoading(true)
      const parsedStatus: IOrderOfferStatusRequestBody[] = Object.keys(status)
        .map((statusName) => {
          const statusCount = status[statusName]

          return from !== statusName /* This update get implicit */ && statusCount !== 0
            ? { from, to: statusName as TOrderOfferStatus, count: statusCount }
            : null
        })
        .filter((statusQuery) => statusQuery) as IOrderOfferStatusRequestBody[]

      for (let i = 0; i < parsedStatus.length; ++i) {
        await updateOrderOfferStatus(order._id, offer.offer._id, parsedStatus[i])
      }

      setLoading(false)
    },
    [updateOrderOfferStatus],
  )

  const handleChangeOrderStatus = useCallback(
    async (order: IOrderListItem, status: TOrderStatus) => {
      setLoading(true)
      await updateOrderStatus(order._id, status)
      setLoading(false)
    },
    [updateOrderStatus],
  )

  const handleToggleGroup = useCallback((status: boolean) => {
    setGroupByTable(status)
  }, [])

  const handleTogglePending = useCallback((status: boolean) => {
    refreshOrders.current?.(true)
    setGroupByPending(status)
  }, [])

  const actionRangePicker = useMemo(() => {
    return (
      <FilterContainer device={device}>
        <RangePicker
          defaultValue={[moment(rangeDate[0], DATE_FORMAT), moment(rangeDate[1], DATE_FORMAT)]}
          format={DATE_FORMAT}
          onCalendarChange={(value) => {
            const start = value?.[0]?.format(DATE_FORMAT) || rangeDate[0]
            const end = value?.[1]?.format(DATE_FORMAT) || rangeDate[1]
            setRangeDate([start, end])
          }}
        />
        <Button
          type="primary"
          style={device === Devices.DESKTOP ? { marginLeft: '0.5rem' } : { marginTop: '0.5rem' }}
          onClick={() => {
            setShowFilterPopover(false)
            showOrderList(true).then()
          }}
        >
          {i18n.t('accept')}
        </Button>
      </FilterContainer>
    )
  }, [device, rangeDate, showOrderList])

  const renderButtonGroupByTable = useMemo(
    () => (
      <ButtonGroup
        key="group-table"
        iconOnly={device !== Devices.DESKTOP}
        help={
          device !== Devices.DESKTOP
            ? groupByTable
              ? t('ungroup')
              : t('group_by_table')
            : undefined
        }
        helpPlacement="bottomLeft"
        onChange={handleToggleGroup}
      >
        {device === Devices.DESKTOP
          ? groupByTable
            ? t('ungroup')
            : t('group_by_table')
          : undefined}
      </ButtonGroup>
    ),
    [device, groupByTable, handleToggleGroup, t],
  )

  const renderButtonGroupByPendingOnly = useMemo(
    () => (
      <ButtonPendingGroup
        key="group-pending-orders"
        iconOnly={device !== Devices.DESKTOP}
        help={device !== Devices.DESKTOP ? (groupByPending ? t('all') : t('pending')) : undefined}
        helpPlacement="bottomLeft"
        onChange={handleTogglePending}
      >
        {device === Devices.DESKTOP ? (groupByPending ? t('all') : t('pending')) : undefined}
      </ButtonPendingGroup>
    ),
    [device, groupByPending, handleTogglePending, t],
  )

  const showCmd = useCallback(() => {
    setCmd({
      billType: 'full',
      note: {
        _id: '',
        sessionId: '',
        table: {
          _id: undefined,
          identifier: undefined,
          choices: $tables.current,
        },
        message: '',
      },
    }).then()
  }, [setCmd, $tables])

  const buttonCmd = useMemo(
    () => (
      <PushButton key="pushBtn" onClick={showCmd} icon={<ControlOutlined />}>
        {device === Devices.DESKTOP ? t('accounts') : undefined}
      </PushButton>
    ),
    [showCmd, device, t],
  )

  // SEND MESSAGE BUTTON
  const handleShowMsgModal = useMemoizedFn(async () => {
    setShowChat(true);
    setEmployess(await listEmployees());
  });

  const handleSendMessage = useMemoizedFn(async (message: ILiveChatMessage) => {
    try {
      await liveChatRepo.sendLiveChatMessage(message);
    } catch {
      // 
    }
  });

  const renderButtonChat = useMemo(
    () => (
      <PushButton
        key="chatBtn"
        onClick={handleShowMsgModal}
        loading={showChat && employees.length === 0}
        icon={<MessageFilled />}
      >
        {device === Devices.DESKTOP ? t('send_chat_message') : undefined}
      </PushButton>
    ),
    [t, device, handleShowMsgModal, showChat, employees]
  );

  // EFFECT
  useEffect(() => {
    initPermissions()
    showOrderList().then()
    // eslint-disable-next-line
  }, [orderStatus])

  useEffect(() => {
    const listeners = {
      orderStats(_data: ISMessage<unknown>) {
        // noop
      },
      orderStatusUpdated(_data: ISMessage<unknown>) {
        refreshOrders.current?.(true);
      },
      orderOfferStatusUpdated(_data: ISMessage<unknown>) {
        refreshOrders.current?.(true);
      },
      newOrder(_data: ISMessage<unknown>) {
        refreshOrders.current?.(true);
      },
      billApproved(_data: ISMessage<unknown>) {
        refreshOrders.current?.(true);
      },
    };

    if (socket) {
      Object.entries(listeners).forEach(([listener, controller]) => {
        socket.on(listener, controller)
      })

      return () => {
        Object.entries(listeners).forEach(([listener, controller]) => {
          socket.off(listener, controller)
        })
      }
    }
  }, [socket, refreshOrders, sendConfirmation])

  const renderFilterButton = useMemo(
    () => (
      <Popover
        key="date-picker-popover"
        content={actionRangePicker}
        placement="left"
        title={t('msg_select_date_range')}
        trigger="click"
        visible={showFilterPopover}
        onVisibleChange={(visible) => setShowFilterPopover(visible)}
      >
        <Button icon={<ClockCircleOutlined />}>
          {device === Devices.DESKTOP ? 'Filtrar' : undefined}
        </Button>
      </Popover>
    ),
    [device, actionRangePicker, showFilterPopover, t],
  )

  const actionsAllowed = useMemo(() => {
    const actionsAllowed = []

    if (
      orderStatus !== EOrderStatus.IN_PROGRESS &&
      (user?.roles?.includes(ERoles.ADMIN) || user?.roles?.includes(ERoles.ACCOUNTANT))
    ) {
      actionsAllowed.push(renderFilterButton)
    }
    actionsAllowed.push(renderButtonGroupByTable)
    actionsAllowed.push(renderButtonGroupByPendingOnly)

    if (
      orderStatus === EOrderStatus.IN_PROGRESS &&
      (user?.roles?.includes(ERoles.ADMIN) ||
        user?.roles?.includes(ERoles.ACCOUNTANT) ||
        user?.roles?.includes(ERoles.WAITRESS))
    ) {
      actionsAllowed.push(buttonCmd)
    }

    return actionsAllowed
  }, [
    buttonCmd,
    orderStatus,
    renderButtonGroupByTable,
    renderButtonGroupByPendingOnly,
    renderFilterButton,
    user,
  ])

  const orderStatusList = useMemo(() => mapOrderStatus(OrderStatus, t), [t])

  const mappedOrderStatus = useMemo(() => {
    return mapOrderStatus(OrderStatus, t)
  }, [t])

  const formattedOrders = useMemo(() => {
    return orderRepo.toForm(order)
  }, [order])

  if (
    orderStatus === EOrderStatus.PAID &&
    !(user?.roles?.includes(ERoles.ACCOUNTANT) || user?.roles?.includes(ERoles.ADMIN))
  ) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={t('msg_not_authorized')}
        extra={
          <>
            <Link to="/admin" key={'home'}>
              <Button type="primary">{t('start')}</Button>
            </Link>
          </>
        }
      />
    )
  }

  return (
    <Page title={t('sidebar_orders')}>
      {/* ORDER LIST */}
      {permissions.canRead && (
        <OrderListView
          orders={orders}
          orderStatusList={orderStatusList}
          loading={mode === PageMode.LISTING && loading}
          group={groupByTable}
          groupByPending={groupByPending}
          actions={actionsAllowed}
          messageBtn={orderStatus === EOrderStatus.IN_PROGRESS && user?.liveChatEnabled ? renderButtonChat : null}
          // EVENT HANDLERS
          onClickOrder={showOrderDetails}
          onAdd={permissions.canWrite ? showOrderForm : undefined}
          onChangeOrderOfferStatus={permissions.canWrite ? handleChangeOrderOfferStatus : undefined}
          onChangeOrderStatus={permissions.canWrite ? handleChangeOrderStatus : undefined}
        />
      )}

      {/* ORDER FORM */}
      {permissions.canWrite && (
        <Modal
          title={formTitle(mode, t('order'))}
          open={[PageMode.EDITING, PageMode.ADDING].includes(mode)}
          destroyOnClose={true}
        >
          <OrderForm
            order={formattedOrders}
            loading={[PageMode.ADDING, PageMode.EDITING].includes(mode) && loading}
            tables={tables}
            offers={offers}
            clientMarks={clientMarks}
            orderStatuses={mappedOrderStatus}
            onSubmit={handleSubmitForm}
            onDiscard={handleCloseFormModal}
            onTableChange={handleTableChange}
          />
        </Modal>
      )}

      {/* ORDER DETAILS */}
      {permissions.canRead && (
        <Modal
          title={formTitle(mode, t('order'))}
          open={!!order && [PageMode.VIEWING].includes(mode)}
          onClose={handleCloseDetailsModal}
          destroyOnClose={true}
        >
          <OrderDetails
            order={order}
            tables={tables}
            loading={[PageMode.VIEWING].includes(mode) && loading}
            onClose={handleCloseDetailsModal}
          />
        </Modal>
      )}
      {user?.liveChatEnabled && showChat ? (
        <FormSendMessage
          show={showChat}
          tables={activeTables}
          orders={orders}
          employees={employees}
          onConfirm={handleSendMessage}
          onClose={() => setShowChat(false)}
        />
      ) : null}
      <FormUserData resolver={formDataBind} userData={{} as IDeliverySpecs} />
    </Page>
  );
}

const FilterContainer = styled.div<{ device: Devices }>`
  display: flex;
  align-content: space-between;
  ${({ device }) =>
    device === Devices.MOBILE
      ? `
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `
      : null}
`
export interface IOrderOfferStatusRequestBody {
  from: TOrderOfferStatus
  to: TOrderOfferStatus
  count: number
}
export default OrderPage

export const mapOrderStatus = (
  orderStatusList: TOrderStatus[],
  t: TFunction<string>,
): { title: string; value: TOrderStatus }[] => {
  return orderStatusList.map((orderStatus) => {
    return {
      value: orderStatus,
      title: t(orderStatus) || '',
    }
  })
}
