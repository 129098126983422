import "reactn";
import { AxiosInstance } from "axios";
import { IDefaultNotification } from "components/notifications/NotificationsSidePanel";
import { IMenu } from "models/admin/menu.repository";
import { INotification } from "models/public/sessions/sessions.repository";
import { IOrder } from "models/admin/orders";
import { Socket } from "socket.io-client";

export enum Timers {
  DEFAULT = "timerRunning",
  PAYMENT_REQUEST = "paymentRequestTimerRunning",
  CALL_WAITRESS = "callWaitressTimerRunning",
  ORDER_REQUEST = "orderRequestTimerRunning",
}

declare module "reactn/default" {
  export interface State {
    orders: IOrder[];
    menus: IMenu[];
    socket: Socket | null;
    httpRequester: AxiosInstance;
    timerRunning: boolean;
    paymentRequestTimerRunning: boolean;
    callWaitressTimerRunning: boolean;
    orderRequestTimerRunning: boolean;
    notified: boolean;
    commandCenter: null | CommandCenterData;
    canOrder: {
      canOrder: boolean;
      orderMark: number | null;
    };
  }
}

export interface CommandCenterData
  extends Pick<INotification<IDefaultNotification>, "note"> {
  billType: "full" | "partial";
}
