import { Divider, List, Skeleton, Typography } from 'antd'
import { ListItemProps } from 'antd/lib/list/Item'
import React from 'react'
import styled from 'styled-components'

// HOOKS
import { Devices, useDevice } from 'hooks/device.hook'

const { Paragraph } = Typography

const ListItem = ({
  title,
  titleNode,
  description,
  extra,
  image,
  children,
  actions,
  divider = true,
  onClick,
  style,
  ...props
}: IListItemProps) => {
  // HOOKS
  const device = useDevice()

  const renderActions = (actions?: React.ReactNode[], device?: Devices) => {
    return actions && actions.length ? (
      <ActionsContainer device={device}>
        {actions.map((action, idx) => (
          <div key={idx}>
            {(divider && <Divider type="vertical" />) || null}
            {action}
          </div>
        ))}
      </ActionsContainer>
    ) : null
  }

  const renderExtra = (extra?: React.ReactNode, device?: Devices) => {
    return extra ? <SecondaryContent device={device}>{extra}</SecondaryContent> : null
  }

  const renderImage = (image?: string | React.ReactNode) => {
    if (image === undefined) {
      return null
    }

    if (image) {
      const imageComponent = typeof image === 'string' ? <img src={image} alt="" /> : image
      return <ImageContainer>{imageComponent}</ImageContainer>
    } else {
      return (
        <ImageContainer>
          <Skeleton.Image style={{ height: 56 }} />
        </ImageContainer>
      )
    }
  }

  const renderContent = (
    title?: string,
    description?: React.ReactNode | string,
    device?: Devices,
    titleNode?: React.ReactNode,
  ) => {
    const renderDescription = (description?: React.ReactNode | string) => {
      const rowNumber = device === Devices.MOBILE ? 2 : 2
      return description ? (
        typeof description === 'string' ? (
          <Description>
            <Paragraph ellipsis={{ rows: rowNumber }}>{description}</Paragraph>
          </Description>
        ) : (
          description
        )
      ) : null
    }

    return (
      <ContentContainer>
        <Title>{title || titleNode}</Title>
        {renderDescription(description)}
      </ContentContainer>
    )
  }

  return (
    <List.Item
      className={onClick ? 'clickable' : ''}
      onClick={onClick}
      {...props}
      style={{ ...style, padding: '0.5rem 1rem', width: '100%' }}
    >
      {children ? (
        children
      ) : (
        <ItemContainer device={device}>
          <MainContent device={device}>
            {renderImage(image)}
            {renderContent(title, description, device, titleNode)}
          </MainContent>
          <ExtraContent>
            {renderExtra(extra, device)}
            {renderActions(actions, device)}
          </ExtraContent>
        </ItemContainer>
      )}
    </List.Item>
  )
}

export interface IListItemProps extends ListItemProps {
  description?: React.ReactNode | string
  image?: React.ReactNode | string
  divider?: boolean
  inline?: boolean
  titleNode?: React.ReactNode
}

export default ListItem

const ItemContainer = styled.div<{ inline?: boolean; device?: Devices }>`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: 600;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`
const Description = styled.div`
  font-size: 14px;
`
const MainContent = styled.div<{ device?: Devices }>`
  width: 100%;
  max-width: 100%;
  text-align: left;
  flex: ${(props) => (props.device === Devices.MOBILE ? '100 1 100%' : '100 0 auto')};
  display: flex;
  justify-content: flex-start;
  padding: 0.5em;
`
const ExtraContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 !important;
  justify-content: flex-end;
  flex-wrap: wrap;
`
const ImageContainer = styled.div`
  width: 100px;
  height: auto;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  & > * {
    width: 100px;
    height: auto;
  }
`
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const SecondaryContent = styled.div<{ device?: Devices }>`
  flex-shrink: 1;
  display: flex;
  align-items: center;
  flex: ${(props) => (props.device === Devices.MOBILE ? '100 1 auto' : '0 0 auto')};
  justify-content: flex-end;
  border-top: ${(props) =>
    props.device === Devices.MOBILE ? '1px solid rgba(220,220,220,0.2)' : 'none'};
  padding: 0.5em;
`

const ActionsContainer = styled.div<{ device?: Devices }>`
  display: flex;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
  flex: ${(props) => (props.device === Devices.MOBILE ? '1 1 auto' : '0 0 auto')};
  border-top: ${(props) =>
    props.device === Devices.MOBILE ? '1px solid rgba(220,220,220,0.2)' : 'none'};
  justify-content: flex-end;
  align-items: center;
  padding: 0.5em;
`
