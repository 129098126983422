import { message } from "antd";
import { useAuth } from "../hooks/auth.hook";
import { useCallback } from "react";
import { useEffect, useGlobal, useMemo } from "reactn";
import { usePointer } from "../hooks/state.hook";
import { useTranslation } from "react-i18next";
import socketIOClient from "socket.io-client";

const channel = (window as any).NativeChannel;

(window as any).ping = () => true;

export function SocketStorage() {
  const { user, isLoggedIn, logout } = useAuth() || {};
  const [socket, setSocket] = useGlobal("socket");
  const { t } = useTranslation();

  const $socket = usePointer(socket);
  const $isLoggedIn = usePointer(isLoggedIn)

  const sessionId = user?.sessionId || "";
  const token = user?.token || "";

  useEffect(() => {
    if (token) {
      channel?.postMessage(`socket-on::${token}`)
      console.log("Sync Native On")
    } else {
      channel?.postMessage(`socket-off`)
      console.log("Sync Native Off")
    }
  }, [token])

  // Socket Listeners Declaration
  const listeners = useMemo(
    () => ({
      connect() {
        console.info("Socket Connected!!");
        message.destroy();
      },

      disconnect(reason: string) {
        console.info("Socket Disconnected: ", reason);
        message.destroy();
      },

      reconnect(attempt: number) {
        $socket.current?.emit("reconnected", { sessionId });
        console.info("Socket Reconnected on attempt: ", attempt);
        message.destroy();
      },

      error(error: unknown) {
        console.error("Socket Error: ", error);
      },

      connect_error(error: unknown) {
        console.error("Socket Connect Error: ", error);
      },

      connect_timeout(timeout: unknown) {
        console.error("Socket Connect Timeout: ", timeout);
      },

      reconnecting() {
        message.loading({
          content: t("connecting"),
          key: "socket",
          duration: 0,
        });
      },

      reconnect_error(error: unknown) {
        console.error("Socket Reconnect Error: ", error);
      },

      reconnect_failed() {
        console.error("Socket Reconnect Failed!");
      },
    }),
    [$socket, sessionId, t]
  );

  // Pointer to Logout
  const $logout = usePointer(logout);

  // Socket initialization
  useEffect(() => {
    if (isLoggedIn && sessionId) {
      const _socket = socketIOClient(location.origin, {
        query: "id=" + sessionId,
        autoConnect: false,
      });

      setTimeout(() => {
        setSocket(_socket)
          .then(() => console.info("Socket configured with ID " + sessionId))
          .catch(console.error);
      }, 100);

      return () => _socket.disconnect();
    } else {
      setSocket(null)
        .then(() => console.info("Socket Dropped"))
        .catch(console.error);
      return () => {
        //noop
      };
    }
  }, [isLoggedIn, setSocket, sessionId]);

  // Listen to the logout event
  useEffect(() => {
    function socketLogout({ message }: { message: string[] }) {
      if (message.includes(sessionId)) {
        $logout.current?.();
      }
    }

    if (socket) {
      socket.on("logout", socketLogout);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      window.ping = () => {
        socket.emit("ping1", { sessionId });
        console.info("sending ping", sessionId);
        return socket.connected
      };
      return () => {
        socket.off("logout", socketLogout);
      };
    }
  }, [socket, sessionId, $logout, $isLoggedIn]);

  useEffect(() => {
    if (socket) {
      Object.entries(listeners).forEach(([listener, controller]) => {
        socket.on(listener, controller);
      });
      socket.connect();

      return () => {
        socket.disconnect();
        Object.entries(listeners).forEach(([listeners, controller]) => {
          socket.off(listeners, controller);
        });
      };
    }
  }, [socket, listeners]);

  return null;
}

export function useSocketConfirmation(socket: SocketIOClient.Socket | null) {
  const $socket = usePointer(socket);

  return useCallback(
    (messageId: string) => {
      $socket.current?.emit("confirmation", { messageId });
    },
    [$socket]
  );
}

//<!-- INTERFACES -->

export interface ISMessage<T> {
  message: T;
  sessionId: string;
  messageId: string;
  table: {
    identifier: string;
    _id: string;
  };
  clientNumber?: number;
}
