// REACT
import React from 'react'

// GLOBAL IMPORTS
import { Popover as ADPopover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'

const Popover = ({ ...props }: IPopoverProps) => {
  return <ADPopover {...props} />
}

export type IPopoverProps = PopoverProps

export default Popover
