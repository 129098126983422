import { HttpRequester } from '../../base.repository'
import { IBill } from 'models/admin/table.repository'
import Axios from 'axios'

export class SessionsRepository {
  public endpoint: string

  constructor(public httpRequester: HttpRequester = Axios) {
    this.endpoint = '/sessions'
  }

  getNotifications = (): Promise<any> => {
    return this.httpRequester
      .get(this.endpoint + '/notifications')
      .then(({ data }) => {
        return data
      })
      .catch(console.error)
  }

  removeNotification = (sessionId: string, data?: string[]): Promise<ISessionData> => {
    if (!data) {
      return this.httpRequester
        .delete(this.endpoint + `/${sessionId}/notifications`)
        .then(({ data }) => data)
        .catch(console.error)
    }
    return this.httpRequester
      .patch(this.endpoint + `/${sessionId}/some-notifications`, data)
      .then(({ data }) => data)
      .catch(console.error)
  }
  // TODO Move this function to the admin repo
  getPendingOrders = (sessionId: string) => {
    return this.httpRequester
      .get(`/admin${this.endpoint}/${sessionId}/pending-orders`)
      .then(({ data }) => data)
      .catch(console.error)
  }

  // TODO Move this function to the admin repo
  generateBill = (
    sessionId: string,
    approved: boolean,
    discount = 0,
    includedTip?: number,
    discountNote = '',
  ): Promise<IBill> => {
    return this.httpRequester
      .post(`/admin${this.endpoint}/${sessionId}/bill`, {
        approved,
        discount,
        discountNote,
        includedTip,
      })
      .then(({ data }) => data)
      .catch(console.error)
  }
}

export interface ISessionData {
  orders: []
  canOrder: boolean
  sessionType: 'employee' | 'client'
  _id: string
  token: string
  user: string
  notifications: Array<INotification<unknown>>
  createdAt: string
  updatedAt: string
}

export interface INotification<T> {
  _id: string
  from: string // Ref to user
  note: {
    _id?: string
    sessionId: string // Ref to user
    message: T
    table?: {
      _id?: string
      identifier?: string
      choices?: Array<{ _id: string; identifier: string }>
    }
    billType?: string
    clientNumber?: number
  }
  eventType: string
  createdAt: string
}
