import { DeniedPage, LoginPage } from 'pages'

// INTERFACES
import { IRoute } from '../types'

const auth: IRoute[] = [
  {
    id: 'login',
    path: '/login',
    page: LoginPage,
  },
  {
    id: 'forbidden',
    path: '/forbidden',
    page: DeniedPage,
  },
]

export default auth
