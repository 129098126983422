// GLOBAL IMPORTS
import { Drawer as ADDrawer } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React from 'react'

// INTERFACES
export interface IDrawerProps extends DrawerProps {
  children?: React.ReactNode
  position?: 'top' | 'left' | 'bottom' | 'right'
}
// eslint-disable-next-line
type EventType =
  | React.KeyboardEvent<HTMLDivElement>
  | React.MouseEvent<HTMLDivElement | HTMLButtonElement>

const drawerBodyStyle: { [key: string]: string | number } = {
  padding: 0,
}

const drawerStyle: { [key: string]: string | number } = {}

const Drawer = ({
  children,
  visible,
  position = 'right',
  onClose,
  width = '80%',
  ...props
}: IDrawerProps): JSX.Element => {
  return (
    <ADDrawer
      placement={position}
      onClose={onClose}
      visible={visible}
      bodyStyle={drawerBodyStyle}
      width={width}
      closable={!!onClose}
      style={drawerStyle}
      {...props}
    >
      {children}
    </ADDrawer>
  )
}

export default Drawer
