// GLOBAL IMPORTS
import { Modal as ADModal } from 'antd'
import { ModalProps } from 'antd/lib/modal/Modal'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

// LOCAL IMPORTS
import { Devices, useDevice } from '../../hooks/device.hook'
import Drawer, { IDrawerProps } from './Drawer'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex: 1 !important;
  width: 100%;
  overflow: hidden;
`

const Modal = ({
  children: Children,
  open,
  title,
  onClose,
  position,
  width,
  height = '100%',
  closeIcon,
  ...props
}: IModalProps) => {
  const device = useDevice()

  // <!-- Defaults initialization -->
  if (!position) {
    position = device === Devices.MOBILE ? 'bottom' : 'right'
  }
  if (!width) {
    width = device === Devices.MOBILE ? '100%' : '500px'
  }

  // TODO: ADD modal when position is center
  return position === 'center' ? (
    <ADModal
      title={title}
      visible={open}
      footer={null}
      bodyStyle={{ height, padding: 0 }}
      centered
      closeIcon={closeIcon}
      closable={!!onClose}
      onCancel={onClose}
    >
      <ModalContainer {...props}>{Children}</ModalContainer>
    </ADModal>
  ) : (
    <Drawer
      position={position}
      title={title}
      visible={open}
      width={width}
      onClose={onClose}
      height={height}
      {...(props as IDrawerProps)}
    >
      <ModalContainer>
        {/* CONTENT */}
        {Children}
      </ModalContainer>
    </Drawer>
  )
}

interface IModalProps extends ModalProps {
  children: React.ReactNode
  open?: boolean
  onClose?: () => void
  position?: 'top' | 'left' | 'bottom' | 'right' | 'center' | undefined
  width?: string | number
  height?: string | number
  title?: ReactNode
  inset?: boolean
  closeIcon?: ReactNode
}

export default React.memo(Modal)
