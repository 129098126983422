import { IHttpRequester, RestApiCrud } from '../services/http'

class BaseRepository<
  I = any,
  LI = I,
  IF = I,
  R extends IHttpRequester = IHttpRequester
> extends RestApiCrud<I, LI, IF, R> {
  constructor(httpRequester: R, endpoint = '', settings = {}) {
    super(httpRequester, endpoint, settings)
  }

  toForm(_item: I | null, _defaults?: Record<string, any>): IF {
    throw new Error('Unsupported operation')
  }
}

export type FunctionalRepository<T /*extends BaseRepository*/> = (
  httpRequester: IHttpRequester,
) => T

export type HttpRequester = IHttpRequester

export default BaseRepository
