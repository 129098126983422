/** HOOK FOR MANAGING ADMIN NAMESPACE AND ITS MODULES */

// REACT
import { createContext, useContext } from "react";

// LOCAL
import {
  AdminNamespace,
  ADMIN_FEATURES as features,
  INamespace,
} from "modules";

export const AdminNamespaceContext = createContext<INamespace | null>(null);

export const useAdminNamespace = (): INamespace | null => {
  return useContext<INamespace | null>(AdminNamespaceContext);
};

export const useAdmin = (): INamespace => {
  AdminNamespace.turnModules(features);

  return AdminNamespace;
};
