// REACT
import React from 'react'

// GLOBAL IMPORTS
import { Tag as ADTag } from 'antd'
import { TagProps } from 'antd/lib/tag'

const Tag = ({ ...props }: ITagProps) => {
  return <ADTag {...props} />
}

export type ITagProps = TagProps

export default Tag
