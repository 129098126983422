// REACT
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
// GLOBAL IMPORTS
import throttle from 'lodash.throttle'

// LOCAL IMPORTS
import { useHtmlElement } from './html-element.hook'

export enum EVerticalScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export const useScroll = () => {
  // HOOKS
  const { getTop, getBottom } = useHtmlElement()

  const lastScrollPosition = useRef<number>(0)
  const currentScrollPosition = useRef<number>(0)
  const currentInterval = useRef<number | null>(null)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const attachedCallbacks = useRef<CallBackMap>(new WeakMap())

  const scrollHandler = useCallback(() => {
    const scrollPosition = containerRef.current?.scrollTop || 0
    // Refresh
    lastScrollPosition.current = currentScrollPosition.current
    currentScrollPosition.current = scrollPosition
  }, [containerRef])

  const scrollDirection = useMemo(() => {
    return currentScrollPosition.current > lastScrollPosition.current
      ? EVerticalScrollDirection.UP
      : EVerticalScrollDirection.DOWN
  }, [currentScrollPosition, lastScrollPosition])

  const isElementActive = useCallback(
    (element: HTMLElement, offset = 0): boolean => {
      if (!element) return false
      const elementTop = getTop(element) || 0
      const elementBottom = getBottom(element) || 0
      const containerTop = containerRef.current?.scrollTop
      const isActive =
        elementTop - offset <= (containerTop || 0) && elementBottom >= (containerTop || 0)

      return isActive
    },
    [getBottom, getTop],
  )

  const attachScroll = useCallback(
    (callback?: ScrollHandler, throttleValue = 50, store = true) => {
      if (!containerRef.current) {
        throw new Error('Undefined container')
      }

      if (callback) {
        const throttledCallback = throttle(callback, throttleValue)
        if (store) {
          attachedCallbacks.current.set(callback, throttledCallback)
        }
        containerRef.current.addEventListener('scroll', throttledCallback)
      } else {
        Object.values(attachedCallbacks).forEach((attachedCallback) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          containerRef.current!.addEventListener('scroll', attachedCallback)
        })
      }
    },
    [containerRef],
  )

  const detachScroll = useCallback(
    (callback?: ScrollHandler) => {
      if (!containerRef.current) {
        throw new Error('Undefined container')
      }

      if (callback) {
        const storedCallback = attachedCallbacks.current.get(callback)
        if (storedCallback) {
          attachedCallbacks.current.delete(callback)
          containerRef.current.removeEventListener('scroll', storedCallback)
        }
      } else {
        Object.values(attachedCallbacks.current).forEach((attachedCallback) => {
          containerRef.current?.removeEventListener('scroll', attachedCallback)
        })
        attachedCallbacks.current = new WeakMap()
      }
    },
    [containerRef],
  )

  const setContainerRef = useCallback(
    (node) => {
      if (node) {
        containerRef.current = node
        // Mounting
        attachScroll(scrollHandler, 100)
      } else if (containerRef.current) {
        detachScroll(scrollHandler)
      }
    },
    [attachScroll, detachScroll, scrollHandler, containerRef],
  )

  /*const checkIfPositionReached = useCallback(
    (callback: () => unknown) => {
      return setTimeout(() => {
        const scrollPosition = containerRef.current?.scrollTop
        if (currentInterval.current) {
          if (scrollPosition === currentScrollPosition.current) {
            callback()
          } else {
            checkIfPositionReached(callback)
          }
        }
      }, Math.abs(currentScrollPosition.current - position))
    },
    [containerRef, currentInterval],
  )*/

  const scrollTo = useCallback(
    (position: number, _callback?: () => unknown) => {
      if (containerRef.current) {
        containerRef.current.scrollTo(0, position)
        /*if (callback) {
          currentInterval.current = checkIfPositionReached(callback)
        }*/
      }
    },
    [containerRef /*checkIfPositionReached*/],
  )

  useLayoutEffect(() => {
    return () => {
      if (currentInterval.current) {
        currentInterval.current = null
      }
    }
  })

  return {
    attachScroll,
    detachScroll,
    scrollDirection,
    isElementActive,
    scrollTo,
    containerRef,
    setContainerRef,
  }
}

export type ScrollHandler = (e?: Event) => unknown
export type CallBackMap = WeakMap<ScrollHandler, ScrollHandler>
