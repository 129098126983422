import OrderRepository from './order.repository'

import { EOrderOfferStatus, EOrderStatus, TOrderStatus } from './types'

export type {
  IOrder,
  IOrderForm,
  IOrderListItem,
  TOrderStatus,
  IOrderOfferItem,
  INote,
  IBill,
  IBillItems,
  TOrderOfferStatus,
  IOrderStats,
  IOrderStat,
} from './types'

export { EOrderOfferStatus, EOrderStatus }
export const OrderStatus: TOrderStatus[] = [
  EOrderStatus.IN_PROGRESS,
  EOrderStatus.REJECTED,
  EOrderStatus.PAID,
]

export const OrderOffersStatus: EOrderOfferStatus[] = [
  EOrderOfferStatus.DELIVERED,
  EOrderOfferStatus.PENDING,
  EOrderOfferStatus.REJECTED,
]
export default OrderRepository
