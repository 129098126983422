// REACT
import React, { useCallback, useMemo, useState } from "react";

// GLOBAL IMPORTS
import { Layout } from "antd";
import AppSider, { INavItem } from "components/layout/AppSidebar";
// LOCAL IMPORTS
import { ActionBar } from "components/view-layout";
import { Buttons } from "components/common";
import { IPageProps, Page } from "components/common/page";
import { showError, showSuccess } from "components/common/Notification";
import { Timers } from "global.d";
import CustomerRepository from "models/public/customer";

// CUSTOM HOOKS
import { CSSProperties } from "styled-components";
import { Devices, useDevice } from "hooks/device.hook";
import { NotificationButton } from "components/notifications/NotificationsLayoutButton";
import { PageMode } from "pages/admin";
import { useAuth } from "hooks/auth.hook";
import { useHash } from "hooks/route.hook";
import { useTimer } from "hooks/timer.hook";
import { useTranslation } from "react-i18next";
import { ViewNotifications } from "components/notifications/NotificationsSidePanel";
import PushButton from "../../components/common/PushButton";
import { PUBLIC_FEATURES } from "modules";

const customerRepo = new CustomerRepository();

const MOD_ORDERS = PUBLIC_FEATURES.includes("orders");

const { Content } = Layout;
const CALL_WAITRESS_DELAY = 30000;

const { ButtonCallWaitress, /*ButtonRequestPayment,*/ ButtonMenu } = Buttons;
const SiderActionBar = () => {
  // HOOKS
  const callWaitressTimer = useTimer(Timers.CALL_WAITRESS, true);
  //LANGUAGE TRANSLATION
  const { t, i18n } = useTranslation();
  const { user } = useAuth() || {};

  const handleCallWaitress = useCallback(async () => {
    try {
      await customerRepo.sendCallWaitressRequest();
      showSuccess({
        message: t("callingWaiter"),
      });
      callWaitressTimer.start(CALL_WAITRESS_DELAY);
    } catch (err) {
      showError({
        message: t("errCallingWaiter"),
        description: t("errCallingWaiterDesc"),
      });
    }
  }, [callWaitressTimer, t]);

  const changeLanguage = useCallback(() => {
    if (i18n.language === "es") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("es");
    }
  }, [i18n]);

  return (
    <ActionBar style={{ justifyContent: "space-between" }}>
      <div></div>
      {!(user?.isInfoTable || user?.isDeliveryTable) ? (
        <ButtonCallWaitress
          disabled={callWaitressTimer.running}
          onClick={!callWaitressTimer.running ? handleCallWaitress : undefined}
          help={callWaitressTimer.running ? t("callWaiterDelayMsg") : undefined}
        >
          {callWaitressTimer.running ? t("wait") : undefined}
        </ButtonCallWaitress>
      ) : null}
      <div key={"languageSelector"}>
        <PushButton onClick={() => changeLanguage()} style={{ padding: "5px" }}>
          {i18n.language === "es" ? "EN" : "ES"}
        </PushButton>
      </div>
    </ActionBar>
  );
};

const PublicPage = ({
  sidebarTitle,
  navItems,
  onClickNavItem,
  actions,
  ...props
}: IPublicPageProps) => {
  // STATE
  const { hash, setHash } /*--------------*/ = useHash();
  const [menuVisibility, setMenuVisibility] = useState(false);
  //LANGUAGE TRANSLATION
  const { t } = useTranslation();

  // MEMOS
  const mode = useMemo((): PageMode => {
    return (hash || PageMode.LISTING) as PageMode;
  }, [hash]);

  // CALLBACKS
  const setMode = useCallback(
    (mode: PageMode) => {
      setHash(mode);
    },
    [setHash]
  );

  const showNotifications = useCallback(() => {
    setMode(PageMode.NOTIFICATIONS);
  }, [setMode]);

  const hideNotifications = useCallback(() => {
    setMode(PageMode.LISTING);
  }, [setMode]);

  // HOOKS
  const device = useDevice();

  const handleCloseMenu = useCallback(() => {
    setMenuVisibility(false);
  }, []);

  const handleClickItem = useCallback(
    (itemId: string) => {
      if (onClickNavItem) {
        onClickNavItem(itemId);
      }
      handleCloseMenu();
    },
    [onClickNavItem, handleCloseMenu]
  );

  const pageActions = useMemo(
    () => [
      [Devices.MOBILE, Devices.TABLET].includes(device) ? (
        <ButtonMenu
          key="button-menu"
          iconOnly
          type="text"
          className="text-primary margin-left-0"
          onClick={() => setMenuVisibility(true)}
        />
      ) : null,
    ],
    [device]
  );

  return (
    <Layout style={layoutStyle}>
      <ViewNotifications
        open={mode === PageMode.NOTIFICATIONS}
        onClose={hideNotifications}
      />
      <AppSider
        title={sidebarTitle || t("menu")}
        navItems={navItems || []}
        footer={<SiderActionBar />}
        visible={menuVisibility}
        onClickItem={handleClickItem}
        onClose={handleCloseMenu}
      />
      <Layout style={layoutStyle}>
        <Content
          style={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "stretch",
          }}
        >
          <Page
            actions={[...(actions || []), ...(pageActions || [])]}
            {...props}
          />
        </Content>
      </Layout>
      {MOD_ORDERS && <NotificationButton onClick={showNotifications} />}
    </Layout>
  );
};

const layoutStyle: CSSProperties = {
  backgroundColor: "#fff",
  flex: "1 !important",
  justifyContent: "stretch",
  alignItems: "stretch",
};

export interface IPublicPageProps extends IPageProps {
  sidebarTitle?: string;
  navItems?: INavItem[];
  onClickNavItem?: (id: string) => unknown;
}

export default React.memo(PublicPage);
