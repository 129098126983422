import { LicensePage, LoginTablePage, MenuPage, OrderPage, ThankyouPage } from '../../pages/public'

// INTERFACES
import { IRoute } from '../types'

const routes: IRoute[] = [
  {
    id: 'tableMenus',
    path: '/la-carte',
    page: MenuPage,
  },
  {
    id: 'tableOrders',
    path: '/orders',
    page: OrderPage,
  },
  {
    id: 'license',
    path: '/license',
    page: LicensePage,
  },
  {
    id: 'loginTable',
    path: '/table/:tableId?',
    page: LoginTablePage,
  },
  {
    id: 'thankyouPage',
    path: '/thankyou',
    page: ThankyouPage,
  },
]

export default routes
