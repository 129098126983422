// REACT
import React from 'react'

// GLOBAL IMPORTS
import './i18n'
import 'react-perfect-scrollbar/dist/css/styles.css'
import ReactDOM from 'react-dom'

// LOCAL IMPORTS
import './styles/App.less'
import * as serviceWorker from './services/ServiceWorker'

import App from './App'
// import i18n from './i18n'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
