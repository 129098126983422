// GLOBAL IMPORTS
import React from 'react'

// LOCAL IMPORTS
import adminRoutes from './admin.routes'
import Routes, { IRoutesProps } from '../Routes'

// HOOKS
import { AdminNamespaceContext, useAdmin } from 'hooks/admin.hook'

const AdminRoutes = ({ layout, notFoundUrl, forbiddenUrl }: IAdminRoutesProps) => {
  const adminContext = useAdmin()
  return (
    <AdminNamespaceContext.Provider value={adminContext}>
      <Routes
        layout={layout}
        forbiddenUrl={forbiddenUrl}
        notFoundUrl={notFoundUrl}
        routes={adminRoutes.filter(
          ({ id }) => adminContext.getModules({ id, enabled: true }).length,
        )}
      />
    </AdminNamespaceContext.Provider>
  )
}

export type IAdminRoutesProps = Pick<IRoutesProps, 'notFoundUrl' | 'forbiddenUrl' | 'layout'>

export default AdminRoutes
