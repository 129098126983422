import { IOfferListItem } from "../offer.repository";
import { ITable } from "../table.repository";
export interface IOrder {
  _id: string;
  shortId?: string;
  createdAt: string;
  totalPrice: IBill;
  subTotalPrice: IBill;
  closedAt?: Date;
  createdBy?: string;
  discount?: number;
  table: ITable;
  orderMark?: number;
  offers: IOrderOfferItem[];
  notes?: INote[];
  status: TOrderStatus;
  employee?: IOrderEmployee;
  discountNote?: string;
  includedTip?: number;
  billApproved?: boolean;
  omittedOffers?: string[];
  deliverySpecs?: IDeliverySpecs;
}

export interface IOrderForm
  extends Omit<
    IOrder,
    | "table"
    | "_id"
    | "createdAt"
    | "totalPrice"
    | "subTotalPrice"
    | "createdBy"
    | "employee"
  > {
  _id?: string;
  createdAt?: string;
  totalPrice?: IBill;
  subTotalPrice?: IBill;
  createdBy?: string;
  table: string;
  employee?: IOrderEmployee;
}

export interface IDeliverySpecs {
  name: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address: string;
  deliveryTime: Date;
  shippingLocation: { name: string; _id: string };
}

interface IOrderEmployee {
  _id: string;
  email: string;
}

export interface IOrderListItem {
  _id: string;
  shortId?: string;
  createdAt: string;
  discount?: number;
  totalPrice: IBill;
  subTotalPrice: IBill;
  closedAt?: Date;
  orderMark?: number;
  table: ITable;
  offers: IOrderOfferItem[];
  employee?: IOrderEmployee;
  notes?: INote[];
  status: TOrderStatus;
  canUpdateOrder?: boolean;
  discountNote?: string;
  billApproved?: boolean;
  includedTip?: number;
}

export interface IBillListItem {
  _id: string;
  bill: IBillModel;
  billType: string;
  createdAt: string;
  generatedBy: IOrderEmployee;
  statusChangedBy: IOrderEmployee;
  orderMark?: number;
  orders: IOrderListItem[];
  paidAt?: string;
  paymentMethod: string;
  status: string;
  table: ITable;
  updateAt: string;
  discountNote: string;
  paymentIntent?: string;
}

export interface IOrderOfferItem {
  offer: IOfferListItem;
  status: TOrderOfferStatus;
  menu: string;
  note?: string;
  orderStatus?: EOrderStatus;

  // COUNTERS
  amount: number;
  pending?: number;
  rejected?: number;
  delivered?: number;
  [index: string]:
    | IOfferListItem
    | TOrderOfferStatus
    | string
    | number
    | undefined;
}

export interface INote {
  offer: string;
  note: string;
}

export interface IBill {
  totalPrice?: { [key: string]: number };
  discount?: { [key: string]: number };
  includeTip?: { [key: string]: number };
  subTotalPrice?: { [key: string]: number };
  deliveryPrice?: { [key: string]: number };
  totalItems: number;
  items?: Record<string, IBillItems>;
}
export interface IBillModel {
  id: string;
  totalPrice?: { [key: string]: number };
  totalItems: number;
  items: IBillModelItem[];
  companyName?: string;
  logo?: string;
  includedTip?: number;
  companyPhone?: string;
  discount?: number;
  discountNote?: string;
  table?: string;
  subTotalPrice: { [key: string]: number };
}
export interface IBillModelItem {
  area: string;
  currency: { [key: string]: number };
  items: number;
  menu: string;
  offerPrice: number;
  totalPrice: number;
}

export interface IBillItems {
  items?: number;
  totalPrice?: number;
  offerPrice?: number;
  currency?: Record<string, unknown>;
}

export enum EOrderStatus {
  IN_PROGRESS = "inProgress",
  REJECTED = "rejected",
  PAID = "paid",
}

export type TOrderStatus =
  | EOrderStatus.IN_PROGRESS
  | EOrderStatus.REJECTED
  | EOrderStatus.PAID;

export enum EOrderOfferStatus {
  PENDING = "pending",
  REJECTED = "rejected",
  DELIVERED = "delivered",
}
export type TOrderOfferStatus =
  | EOrderOfferStatus.PENDING
  | EOrderOfferStatus.REJECTED
  | EOrderOfferStatus.DELIVERED;

export interface IOrderStat {
  offer: string;
  amount: number;
}
export interface IOrderStats {
  [key: string]: IOrderStat[];
}
