import styled from 'styled-components'

const Container = styled.div<IContainerProps>`
  display/*---------*/: flex;
  overflow-x/*------*/: hidden;
  width/*-----------*/: 100%;
  flex-wrap/*-------*/: ${({ wrap }) => wrap || 'wrap'};
  -webkit-box-flex/**/: 1;
  flex-direction/*--*/: ${({ flexDirection }) => flexDirection || 'row'};
  overflow-y/*------*/: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
  justify-content/*-*/: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items/*-----*/: ${({ alignItems }) => alignItems || 'flex-start'};
  flex/*------------*/: ${({ fixed }) => (fixed ? '0 1 1 !important' : '1 !important')};
  position/*--------*/: ${({ position }) => position || 'relative'};
  ${({ scrollable }) =>
    scrollable
      ? `
    & > * {
      max-width: 100%;
    }
  `
      : null}
`

Container.displayName = 'Container'

export type FlexJustify =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'stretch'
  | 'space-even'

export interface IContainerProps {
  width?: /*--------*/ string
  height?: /*-------*/ string
  maxHeight?: /*----*/ string
  scrollable?: /*---*/ boolean
  fixed?: /*--------*/ boolean
  flexDirection?: /**/ 'row' | 'column'
  position?: /*-----*/ 'relative' | 'absolute' | 'fixed'
  justifyContent?: /**/ FlexJustify
  alignItems?: /*----*/ FlexJustify // TODO: check if this could be a different type like FlexAlign
  padding?: /*-------*/ 'string'
  wrap?: /*----------*/ 'wrap' | 'nowrap'
}

export default Container
