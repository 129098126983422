import AdminNamespace from "./admin";
import PublicNamespace from "./public";

import { IModule } from "./module";
import { INamespace } from "./namespace";

export { AdminNamespace, PublicNamespace };

export type { IModule, INamespace };

const {
  REACT_APP_PUBLIC_FEATURES: publicFeatures,
  REACT_APP_ADMIN_FEATURES: adminFeatures,
} = process.env;

const defaultAdminFeatures = [
  "dashboard",
  "menus",
  "offers",
  "products",
  "categories",
  "tags",
  "orders",
  "areas",
  "tables",
  "employees",
  "settings",
  "units_measure",
  "license",
  "conversions",
  "reports",
  "inventories",
  "offers_existence",
];

const defaultPublicFeatures = ["menus", "orders"];

export const ADMIN_FEATURES = adminFeatures
  ? JSON.parse(adminFeatures)
  : defaultAdminFeatures;

export const PUBLIC_FEATURES = publicFeatures
  ? JSON.parse(publicFeatures)
  : defaultPublicFeatures;
