import { IModule, IModuleFilter } from './module'

export type { IModuleFilter, IModule }

export interface INamespace {
  name: string
  modules: IModule[]
  pushModule: (module: IModule) => boolean
  turnModules: (enabledModules: string[]) => IModule[]
  getModules: (filters?: IModuleFilter) => IModule[]
}

class Namespace implements INamespace {
  modules: IModule[]
  name: string

  constructor(name: string, modules: IModule[]) {
    this.name = name
    this.modules = modules || []
  }

  pushModule(module: IModule): boolean {
    try {
      this.modules.push(module)
      return true
    } catch (err) {
      return false
    }
  }

  getModules(filters?: IModuleFilter): IModule[] {
    if (filters) {
      return this.modules.filter((m) => {
        return m.match(filters)
      })
    } else {
      return this.modules
    }
  }

  turnModules(enabledModules: string[]): IModule[] {
    this.modules.forEach((m) => {
      if (m && enabledModules.includes(m.id)) {
        m.enabled = true
      }
    })

    return this.getModules()
  }
}

export default Namespace
