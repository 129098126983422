// GLOBAL IMPORTS
import { hot } from "react-hot-loader";
import React, { useLayoutEffect } from "react";

// LOCAL IMPORTS
import "moment/locale/es";
import { AuthContext, useAuthStorage } from "./hooks/auth.hook";
import { ConfigProvider } from "antd";
import AppRouter, { Storage } from "./AppRouter";
import Axios from "axios";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";
import moment from "moment";

import { useTranslation } from "react-i18next";
const App = () => {
  const auth = useAuthStorage();
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  useLayoutEffect(() => {
    Axios.defaults.baseURL = `${location.origin}/backend`;
  }, []);

  const { getHeader, refresh } = auth;

  useLayoutEffect(() => {
    const interceptorReq = Axios.interceptors.request.use(
      async (config: any) => {
        const { Authorization } = await getHeader();
        config.headers.Authorization = Authorization;
        config.headers["content-language"] = i18n.languages[0];
        return config;
      }
    );

    const interceptorRes = Axios.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error) => {
        if ([412, 401].includes(error.response?.status)) {
          refresh().catch(console.error);
        }
        //TODO retry request
        return Promise.reject(error);
      }
    );

    return () => {
      Axios.interceptors.request.eject(interceptorReq);
      Axios.interceptors.response.eject(interceptorRes);
    };
  }, [getHeader, refresh, i18n]);

  return (
    <AuthContext.Provider value={auth}>
      <ConfigProvider locale={i18n.language === "es" ? esES : enUS}>
        <>
          <Storage />
          <AppRouter />
        </>
      </ConfigProvider>
    </AuthContext.Provider>
  );
};

export default hot(module)(App);
