import React, { setGlobal } from 'reactn'

import { MenuStorage } from './menu.storage'
import { NotificationStorage } from './notification.storage'
import { SocketStorage } from './socket.storage'

setGlobal({
  menus: [],
  orders: [],
  offers: [],
  socket: null,
  canOrder: false,
  timerRunning: false,
  paymentRequestTimerRunning: false,
  callWaitressTimerRunning: false,
  orderRequestTimerRunning: false,
  notificated: true,
  commandCenter: null,
  clientNumber: null,
}).then()

export function Storage() {
  return (
    <>
      <MenuStorage />
      <SocketStorage />
      <NotificationStorage />
    </>
  )
}

export enum ISocketEventTypes {
  NEW_ORDER = 'newOrder',
  ORDER_STATS = 'orderStats',
}
