export type IModuleFilter = {
  [key in keyof IModule]?: unknown
}

export interface IModuleJSON {
  id: string
  name: string
  enabled: boolean
}
export interface IModule {
  readonly name: string
  readonly id: string
  enabled: boolean
  match: (filters: IModuleFilter) => boolean
  toString: () => string
  toJSON: () => IModuleJSON
}

class Module implements IModule {
  readonly id: string
  readonly name: string
  enabled: boolean

  constructor(id: string, name: string, enabled = false) {
    this.id = id
    this.name = name
    this.enabled = enabled
  }

  match(filters: IModuleFilter): boolean {
    if (filters['id'] && filters['id'] !== this.id) {
      return false
    }

    if (filters['name'] && filters['name'] !== this.name) {
      return false
    }

    if (filters['enabled'] && filters['enabled'] !== this.enabled) {
      return false
    }

    return true
  }

  toString(): string {
    return `${this.name} (${this.id}): ${this.enabled ? 'enabled' : 'disabled'}`
  }

  toJSON(): IModuleJSON {
    return {
      id: this.id,
      name: this.name,
      enabled: this.enabled,
    }
  }
}

export default Module
