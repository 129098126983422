import { Timers } from 'global.d'
import { useCallback, useEffect, useRef } from 'react'
import { useGlobal } from 'reactn'

export const useTimer = (timerRef: Timers = Timers.DEFAULT, global = false) => {
  const [running, setRunning] = useGlobal(timerRef)
  const timer = useRef<number>(0)

  const start = useCallback(
    (timeoutInMS: number, callback?: () => unknown) => {
      if (global) {
        setRunning(true)
      }
      setTimeout(() => {
        if (global) {
          setRunning(false)
        }
        if (callback) {
          callback()
        }
      }, timeoutInMS)
    },
    [setRunning, global],
  )

  const stop = useCallback(() => {
    if (timer.current !== 0) {
      clearTimeout(timer.current)
      if (global) {
        setRunning(false)
      }
    }
  }, [timer, setRunning, global])

  useEffect(() => stop, [stop])

  return {
    start,
    stop,
    running,
  }
}
