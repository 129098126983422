import { Col, Image, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IBill } from "models/admin/table.repository";
import { useCallback } from "reactn";
import { useTranslation } from "react-i18next";
import { ViewLayout } from "components/view-layout";
import moment from "moment";
import React, { ReactNode } from "react";
import styled from "styled-components";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";

//<!-- Component Interfaces -->
interface PaymentBillProps {
  bill: IBill | null;
  discount: number | null;
  includedTip?: number;
}

interface iTableData {
  key: number;
  name: {
    text: string;
    price: string;
  };
  price: ReactNode;
}

//<!-- Component Templating -->
export function PaymentBill({ ...props }: PaymentBillProps) {
  const { bill, discount, includedTip } = props;
  //LANGUAGE TRANSLATION
  const { t } = useTranslation();
  const columns: ColumnsType<iTableData> = [
    {
      title: t("ordersTxt"),
      dataIndex: "name",
      className: "billTableHead",
      key: "name",
      render: ({ text, price }: { text: string; price: string }) => (
        <span>
          {text}
          <div style={{ display: "inline-block" }}>{price}</div>
        </span>
      ),
    },
    {
      title: t("priceTxt"),
      dataIndex: "price",
      className: "billTableHead",
      key: "price",
      align: "right",
    },
  ];

  const computeTotal = useCallback(
    (amount: number) => {
      let total: number = amount;

      if (discount) {
        total -= (amount * discount) / 100;
      }

      if (includedTip) {
        total += (amount * includedTip) / 100;
      }

      return total.toFixed(2);
    },
    [discount, includedTip]
  );

  if (!bill) {
    return <div>{t("loadingTxt")}</div>;
  }

  const data: iTableData[] = Object.entries(bill.items || {}).map(
    ([name, bill], key) => {
      return {
        key,
        name: {
          text: `${bill.items} ${name} `,
          price: `(${bill.offerPrice?.toFixed(2)} ${bill.currency?.shortName})`,
        },
        price: (
          <span style={{ marginLeft: -32 }}>
            {bill.totalPrice?.toFixed(2)}{" "}
            <span>{bill.currency?.shortName}</span>
          </span>
        ),
      };
    }
  );

  return (
    <BillContainer>
      <ViewLayout
        actionBar={
          <div style={{ width: "100%" }}>
            <Row justify="end" wrap={true} className="bill-stats print-styles">
              <Col span={24} sm={16}>
                {((bill.discount ||
                  discount ||
                  includedTip ||
                  bill.includedTip) && (
                  // <PriceBox>
                  <Row>
                    <Col span={12} style={{ textAlign: "end" }}>
                      {t("subtotalTxt")}:
                    </Col>
                    <Col span={12} style={{ textAlign: "end" }}>
                      {Object.entries(bill.subTotalPrice || {}).map(
                        ([currency, amount], index) => {
                          return (
                            <div key={index}>
                              {amount.toFixed(2)} {currency}
                            </div>
                          );
                        }
                      )}
                    </Col>
                  </Row>
                  // </PriceBox>
                )) ||
                  null}
                <PrintBox style={discount === null ? { display: "block" } : {}}>
                  {((bill.includedTip || includedTip) && includedTip !== 0 && (
                    <Row>
                      <Col span={12} style={{ textAlign: "end" }}>
                        {t("serviceTxt")}:
                      </Col>
                      <Col span={12} style={{ textAlign: "end" }}>
                        {bill.includedTip || includedTip}%
                      </Col>
                    </Row>
                  )) ||
                    null}
                  {(bill.discount && discount !== 0 && (
                    <Row>
                      <Col span={12} style={{ textAlign: "end" }}>
                        {t("discountTxt")}:
                      </Col>
                      <Col span={12} style={{ textAlign: "end" }}>
                        {bill.discount || discount}%
                      </Col>
                    </Row>
                  )) ||
                    null}
                </PrintBox>

                <Row>
                  <Col span={12} style={{ textAlign: "end" }}>
                    {t("totalTxt")}:
                  </Col>
                  <Col span={12} style={{ textAlign: "end" }}>
                    {Object.entries(bill.totalPrice || {}).map(
                      ([currency, amount], index) => {
                        return (
                          <div key={index}>
                            {computeTotal(amount)} {currency}
                          </div>
                        );
                      }
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <BillContactBox>
              <div>{t("gratitudeTxt")}</div>
              {(bill.companyPhone && <div>Tel: {bill.companyPhone}</div>) ||
                null}
            </BillContactBox>
          </div>
        }
      >
        <div style={{ height: "100%", width: "100%", maxHeight: "100%" }}>
          <div>
            <BillHeader>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BillHeaderImage>
                  {bill.logo && (
                    <Image width={64} src={`/backend` + bill.logo} />
                  )}
                </BillHeaderImage>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title className="print-styles" level={3}>
                    {bill.companyName}
                  </Title>
                  <div style={{ width: "100%" }} className="print-styles">
                    {moment(new Date()).format("YYYY-MM-DD HH:mm")}
                  </div>
                  {bill.table && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                      }}
                    >
                      <Title
                        className="print-styles"
                        level={5}
                        style={{ marginRight: "6px" }}
                      >
                        {t("mesaTxt")}:
                      </Title>
                      <Text className="print-styles">{bill.table}</Text>
                    </div>
                  )}
                </div>
              </div>
            </BillHeader>
            <Table
              className="bill-container"
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
        </div>
      </ViewLayout>
    </BillContainer>
  );
}

const BillHeader = styled.div`
  background-color: #fafafa;
  padding: 16px 16px 0;
  text-align: center;
  width: 100%;
  @media print {
    background-color: #ffffff;
  }
`;

const BillHeaderImage = styled.div`
  @media print {
    display: none;
  }
`;
const BillContactBox = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 10px;
  display: none;
  margin-top: 10px;
  @media print {
    display: block;
    font-size: 11px !important;
    font-weight: 900 !important;
    /* font-family: Tahoma, Arial, 'Segoe UI', Calibri, Geneva, Verdana !important; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }
`;

const BillContainer = styled.div`
  margin: -24px;
  max-height: 500px;
  display: flex;
  @media print {
    margin: 0;
    max-height: none;
  }
`;

const PrintBox = styled.div`
  display: none;
  @media print {
    display: block;
  }
`;
