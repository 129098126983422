import { Modal as ADModal, Card, Col, Empty, Row, Spin } from "antd";
import { BillPaymentCtrl } from "./BillPaymentCtrl";
import { ClearSessionsCtrl } from "./ClearSessionsCtrl";
import { ERoles } from "../../types";
import { IBill } from "models/admin/table.repository";
import { InputSelect } from "../common/Inputs";
import {
  IOrderOfferStatusRequestBody,
  mapOrderStatus,
} from "pages/admin/OrderPage";
import { IOrderOfferStatusResponse, OrderList } from "components/orders";
import { OrderStatusCtrl } from "./OrderStatusCtrl";
import { SessionsRepository as SessionsAdminRepository } from "models/admin/sessions.repository";
import { SessionsRepository } from "models/public/sessions/sessions.repository";
import {
  showError,
  showInfo,
  showSuccess,
} from "components/common/Notification";
import { useAuth } from "../../hooks/auth.hook";
import { useCallback, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import CustomerRepository from "models/public/customer";
import i18n from "i18n";
import OrderRepository, {
  IOrder,
  IOrderListItem,
  IOrderOfferItem,
  OrderStatus,
  TOrderOfferStatus,
} from "models/admin/orders";
import React, { useGlobal, useLayoutEffect, useMemo, useState } from "reactn";
import TableRepository from "models/admin/table.repository";

const tableRepo = new TableRepository();
const orderRepo = new OrderRepository();
const sessionsRepo = new SessionsRepository();
const sessionsAdminRepo = new SessionsAdminRepository();
const customerRepo = new CustomerRepository();

export const CommandCenter = React.memo(() => {
  const [cmdData, setCmd] = useGlobal("commandCenter");
  //LANGUAGE TRANSLATION
  const { t } = useTranslation();

  const closeCmd = useCallback(() => {
    setCmd(null).then();
    setOrders([]);
    setSelectedClient(null);
  }, [setCmd]);

  const { user } = useAuth() || {};

  const [rawOrders, setOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const [bill, setBill] = useState<IBill | null>(null);
  const [billVisibility, setBillVisibility] = useState(false);
  const [loadingBill, setLoadingBill] = useState(false);
  const [tableId, setTableId] = useState("");
  const [clientMarks, setClientMarks] = useState<number[]>([]);
  const [selectedClient, setSelectedClient] = useState<number | null>(null);

  const billType = cmdData?.billType || "full";
  const sessionId = cmdData?.note.sessionId || "";
  const tableIdentifier = cmdData?.note.table?.identifier || "";
  const _tableId = cmdData?.note.table?._id || "";
  const componentToPrintRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const orders = useMemo(() => {
    if (!selectedClient) {
      return rawOrders;
    }
    return rawOrders.filter((item) => {
      return item.orderMark === selectedClient;
    });
  }, [selectedClient, rawOrders]);

  useLayoutEffect(() => {
    setTableId(_tableId);
  }, [_tableId]);

  useLayoutEffect(() => {
    if (!cmdData) {
      setTableId("");
      setClientMarks([]);
      setSelectedClient(null);
    }
  }, [cmdData]);

  const tables = cmdData?.note.table?.choices;

  const _id = useMemo(() => {
    return billType === "partial" ? sessionId : tableId;
  }, [tableId, sessionId, billType]);

  const getTableClient = useCallback(
    async (tableId: string) => {
      try {
        const clients = await sessionsAdminRepo.getTableClients(tableId);
        setClientMarks(clients);
      } catch (e) {
        showError({
          message /*----*/: t("msg_error_client_list_loading"),
          description /**/: t("msg_error_client_list_loading_desc"),
        });
        return [];
      }
    },
    [t]
  );

  const topAction = useMemo(() => {
    if (billType === "partial" && _id && tableIdentifier) {
      return t("msg_manage_client_account_in_table") + tableIdentifier;
    }
    return (
      <Row gutter={8} style={{ margin: "-5px -5px -5px -15px" }}>
        <Col span={13}>
          {/* TABLE SELECTOR */}
          <InputSelect
            choices={
              tables?.map((table) => ({
                title: table?.identifier,
                value: table?._id,
              })) || []
            }
            // choices={tables.map(({ _id, identifier }) => ({ value: _id, title: identifier }))}
            help={t("msg_select_table")}
            placeholder={t("table")}
            defaultValue={tableIdentifier || undefined}
            style={{ marginBottom: -10 }}
            helpPlacement="topRight"
            onChange={(value: string) => {
              setTableId(value);
              getTableClient(value).then();
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </Col>
        <Col span={9}>
          {/* Order slot */}
          <InputSelect
            style={{ maxWidth: 100 }}
            placeholder={t("client")}
            defaultValue={(tableIdentifier && t("all")) || undefined}
            choices={[
              { value: 0, title: t("all") },
              ...clientMarks.map((v) => ({ title: v.toString(), value: v })),
            ]}
            help={t("msg_select_client")}
            helpPlacement="topRight"
            onSelect={(value: any) => setSelectedClient(value)}
          />
        </Col>
      </Row>
    );
  }, [
    _id,
    setTableId,
    tableIdentifier,
    tables,
    billType,
    clientMarks,
    getTableClient,
    t,
  ]);

  const loadOrders = useCallback(() => {
    setLoading(true);
    const repo = billType === "partial" ? sessionsRepo : tableRepo;
    repo.getPendingOrders(_id).then((orders) => {
      setOrders(orders);
      setLoading(false);
    });
  }, [_id, billType]);

  useEffect(() => {
    if (cmdData && _id) {
      loadOrders();
    }
  }, [loadOrders, cmdData, _id]);

  async function handleChangeOrderOfferStatus(
    order: IOrderListItem,
    offer: IOrderOfferItem,
    { status, from }: IOrderOfferStatusResponse
  ) {
    setLoading(true);
    await changeOrderOfferStatus(order, offer, { status, from });
    setLoading(false);
    loadOrders();
  }

  async function handleClear() {
    let res;
    try {
      if (billType === "partial") {
        res = await sessionsAdminRepo.deleteSession(sessionId);
      } else {
        res = await customerRepo.deleteSessionsByTableId(
          tableId,
          selectedClient || 0
        );
      }
      showSuccess({
        message: t("msg_clean_complete"),
        description:
          t("msg_deleted_sessions") +
          ` ${res.deletedItems}/${res.matchedItems}`,
      });
    } catch (error) {
      showError({
        message: t("msg_error_deleting"),
        description: t("msg_error_delete_sessions_desc"),
      });
    }
  }

  async function handleUpdate(status: string) {
    if (billType === "partial") {
      orderRepo.updateOrdersSatusBySessionId(sessionId, { status }).then(() => {
        setOrders([]);
        showSuccess({
          message: t("msg_updated_orders"),
          description:
            t("msg_orders_pased_to_state") +
            ` ${
              status === "paid"
                ? t("paids").toLowerCase()
                : t("rejecteds").toLowerCase()
            }`,
        });
      });
    } else {
      orderRepo
        .updateOrdersSatusByTableId(tableId, {
          status,
          orderMark: selectedClient || 0,
        })
        .then(() => {
          setOrders([]);
          showSuccess({
            message: t("msg_success_orders_updated"),
            description:
              t("msg_orders_from_table") +
              ` ${tableIdentifier} ` +
              t("msg_pased_to_state") +
              ` ${
                status === "paid"
                  ? t("paids").toLowerCase()
                  : t("rejecteds").toLowerCase()
              }`,
          });
        });
    }
  }

  async function handleGenBill(
    status = false,
    discount = 0,
    includedTip?: number,
    discountNote = "",
    contentToPrint: unknown = ""
  ) {
    if (status) {
      setBillVisibility(false);
    } else {
      setBillVisibility(true);
    }

    if (discount && !discountNote.length) {
      showInfo({
        message: t("msg_must_add_a_discount_note"),
      });
      setBillVisibility(true);
      return;
    }
    setLoadingBill(true);

    let bill: IBill;

    if (billType === "partial") {
      bill = await sessionsRepo.generateBill(
        sessionId,
        status,
        discount,
        includedTip,
        discountNote
      );
    } else {
      bill = await tableRepo.generateBill(
        tableId,
        sessionId,
        status,
        discount,
        includedTip,
        discountNote,
        selectedClient || 0
      );
    }

    setBill(bill);

    if (status) {
      showSuccess({
        message: t("msg_success_bill_sended"),
      });
      if (contentToPrint) {
        componentToPrintRef.current = contentToPrint;
        setTimeout(() => {
          handlePrint?.();
        }, 1000);
      }
      closeCmd();
    }
    setLoadingBill(false);
  }

  return (
    <>
      <ADModal
        title={topAction}
        visible={!!cmdData}
        footer={false}
        onCancel={closeCmd}
        bodyStyle={{ padding: 0 }}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <Card
            actions={
              tableId &&
              (user?.roles?.includes(ERoles.ADMIN) ||
                user?.roles?.includes(ERoles.ACCOUNTANT))
                ? [
                    <BillPaymentCtrl
                      key="bill_payment"
                      bill={bill}
                      loadingBill={loadingBill}
                      billVisibility={billVisibility}
                      onConfirm={(
                        discount,
                        includedTip,
                        discountNote,
                        componentToPrint
                      ) =>
                        handleGenBill(
                          true,
                          discount,
                          includedTip,
                          discountNote,
                          componentToPrint
                        )
                      }
                      onShowBill={() => handleGenBill(false)}
                      onCancel={() => setBillVisibility(false)}
                    />,
                    <OrderStatusCtrl
                      key="order_status"
                      onUpdate={handleUpdate}
                    />,
                    <ClearSessionsCtrl
                      key="clear_sessions"
                      onClear={handleClear}
                    />,
                  ]
                : []
            }
            bodyStyle={{
              maxHeight: "60vh",
              overflowY: "auto",
              padding: "0 0 15px",
            }}
          >
            {orders?.length ? (
              <OrderList
                orderStatusList={mapOrderStatus(OrderStatus, t)}
                onChangeOrderOfferStatus={handleChangeOrderOfferStatus}
                items={orders}
              />
            ) : (
              <Empty
                description={t("msg_no_pending_orders")}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </Card>
        </Spin>
      </ADModal>
    </>
  );
});

export async function changeOrderOfferStatus(
  order: IOrderListItem,
  offer: IOrderOfferItem,
  { status, from }: IOrderOfferStatusResponse
) {
  const parsedStatus = (
    Object.keys(status)
      .map((statusName) => {
        const statusCount = status[statusName];

        return from !== statusName /* This update get implicit */ &&
          statusCount !== 0
          ? { from, to: statusName as TOrderOfferStatus, count: statusCount }
          : null;
      })
      .filter((statusQuery) => statusQuery) as IOrderOfferStatusRequestBody[]
  ).map(({ from, to, count }) => {
    return orderRepo.updateOrderOfferStatus(order._id, offer.offer._id, {
      currentStatus: from,
      toStatus: to,
      count,
    });
  });

  return Promise.all(parsedStatus).catch(() => {
    showError({
      message /*----*/: i18n.t("msg_error_updating"),
      description /**/: i18n.t("msg_error_offer_status_update_desc"),
    });
  });
}
