import Axios from 'axios';
import BaseRepository, { HttpRequester } from '../base.repository';

class LiveChatRepository extends BaseRepository<ILiveChatMessage, ILiveChatMessage, ILiveChatMessage> {
  constructor(httpRequester: HttpRequester = Axios) {
    super(httpRequester, 'liveChat');
  }

  sendLiveChatMessage = async (message: ILiveChatMessage) => {
    try {
      const { data } = await this.httpRequester.post(`${this.endpoint}/sendMessage`, message);
      return data;
    } catch (error) {
      return console.error(error);
    }
  };

  toForm(tag: ILiveChatMessage | null, defaults = {}): ILiveChatMessage {
    if (tag) {
      return tag as ILiveChatMessage;
    } else {
      return {
        to: [],
        offers: [],
        tables: [],
        message: '',
        ...defaults,
      };
    }
  }
}

export interface ILiveChatMessage {
  to: string[];
  offers: string[];
  tables: string[];
  message: string;
}

export default LiveChatRepository;
