import { useCallback, useLayoutEffect, useRef, useState } from 'react'

export function usePointer<T>(prop: T): React.MutableRefObject<T> {
  const item = useRef<T>(prop)

  useLayoutEffect(() => {
    item.current = prop
  }, [prop])

  return item
}

export function useRender() {
  const updater = useState(true)[1]
  return useCallback(() => updater((v) => !v), [updater])
}
