import StripeRepository from "./stripe.repository";

const stripeRepo = new StripeRepository();

export const handleCheckout = async (id: string) => {
  const urlResult = await stripeRepo
    .generateCheckout(id)
    .then(({ url }) => url)
    .catch(console.error);
  window.location.href = urlResult;
};
