import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { ActionBar, ViewLayout } from "components/view-layout";
import React, { useCallback } from "react";
import { ButtonRequestPayment } from "components/common/Buttons";
import styled from "styled-components";
import CustomerRepository from "models/public/customer";
import { showError, showSuccess } from "components/common/Notification";
const customerRepo /**/ = new CustomerRepository();
export enum EOrderScope {
  FULL = "full",
  PARTIAL = "partial",
}

export function BillHasDeleted() {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation();

  const requestPayment = useCallback(
    async (scope: EOrderScope) => {
      try {
        await customerRepo.sendBillRequest(scope);
        showSuccess({
          message /*----*/: t("successPaymentReq"),
          description /**/: t("successPaymentReqDesc"),
        });
      } catch (err) {
        showError({
          message /*----*/: t("errPaymentReq"),
          description /**/: t("errPaymentReqDesc"),
        });
      }
    },
    [t]
  );

  const actionBar = (
    <ActionBar
      style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
    >
      <ButtonRequestPayment
        helpPlacement="bottomRight"
        help={t("paymentReqMyOrdersTltp")}
        style={{ flex: "1 1 auto" }}
        onClick={() => {
          requestPayment(EOrderScope.PARTIAL);
        }}
      >
        {t("myOrders")}
      </ButtonRequestPayment>

      <ButtonRequestPayment
        type={"primary"}
        help={t("paymentReqAllOrdersTltp")}
        helpPlacement="bottomRight"
        style={{ flex: "1 1 auto", padding: "4px 10px" }}
        onClick={() => {
          requestPayment(EOrderScope.FULL);
        }}
      >
        {t("tableOrders")}
      </ButtonRequestPayment>
    </ActionBar>
  );
  return (
    <>
      <BillContainer>
        <ViewLayout actionBar={actionBar}>
          <div style={{ height: "100%", width: "100%", maxHeight: "100%" }}>
            <div>
              <BillHeader>
                <Col span={24}>
                  <strong>{t("request_your_account")}</strong>
                </Col>
              </BillHeader>
            </div>
            <Row
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "justify",
                justifyContent: "center",
              }}
            >
              <Col span={20}>{t("null_bill_msg")}</Col>
            </Row>
          </div>
        </ViewLayout>
      </BillContainer>
    </>
  );
}
// export interface IBillHasDeleted {
//   notifiClose(): void;
// }

const BillHeader = styled.div`
  //background-color: #fafafa;
  padding-bottom: 8px;
  padding: 16px 16px 0;
  text-align: left;
  font-family: bold;
  width: 100%;
  @media print {
    background-color: #ffffff;
  }
`;
const BillContainer = styled.div`
  margin: -24px;
  max-height: 500px;
  display: flex;
`;
