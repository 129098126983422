import { ISMessage, useSocketConfirmation } from "./socket.storage";
import { showInfo, showWarning } from "components/common/Notification";
import { useAuth } from "hooks/auth.hook";
import { useEffect, useGlobal } from "reactn";
import { useMemo } from "reactn";
import { usePointer } from "hooks/state.hook";
import { useTranslation } from "react-i18next";
import TableRepository from "../models/public/table/table.repository";

const tableRepo = new TableRepository();

export function MenuStorage() {
  const { user } = useAuth() || {};
  // eslint-disable-next-line
  const [menus, setMenus] = useGlobal("menus");
  const [socket] = useGlobal("socket");

  const setNotified = useGlobal("notified")[1];
  const sendConfirmation = useSocketConfirmation(socket);

  const $menus = usePointer(menus);

  const tableId = user?.tableId || "";

  //LANGUAGE TRANSLATION
  const { t } = useTranslation();

  const listeners = useMemo(
    () => ({
      menuDeleted({
        message: { _id: menuId, name },
        messageId,
      }: ISMessage<ISResponse>) {
        sendConfirmation(messageId);
        setNotified(true).then();
        const _menus = [...$menus.current];
        const index = _menus.findIndex((menu) => menu._id === menuId);
        _menus.splice(index, 1);
        setMenus(_menus).then();

        if (!tableId) {
          showWarning({
            message: t("msg_menu_delete", { name: name }),
          });
        }
      },

      menuOfferDisabled(data: ISMessage<ISResponse>) {
        sendConfirmation(data.messageId);
        return;
      },

      menuOfferDeleted(data: ISMessage<IMenuData>) {
        sendConfirmation(data.messageId);
        setNotified(true).then();
        if (tableId) {
          return listeners.menuOfferUpdated(data, false);
        }

        showInfo({
          message: t("msg_offer_deleted", { name: data.message.offerName }),
        });
      },

      async menuOfferUpdated(
        {
          message: { offerName, menus: menuItems, offerStatus },
          messageId,
        }: ISMessage<IMenuData>,
        forceNotify = true
      ) {
        sendConfirmation(messageId);
        setNotified(true).then();
        const _menus = [...$menus.current];

        if (tableId)
          try {
            const updatedMenus = await Promise.all(
              menuItems.map(tableRepo.getMenuById)
            );
            updatedMenus.forEach((menu) => {
              const index = _menus.findIndex((_menu) => _menu._id === menu._id);
              if (index !== -1) {
                _menus[index] = menu;
              }
            });
          } catch (error) {
            console.error(error);
          }

        await setMenus(_menus);
        if (forceNotify && !offerStatus) {
          showWarning({
            message: t("disabledOfferTxt", { offer: offerName }),
          });
        } else if (forceNotify && !tableId) {
          showWarning({
            message: t("offerUpdatedTxt", { offer: offerName }),
          });
        }
      },

      async menuOfferUpdatedByReview(data: ISMessage<IMenuData>) {
        sendConfirmation(data.messageId);
        await listeners.menuOfferUpdated(data, false);
      },

      async menuOfferAdded({ message: id, messageId }: ISMessage<string>) {
        sendConfirmation(messageId);
        await setNotified(true);
        const _menus = [...$menus.current];
        const index = _menus.findIndex((menu) => menu._id === id);

        if (tableId)
          try {
            const menu = await tableRepo.getMenuById(id);
            if (index === -1) {
              console.info(new Error("Menu divergence found: Panic!! 💣😱"));
              _menus.push(menu);
            } else {
              _menus[index] = menu;
            }
            setMenus(_menus).then();
          } catch (error) {
            console.error(error);
          }
      },

      async menuUpdated({
        message: { name },
        messageId,
      }: ISMessage<ISResponse>) {
        sendConfirmation(messageId);
        await setNotified(true);

        if (!tableId) {
          showInfo({
            message: t("msg_menu_updated", { menu: name }),
          });
        }

        if (tableId)
          try {
            const _menus = await tableRepo.getMenu(tableId);
            await setMenus(_menus);
          } catch (error) {
            console.error(error);
          }
      },
    }),
    [$menus, setMenus, tableId, setNotified, sendConfirmation, t]
  );

  useEffect(() => {
    if (socket) {
      Object.entries(listeners).forEach(([listener, controller]) => {
        socket.on(listener, controller);
      });
      return () => {
        Object.entries(listeners).forEach(([listeners, controller]) => {
          socket.off(listeners, controller);
        });
      };
    }
  }, [socket, listeners]);

  return null;
}

interface ISResponse {
  _id: string;
  name: string;
}

export interface IMenuData {
  offerId: string;
  offerName?: string;
  menus: string[];
  offerStatus?: boolean;
}
