// REACT
import React, { useMemo } from 'react'

// GLOBAL IMPORTS
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

// LOCAL IMPORTS
import { Storage } from './storage'

// ADMIN
import AdminRoutes from './routes/admin'

// PUBLIC
import PublicRoutes from './routes/public'

// AUTH
import AuthRoutes from './routes/auth'

// ERRORS
import ErrorRoutes, { EErrorRoutes } from './routes/error.routes'

// LAYOUTS
import { Admin, Empty, Public } from './layouts'
import { TestPage } from 'pages'

const { Layout: AdminLayout } = Admin
const { Layout: PublicLayout } = Public
const { Layout: EmptyLayout } = Empty

const AppRouter = () => {
  const notFoundRoute = useMemo(() => {
    return ErrorRoutes.find(({ id }) => id === EErrorRoutes.NOT_FOUND)
  }, [])

  const forbiddenRoute = useMemo(() => {
    return ErrorRoutes.find(({ id }) => id === EErrorRoutes.FORBIDDEN)
  }, [])

  return (
    <Router>
      <Switch>
        <Route path="/test" exact render={() => <TestPage></TestPage>} />
        {/* ERROR ROUTES */}
        {/* FORBIDDEN */}
        <Route path={forbiddenRoute?.path || ''} component={forbiddenRoute?.page} />
        {/* 404 */}
        <Route path={notFoundRoute?.path || ''} component={notFoundRoute?.page} />

        {/* AUTH ROUTE */}
        <Route
          path="/security"
          render={() => (
            <AuthRoutes
              layout={EmptyLayout}
              notFoundUrl={notFoundRoute?.path || '/'}
              forbiddenUrl={forbiddenRoute?.path || '/'}
            />
          )}
        />

        {/* ADMIN ROUTES */}
        <Route
          path="/admin"
          render={() => (
            <AdminRoutes
              layout={AdminLayout}
              notFoundUrl={notFoundRoute?.path || '/'}
              forbiddenUrl={forbiddenRoute?.path || '/'}
            />
          )}
        />

        {/* INITIAL REDIRECTION */}
        <Route path="/" exact>
          <Redirect to={'/admin'} />
        </Route>

        {/* PUBLIC ROUTES */}
        <Route
          path="/"
          render={() => (
            <PublicRoutes
              layout={PublicLayout}
              notFoundUrl={notFoundRoute?.path || '/'}
              forbiddenUrl={forbiddenRoute?.path || '/'}
            />
          )}
        />

        <Route path="*">
          <Redirect to={notFoundRoute?.path || ''} />
        </Route>
      </Switch>
    </Router>
  )
}

export { Storage }
export default AppRouter
