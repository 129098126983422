// GLOBAL IMPORTS
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import React, { useLayoutEffect, useState } from 'react'

// LOCAL IMPORTS
import { INavItem } from './types'

const { SubMenu } = Menu

/** Get back the tree of parents for the active item to open those submenus */
const getParents = (navItems: INavItem[], targetId: string | null | undefined): string[] | null => {
  if (!targetId) {
    return []
  }

  const targetNavItem = navItems.find(({ id }) => id === targetId)

  if (targetNavItem) {
    return []
  } else {
    for (let i = 0; i < navItems.length; ++i) {
      const { id, children } = navItems[i]
      const matchedChildren = children?.length ? getParents(children, targetId) : null
      if (matchedChildren) {
        return [id, ...matchedChildren]
      }
    }
  }

  return null
}

const NavMenu = ({ navItems, active, openMenus, onClickLink }: INavMenuProps) => {
  // STATE
  const [activeItem, setActiveItem] = useState<string>('')
  const [openKeys, setOpenKeys] = useState<string[]>([])

  const renderNavItem = ({
    id,
    label,
    icon,
    link,
    children,
    onClick,
  }: INavItem & { onClick?: (id: string) => unknown }) => {
    const handleClickLink = onClick ? () => onClick(id) : undefined

    return children && children.length ? (
      <SubMenu key={id} title={label} icon={icon}>
        {children.map((child) => renderNavItem({ ...child, onClick }))}
      </SubMenu>
    ) : (
      <Menu.Item key={id} icon={icon} onClick={handleClickLink}>
        {link ? <Link to={link}>{label}</Link> : label}
      </Menu.Item>
    )
  }

  /** Make sure opening a new submenu close all open submenus */
  const onOpenChange = (openSubMenus: unknown) => {
    const newOpenKey = (openSubMenus as string[]).filter((key) => openKeys.indexOf(key) === -1)
    setOpenKeys(newOpenKey)
  }

  const handleNavItemSelected = ({ key }: { key: React.Key }) => {
    setActiveItem(key as string)
  }

  useLayoutEffect(() => {
    setActiveItem(active || (navItems?.length ? navItems[0].id : ''))
    setOpenKeys(openMenus || getParents(navItems, active) || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navItems, active, openMenus])

  return (
    <Menu
      theme="light"
      mode="inline"
      selectedKeys={activeItem ? [activeItem] : undefined}
      onOpenChange={onOpenChange}
      openKeys={openKeys}
      onSelect={handleNavItemSelected}
    >
      {navItems.map((navItem) => renderNavItem({ ...navItem, onClick: onClickLink }))}
    </Menu>
  )
}

export interface INavMenuProps {
  navItems: INavItem[]
  active?: string | null
  openMenus?: string[]
  onClickLink?: (id: string) => unknown
}

export default NavMenu
