// REACT

import React from 'react'

// GLOBAL IMPORTS
import { Badge } from 'antd'
import { useTranslation } from 'react-i18next'

// LOCAL IMPORTS
import { ActionConfirmation } from './ActionConfirmation'
import {
  IconBack,
  IconBillPayment,
  IconClear,
  IconClock,
  IconClose,
  IconCloseAll,
  IconDelete,
  IconDiscard,
  IconDone,
  IconDown,
  IconEdit,
  IconFilter,
  IconGo,
  IconGroup,
  IconLogout,
  IconMenu,
  IconMinus,
  IconNote,
  IconNotifications,
  IconPayment,
  IconPhone,
  IconPlus,
  IconSearch,
  IconShoppingCart,
  IconStatusUpdate,
  IconUp,
} from './Icons'
import PushButton, { IPushButtonProps } from './PushButton'
import styled from 'styled-components'
import ToggleButton, { IToggleButtonProps } from './ToggleButton'

// A
type IButtonAddProps = IPushButtonProps

export const ButtonAdd = ({ children, ...props }: IButtonAddProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children ?? t('insertTxt')
  return (
    <PushButton icon={<IconPlus />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonAddExtendedProps = IButtonAddProps
export const ButtonAddExtended = ({ children, ...props }: IButtonAddExtendedProps): JSX.Element => {
  return (
    <ButtonAdd {...props} block type="text">
      {children}
    </ButtonAdd>
  )
}

// B
type IButtonBackProps = IPushButtonProps
export const ButtonBack = ({ children, ...props }: IButtonBackProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('backTxt')
  return (
    <PushButton type="link" iconOnly icon={<IconBack />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

// C
type IButtonCallWaitressProps = IPushButtonProps
export const ButtonCallWaitress = ({
  children,
  ...props
}: IButtonCallWaitressProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('callWaiterTxt')
  return (
    <PushButton icon={<IconPhone />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonClearProps = IPushButtonProps
export const ButtonClear = ({ children, ...props }: IButtonClearProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('clearTxt')
  return (
    <PushButton icon={<IconClear style={{ fontSize: 22 }} />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

interface IButtonClearSystem extends IPushButtonProps {
  onConfirm(): void
}
export function ButtonClearSystem({ children, style, onConfirm, ...props }: IButtonClearSystem) {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  return (
    <ActionConfirmation
      title={t('closeSysSessions')}
      message={t('closeSysSessionsDesc')}
      onConfirm={onConfirm}
      onDiscard={() => ({})}
    >
      <PushButton
        {...props}
        style={{ marginRight: 20, border: 'none', boxShadow: 'none', ...style, borderRadius: 50 }}
        icon={<IconCloseAll className="text-primary" style={{ fontSize: '25px' }} />}
      >
        {children}
      </PushButton>
    </ActionConfirmation>
  )
}

type IButtonBillPayment = IPushButtonProps
export const ButtonBillPayment = ({ children, ...props }: IButtonBillPayment): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('clearTxt')
  return (
    <PushButton icon={<IconBillPayment style={{ fontSize: 26 }} />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

export interface IButtonCartProps extends IPushButtonProps {
  count: number
  iconSize?: string
}

export const ButtonCart = ({ children, count, iconSize, ...props }: IButtonCartProps) => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('orderTxt')
  const iconStyle: React.CSSProperties = {}

  if (iconSize) {
    iconStyle['fontSize'] = iconSize
  }

  const counterVal = count > 99 ? '99+' : `${count}`

  return (
    <Badge count={count ? <CounterBadge>{counterVal}</CounterBadge> : <></>} overflowCount={10}>
      <PushButton icon={<IconShoppingCart style={iconStyle} />} {...props}>
        {buttonContent}
      </PushButton>
    </Badge>
  )
}

type IButtonCloseProps = IPushButtonProps
export const ButtonClose = ({ children, ...props }: IButtonCloseProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('closeTxt')
  return (
    <PushButton icon={<IconClose />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

// D
type IButtonDeleteProps = IPushButtonProps
export const ButtonDelete = ({ children, ...props }: IButtonDeleteProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('removeTxt')

  return (
    <PushButton icon={<IconDelete />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonDiscardProps = IPushButtonProps
export const ButtonDiscard = ({ children, ...props }: IButtonDiscardProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('discardTxt')
  return (
    <PushButton icon={<IconDiscard />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonDoneProps = IPushButtonProps
export const ButtonDone = ({ children, ...props }: IButtonDoneProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('doneTxt')
  return (
    <PushButton icon={<IconDone />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonDownProps = IPushButtonProps
export const ButtonDown = ({ children, ...props }: IButtonDownProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('downTxt')
  return (
    <PushButton icon={<IconDown />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

// E
type IButtonEditProps = IPushButtonProps
export const ButtonEdit = ({ children, ...props }: IButtonEditProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('editTxt')
  return (
    <PushButton icon={<IconEdit />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

// F
type IButtonFilterProps = IPushButtonProps
export const ButtonFilter = ({ children, ...props }: IButtonFilterProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('filterTxt')
  return (
    <PushButton icon={<IconFilter />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

// G
type IButtonGoProps = IPushButtonProps
export const ButtonGo = ({ children, ...props }: IButtonGoProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('gotxt')
  return (
    <PushButton icon={<IconGo />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonGroupProps = IToggleButtonProps
export const ButtonGroup = ({ children, ...props }: IButtonGroupProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('groupTxt')
  return (
    <ToggleButton icon={<IconGroup />} {...props}>
      {buttonContent}
    </ToggleButton>
  )
}
// H
// I
// J
// K
// L
type IButtonLogoutProps = IPushButtonProps
export const ButtonLogout = ({ children, ...props }: IButtonLogoutProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('leaveTxt')
  return (
    <PushButton type="link" icon={<IconLogout />} style={{ flex: '1 1 auto' }} {...props}>
      {buttonContent}
    </PushButton>
  )
}
// M
// eslint-disable-next-line
type IButtonMenuProps = IPushButtonProps
export const ButtonMenu = ({ children, ...props }: IButtonMenuProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('menu')
  return (
    <PushButton iconOnly type="link" icon={<IconMenu />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonMinusProps = IPushButtonProps
export const ButtonMinus = ({ children, ...props }: IButtonMinusProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('removeTxt')
  return (
    <PushButton icon={<IconMinus />} {...props}>
      {buttonContent}
    </PushButton>
  )
}
// N
type IButtonNoteProps = IPushButtonProps
export const ButtonNote = ({ children, ...props }: IButtonNoteProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('notesTxt')
  return (
    <PushButton icon={<IconNote />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonNotificationProps = IPushButtonProps
export const ButtonNotification = ({
  children,
  ...props
}: IButtonNotificationProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('notificationsTxt')
  return (
    <PushButton icon={<IconNotifications />} {...props}>
      {buttonContent}
    </PushButton>
  )
}
// O
// P
type IButtonPendingProps = IToggleButtonProps
export const ButtonPendingGroup = ({ children, ...props }: IButtonPendingProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('groupTxt')
  return (
    <ToggleButton icon={<IconClock />} {...props}>
      {buttonContent}
    </ToggleButton>
  )
}
// Q
// R
type IButtonRequestOrderPermission = IPushButtonProps
export const ButtonRequestOrderPermission = ({
  children,
  ...props
}: IButtonRequestOrderPermission) => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('askPermissionTxt')

  return (
    <PushButton icon={<IconShoppingCart />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonRequestPaymentProps = IPushButtonProps
export const ButtonRequestPayment = ({
  children,
  ...props
}: IButtonRequestPaymentProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('payTxt')
  return (
    <PushButton icon={<IconPayment />} {...props}>
      {buttonContent}
    </PushButton>
  )
}
// S
type IButtonSearchProps = IPushButtonProps
export const ButtonSearch = ({ children, ...props }: IButtonSearchProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('searchTxt')
  return (
    <PushButton icon={<IconSearch />} {...props}>
      {buttonContent}
    </PushButton>
  )
}

type IButtonStatusUpdateProps = IPushButtonProps
export const ButtonStatusUpdate = ({
  children,
  ...props
}: IButtonStatusUpdateProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('updateStatusTxt')
  return (
    <PushButton icon={<IconStatusUpdate size="1.5em" />} {...props}>
      {buttonContent}
    </PushButton>
  )
}
// T
// U
type IButtonUpProps = IPushButtonProps
export const ButtonUp = ({ children, ...props }: IButtonUpProps): JSX.Element => {
  //LANGUAGE TRANSLATION
  const { t } = useTranslation()
  const buttonContent = children || t('upTxt')
  return (
    <PushButton icon={<IconUp />} {...props}>
      {buttonContent}
    </PushButton>
  )
}
// V
// W
// X
// Y
// Z

const CounterBadge = styled.span`
  background: red;
  border-radius: 50px;
  font-size: 12px;
  padding: 3px 5px;
  color: white;
`
