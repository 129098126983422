// GLOBAL IMPORTS
import {
  Input as ADInput,
  InputNumber as ADInputNumber,
  Select as ADSelect,
  Switch as ADSwitch,
} from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import { InputProps, TextAreaProps } from 'antd/lib/input'
import { SelectProps } from 'antd/lib/select'
import { SwitchProps } from 'antd/lib/switch'
import React from 'react'

// LOCAL IMPORTS
import { IconSearch } from './Icons'
import Tooltip, { TooltipPlacement } from './Tooltip'

const { Option: ADOption } = ADSelect
import { useTranslation } from 'react-i18next'

const Input = ({ choices, help, helpPlacement, ...props }: IInputProps | ISelectProps) => {
  const renderSelectChoices = () => {
    return (choices as Array<IChoiceObject | string>).map((choice: string | IChoiceObject, idx) => {
      let choiceTitle = ''
      let choiceValue = ''
      if (typeof choice === 'string') {
        choiceTitle = choice
        choiceValue = choice
      } else {
        const { title, value } = choice as IChoiceObject
        choiceTitle = (title ?? value) as string
        choiceValue = (value ?? title) as string
      }

      return (
        <ADOption key={idx} title={choiceTitle} value={choiceValue}>
          {choiceTitle}
        </ADOption>
      )
    })
  }
  const selectProps = props as SelectProps<any>
  const inputProps = props as InputProps
  const InputComponent = choices ? (
    <ADSelect {...selectProps} style={{ width: '100%' }}>
      {renderSelectChoices()}
    </ADSelect>
  ) : (
    <ADInput {...inputProps} />
  )

  return help ? (
    <Tooltip title={help} placement={helpPlacement}>
      {InputComponent}
    </Tooltip>
  ) : (
    InputComponent
  )
}
export interface IChoiceObject {
  title: string | number
  value: string | number
}
export interface IInputProps extends InputProps {
  choices?: string[] | IChoiceObject[]
  help?: string
  helpPlacement?: TooltipPlacement
}

export interface ISelectProps extends SelectProps<any> {
  choices: string[] | number[] | { title: string; value: string | number }[]
  help?: string
  helpPlacement?: TooltipPlacement
}
export const InputText = (props: IInputProps) => {
  return <Input {...props} />
}

export const InputPassword = ({ help, helpPlacement, ...props }: IInputProps) => {
  const inputComponent = <ADInput.Password {...props} />
  return help ? (
    <Tooltip title={help} placement={helpPlacement}>
      {inputComponent}
    </Tooltip>
  ) : (
    inputComponent
  )
}

export const InputSelect = (props: ISelectProps) => {
  const { t } = useTranslation()

  const defaultSelectPlaceholder = t('select')
  return <Input placeholder={defaultSelectPlaceholder} {...props} />
}

export const InputTags = (props: ISelectProps) => {
  return <Input {...props} mode="tags" />
}

export interface ITextAreaProps extends TextAreaProps {
  help?: string
  helpPlacement?: TooltipPlacement
}
export const TextArea = ({ help, helpPlacement, ...props }: ITextAreaProps) => {
  const textAreaComponent = <ADInput.TextArea {...props} />
  return help ? (
    <Tooltip title={help} placement={helpPlacement}>
      {textAreaComponent}
    </Tooltip>
  ) : (
    textAreaComponent
  )
}

export const InputSearch = (props: IInputProps) => {
  const { t } = useTranslation()
  const defaultPlaceholder = t('searchTxt')
  return <Input placeholder={defaultPlaceholder} {...props} allowClear suffix={<IconSearch />} />
}
export interface IInputNumberProps extends InputNumberProps {
  help?: string
  helpPlacement?: TooltipPlacement
}
export const InputNumber = ({ help, helpPlacement, ...props }: IInputNumberProps) => {
  const inputNumber = <ADInputNumber {...props} inputMode="numeric" />
  return help ? (
    <Tooltip title={help} placement={helpPlacement}>
      {inputNumber}
    </Tooltip>
  ) : (
    inputNumber
  )
}

export const Switch = ({ help, helpPlacement, ...props }: ISwitchProps) => {
  const switchComponent = <ADSwitch {...props} />
  return help ? (
    <Tooltip title={help} placement={helpPlacement}>
      {switchComponent}
    </Tooltip>
  ) : (
    switchComponent
  )
}
export interface ISwitchProps extends SwitchProps {
  help?: string
  helpPlacement?: TooltipPlacement
}

export default Input
