/** HOOK FOR MANAGING PUBLIC NAMESPACE AND ITS MODULES */

// REACT
import { createContext, useContext } from "react";

// LOCAL
import {
  INamespace,
  PublicNamespace,
  PUBLIC_FEATURES as features,
} from "modules";

export const PublicNamespaceContext = createContext<INamespace | null>(null);

export const usePublicNamespace = (): INamespace | null => {
  return useContext<INamespace | null>(PublicNamespaceContext);
};

export const usePublic = (): INamespace => {
  PublicNamespace.turnModules(features);

  return PublicNamespace;
};
