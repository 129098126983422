// REACT
import React from 'react'

// GLOBAL IMPORTS
import { Layout } from 'antd'

// LOCAL IMPORTS

// HOOKS
import { IRoute } from 'hooks/route.hook'
import { useViewport } from 'hooks/viewport.hook'

const PublicLayout = ({ children }: IPublicLayoutProps) => {
  const [fullHeight, fullWidth] = useViewport()

  const layoutStyle = {
    minHeight: fullHeight,
    height: fullHeight,
    width: fullWidth,
    backgroundColor: '#fff',
  }

  return (
    <Layout className="public-layout" style={layoutStyle}>
      {children}
    </Layout>
  )
}

export interface IPublicLayoutProps {
  children?: React.ReactNode
  authorizedRoutes?: IRoute[]
  deniedRoutes?: IRoute[]
  activeRoute?: IRoute
}

export default PublicLayout
