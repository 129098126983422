import { DeniedPage, ErrorPage, NotFoundPage } from '../pages'

// INTERFACES
import { IRoute } from './types'

export enum EErrorRoutes {
  NOT_FOUND = 'notFound',
  ERROR = 'error',
  FORBIDDEN = 'forbidden',
}

const error: IRoute[] = [
  {
    id: 'notFound',
    path: '/not-found',
    page: NotFoundPage,
  },
  {
    id: 'error',
    path: '/error',
    page: ErrorPage,
  },
  {
    id: 'forbidden',
    path: '/forbidden',
    page: DeniedPage,
  },
]

export default error
