import { HttpRequester } from 'services/http/http.interface'
import Axios from 'axios'

export class SessionsRepository {
  public endpoint: string

  constructor(public httpRequester: HttpRequester = Axios) {
    this.endpoint = '/admin/sessions'
  }

  getTableClients(tableId: string) {
    return this.httpRequester
      .get(this.endpoint + `/${tableId}/order-marks`)
      .then(({ data }) => data)
  }

  deleteSession(sessionId: string): Promise<{ matchedItems: number; deletedItems: number }> {
    return this.httpRequester.delete(`admin/sessions/session/${sessionId}`).then(({ data }) => data)
  }

  clearSystem() {
    return this.httpRequester.delete(this.endpoint)
  }
}
