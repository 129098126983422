import React from 'react'

// LOCAl IMPORTS
import CountTag from './CountTag'

const PriceTag = ({ amount = 0, unit }: IPriceProps) => {
  return <CountTag amount={amount?.toFixed(2) || 0} unit={unit} />
}

export interface IPriceProps {
  amount: number | null
  unit?: string
}

export default PriceTag
