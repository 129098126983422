import {
  EOrderStatus,
  INote,
  IOrder,
  IOrderForm,
  IOrderListItem,
  IOrderOfferItem,
  TOrderOfferStatus,
  TOrderStatus,
} from './types'
import Axios from 'axios'
import BaseRepository, { HttpRequester } from '../../base.repository'

export class OrderRepository extends BaseRepository<IOrder, IOrderListItem, IOrderForm> {
  constructor(httpRequester: HttpRequester = Axios) {
    super(httpRequester, 'admin/orders')
  }

  async list(query = {}): Promise<IOrderListItem[]> {
    const orders = await super.list(query)

    return orders.map((order) => {
      return {
        ...order,
        offers: this.mergeOffersAndNotes(order.offers, order.notes),
      }
    })
  }

  insert(newItemData: IOrderForm, params: { [key: string]: unknown } = {}): Promise<IOrder> {
    return this.httpRequester.post(this.endpoint, newItemData, { params: params })
  }

  async updateOrderOfferStatus(
    orderId: string,
    offerId: string,
    status: { currentStatus: TOrderOfferStatus; toStatus: TOrderOfferStatus; count: number },
  ) {
    try {
      await this.httpRequester.put(`${this.endpoint}/${orderId}/offers/${offerId}`, status)
    } catch (err) {
      throw new Error('Error updating Offer status')
    }
  }

  updateTable(fromTable: string, toTable: string) {
    return this.httpRequester
      .post(this.endpoint + '/update-table', { fromTable, toTable })
      .then(({ data }) => data)
  }

  async updateOrderStatus(orderId: string, status: TOrderStatus) {
    try {
      await this.httpRequester.patch(`${this.endpoint}/${orderId}/status`, { status })
    } catch (err) {
      throw new Error('Error updating Order status')
    }
  }

  async get(orderId: string): Promise<IOrder> {
    const order = await super.get(orderId)

    return {
      ...order,
      offers: this.mergeOffersAndNotes(order.offers, order.notes),
    }
  }

  /**
   * @override
   */
  toForm(order: IOrder | null, defaults = {}): IOrderForm {
    if (order) {
      const { table, offers, notes } = order
      return {
        ...order,
        table: table?._id || '',
        offers: this.mergeOffersAndNotes(offers, notes),
      }
    } else {
      return {
        table: '',
        status: EOrderStatus.IN_PROGRESS,
        offers: [],
        ...defaults,
      }
    }
  }

  mergeOffersAndNotes(offers: IOrderOfferItem[], notes: INote[] = []): IOrderOfferItem[] {
    return offers.map((offer) => {
      // Add notes to offers
      const note = notes?.find((note) => note.offer === offer.offer._id)?.note || ''
      return { ...offer, note }
    })
  }

  updateOrdersSatusByTableId = (tableId: string, data: unknown) => {
    return this.httpRequester
      .patch(this.endpoint + `/table/${tableId}/status`, data)
      .then(({ data }) => data)
      .catch(console.error)
  }

  updateOrdersSatusBySessionId = (sessionId: string, data: { status: string }) => {
    return this.httpRequester
      .patch(this.endpoint + `/session/${sessionId}/status`, data)
      .then(({ data }) => data)
      .catch(console.error)
  }
}

export default OrderRepository
