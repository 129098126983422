// REACT
import React from 'react'

// GLOBAL IMPORTS
import styled from 'styled-components'

// LOCAL IMPORTS
import { ButtonBack } from '../Buttons'

const PageHeader = ({ onBack, title, actions }: IPageHeaderProps) => {
  // return <ADPageHeader {...props} onBack={onBack} />

  const renderPageTitle = () => {
    if (!title) return null

    if (typeof title === 'string') {
      return <PageTitle>{title}</PageTitle>
    } else {
      return title
    }
  }
  return (
    <PageHeaderContainer>
      {onBack && (
        <LeftSlot>
          <ButtonBack onClick={onBack} />
        </LeftSlot>
      )}
      <CentralSlot>{renderPageTitle()}</CentralSlot>
      <RightSlot>{actions}</RightSlot>
    </PageHeaderContainer>
  )
}

const PageHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  padding: 0.5em 0;
  & > * {
    align-items: center;
  }
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`

const LeftSlot = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: flex-start;
`

const CentralSlot = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
`

const PageTitle = styled.h2`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`

const RightSlot = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`

export interface IPageHeaderProps {
  title?: React.ReactNode
  actions?: React.ReactNode[]
  onBack?: () => unknown
}

export default PageHeader
