// REACT
import React from 'react'

// GLOBAL IMPORTS
import { Badge as ADBadge } from 'antd'
import { BadgeProps } from 'antd/lib/badge'

const Badge = ({ ...props }: IBadgeProps) => {
  return <ADBadge {...props} />
}

export type IBadgeProps = React.PropsWithChildren<BadgeProps>

export default Badge
