// REACT
import React, { useMemo, useState } from "react";

// GLOBAL IMPORTS
import { Button, Card, Col, Popover, Radio, Row, Typography } from "antd";
import styled from "styled-components";

// LOCAL IMPORTS
import { Container, ViewDetails } from "components/view-layout";
import { PriceTag, Section } from "components/common";
import OrderOfferList from "./offers/OrderOfferList";

// REPOS
import { ActionConfirmation } from "components/common/ActionConfirmation";
import {
  ButtonDiscard,
  ButtonDone,
  ButtonEdit,
} from "components/common/Buttons";
import { FormUserData } from "./FormUserData";
import { IDeliverySpecs } from "models/admin/orders/types";
import { ITableListItem } from "models/admin/table.repository";
import { showError, showSuccess } from "components/common/Notification";
import { useCallback } from "reactn";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import OrderRepository, { EOrderStatus, IOrder } from "models/admin/orders";

const { Title, Paragraph, Text } = Typography;

const PriceTagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const OrderDetails = ({ order, loading, tables, onClose }: IOrderDetails) => {
  const { orderStatus: OrderStatusParam } = useParams<{
    orderStatus: EOrderStatus;
  }>();
  const {
    offers = [],
    totalPrice,
    status,
    table,
    _id,
    shortId,
    deliverySpecs,
    discount,
    discountNote,
    includedTip,
  } = order || {};

  //LANGUAGE TRANSLATION
  const { t } = useTranslation();

  const [formDataBind, setFormDataBind] = useState<
    null | ((p?: IDeliverySpecs) => void)
  >(null);

  const showFormUserData = useCallback(() => {
    return new Promise<IDeliverySpecs>((res) => {
      setFormDataBind(() => res);
    }).then((res) => {
      setFormDataBind(null);
      return res;
    });
  }, [setFormDataBind]);

  const orderPrices = useMemo(() => {
    const prices = Object.entries(totalPrice?.totalPrice || {});

    return prices.length
      ? prices.map((price, index) => {
          return <PriceTag key={index} amount={price[1]} unit={price[0]} />;
        })
      : [<PriceTag key={0} amount={0} unit={""} />];
  }, [totalPrice]);

  return (
    <ViewDetails className="order-details-view" loading={loading}>
      <Container
        className="order-details-container"
        justifyContent="stretch"
        alignItems="stretch"
        flexDirection="column"
      >
        <Container fixed>
          <Row
            gutter={16}
            className="padding-content"
            style={{ width: "100%", paddingBottom: 0 }}
          >
            <Col span={16}>
              <Title level={5}>Código de Orden</Title>
              <Paragraph>{shortId || _id || "?"}</Paragraph>
            </Col>

            <Col span={8}>
              <Row justify="end">
                <PriceTagWrapper>{orderPrices}</PriceTagWrapper>
              </Row>
            </Col>
          </Row>

          <Row
            gutter={16}
            style={{ width: "100%", paddingLeft: "1rem", paddingRight: "1rem" }}
          >
            <Col span={12}>
              <Title level={5}>{table?.identifier || "?"}</Title>
              <Paragraph>{t(status || "inProgress")}</Paragraph>
            </Col>

            {/* Table changer (In progress only) */}
            {(table &&
              OrderStatusParam === EOrderStatus.IN_PROGRESS &&
              !deliverySpecs && (
                <Col span={12}>
                  <TableChanger
                    from={table._id}
                    tables={tables}
                    onFinish={onClose}
                  />
                </Col>
              )) ||
              (discount || includedTip ? (
                <Col span={12}>
                  {discount ? (
                    <Row justify="end">
                      <Col>
                        <Title level={5}>
                          Descuento{" "}
                          <Text className="text-primary">{discount}%</Text>
                        </Title>
                      </Col>
                    </Row>
                  ) : null}

                  {includedTip ? (
                    <Row justify="end">
                      <Col>
                        <Title level={5}>
                          Propina{" "}
                          <Text className="text-primary">{includedTip}%</Text>
                        </Title>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              ) : null)}
          </Row>

          {discountNote ? (
            <div style={{ padding: "0 1em 0 1em" }}>
              <Row>
                <Col flex="auto">
                  <Title level={5}>{t("discount_note")}</Title>
                  <Paragraph
                    ellipsis={{
                      rows: 1,
                      expandable: true,
                      symbol: " Ver más",
                      onEllipsis: (ellipsis) => {
                        console.info("Ellipsis changed:", ellipsis);
                      },
                    }}
                  >
                    {discountNote}
                  </Paragraph>
                </Col>
              </Row>
            </div>
          ) : null}

          {deliverySpecs ? (
            <Row justify="end">
              <Col>
                <Button
                  onClick={showFormUserData}
                  style={{ marginBottom: 4, marginRight: 8 }}
                >
                  Detalles de Entrega
                </Button>
              </Col>
            </Row>
          ) : null}
        </Container>
        <Container
          className="order-details-container"
          justifyContent="stretch"
          alignItems="stretch"
          flexDirection="column"
        >
          <Container scrollable>
            <Section
              title={t("orders")}
              style={{ flexGrow: 1 }}
              className="padding-content"
            >
              <Container>
                <Container scrollable height="100%">
                  <OrderOfferList items={offers} />
                </Container>
              </Container>
            </Section>
          </Container>
        </Container>
      </Container>

      <FormUserData
        resolver={formDataBind}
        edit={false}
        userData={deliverySpecs}
      />
    </ViewDetails>
  );
};

export interface IOrderDetails {
  order?: IOrder | null;
  loading?: boolean;
  tables?: ITableListItem[];
  onClose?(): void;
}

export default OrderDetails;

// <!-- Table Changer Component -->
const orderRepo = new OrderRepository();

interface iTableChanger {
  from: string;
  tables?: ITableListItem[];
  onFinish?(): void;
}

const TableChanger = (props: iTableChanger) => {
  const { from, tables, onFinish } = props;
  const [selected, setSelected] = useState("");
  const [cardVisible, setCardVisible] = useState(false);
  const { t } = useTranslation();

  const handleChangeTable = useCallback(async () => {
    try {
      await orderRepo.updateTable(from, selected);
      showSuccess({
        message: t("msg_success_table_update_desc"),
      });
      onFinish?.();
    } catch (error: any) {
      if (error.response?.status === 409) {
        showError({
          message: t("msg_error_table_occupied"),
        });
        return;
      }
      showError({
        message: t("msg_error"),
      });
    }
  }, [from, selected, onFinish, t]);

  const handleDiscard = useCallback(() => {
    setSelected("");
    setCardVisible(false);
  }, []);

  const cardContent = useMemo(() => {
    return (
      <Card
        style={{
          margin: "-12px -16px",
          maxWidth: 300,
        }}
        title={<Title level={4}>{t("msg_select_table")}</Title>}
        bodyStyle={{
          paddingTop: 5,
          paddingBottom: 5,
          overflowY: "scroll",
          maxHeight: 200,
        }}
        actions={[
          <div key={1} style={{ margin: "0 10px" }}>
            <ButtonDiscard onClick={handleDiscard} style={{ marginRight: 10 }}>
              {t("discardTxt")}
            </ButtonDiscard>
            <ActionConfirmation
              message={t("msg_action_move_to_different_table")}
              title={t("msg_change_orders_from_table")}
              onConfirm={handleChangeTable}
              onDiscard={() => {
                /*noop*/
              }}
              placement="topRight"
            >
              <ButtonDone type="primary" disabled={!selected}>
                {t("msg_done")}
              </ButtonDone>
            </ActionConfirmation>
          </div>,
        ]}
      >
        <Radio.Group
          value={selected}
          onChange={(e) => {
            setSelected(e.target.value);
          }}
        >
          {tables?.map(({ _id, identifier }) => (
            <div key={_id}>
              <Radio
                style={{
                  display: "block",
                  height: "30px",
                  lineHeight: "30px",
                }}
                value={_id}
              >
                {identifier}
              </Radio>
            </div>
          ))}
        </Radio.Group>
      </Card>
    );
  }, [tables, handleChangeTable, selected, handleDiscard, t]);

  if (!tables) return null;

  return (
    <div style={{ textAlign: "right", width: "100%", marginTop: 12 }}>
      <Popover
        content={cardContent}
        trigger="click"
        placement="topRight"
        visible={cardVisible}
        onVisibleChange={setCardVisible}
      >
        <ButtonEdit>{t("msg_change_table")}</ButtonEdit>
      </Popover>
    </div>
  );
};
