import { useMemoizedFn } from 'ahooks';
import { Button, Col, Form, Row, Tag } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { Modal } from 'components/common';
import { InputSelect, TextArea } from 'components/common/Inputs';
import { IEmployee } from 'models/admin/employees';
import { ILiveChatMessage } from 'models/admin/live-chat.repository';
import { IOrderListItem } from 'models/admin/orders/types';
import { ITable } from 'models/admin/table.repository';
import { FormInstance } from 'rc-field-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IDefaultMessage {
  label: string;
  message: string;
}

export interface FormSendMessageProps {
  show: boolean;
  tables: ITable[];
  orders: IOrderListItem[];
  defaultMessages?: IDefaultMessage[];
  employees: IEmployee[];
  onConfirm?: (data: ILiveChatMessage) => void;
  onClose: () => void;
}

interface CustomMessageProps {
  label: string;
  message: string;
  onClicked: (v: string) => void;
}

const MSG_SEPARATOR = '\n\n';

function parseMessage(rawMsg = '') {
  const parts = rawMsg?.split(MSG_SEPARATOR);
  return parts.length === 1 ? ['', parts[0]] : parts;
}

function arrayFilterAndMap<T, E>(
  array: T[],
  filterCb: (v: T, i: number, arr: E[]) => boolean,
  mapCb: (v: T, i: number, arr: E[]) => E = (v) => v as any
): E[] {
  return array.reduce((result, value, index) => {
    if (filterCb(value, index, result)) {
      result.push(mapCb(value, index, result));
    }
    return result;
  }, [] as E[]);
}

function getOffersByTable(orders: IOrderListItem[], tableId: string): string[] {
  return arrayFilterAndMap(
    orders.filter(({ table }) => table.identifier === tableId).flatMap(({ offers }) => offers),
    ({ offer: { name } }, i, res) => !res.includes(name),
    ({ offer: { name } }) => name
  );
}

function setMessage(form: FormInstance, tables: string, message: string) {
  form.setFields([{ name: 'message', value: `${tables}${tables ? MSG_SEPARATOR : ''}${message}`, touched: true }]);
}

function showTableWithOffers(table: string, offers: string[]) {
  return `${table}\n${offers.filter((offer, i, offers) => offers.indexOf(offer) === i).join('\n')}`;
}

function CustomMessage({ message, onClicked, label }: CustomMessageProps) {
  return (
    <Tag style={{ cursor: 'pointer' }} onClick={() => onClicked(message)}>
      {label}
    </Tag>
  );
}

export function FormSendMessage({
  show,
  tables,
  orders,
  employees,
  onConfirm,
  onClose,
  defaultMessages,
}: FormSendMessageProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [ordersByTables, setOrdersByTables] = useState<IOrderListItem[]>([]);
  const [selectOrdersDisabled, setSelectOrdersDisabled] = useState(true);
  const [pendingOffers, setPendingOffers] = useState<{ title: string; value: string }[]>([]);

  defaultMessages = defaultMessages ?? [
    { label: t('send_chat_message_msg_1'), message: t('send_chat_message_msg_1') },
    { label: t('send_chat_message_msg_2'), message: t('send_chat_message_msg_2') },
    { label: t('send_chat_message_msg_3'), message: t('send_chat_message_msg_3') },
  ];

  useEffect(() => {
    setPendingOffers(
      ordersByTables.flatMap(({ offers, table: { identifier: id } }) =>
        arrayFilterAndMap(
          offers,
          ({ offer, pending }, _i, res) => !!(pending && !res.some(({ value }) => value === `${id}&${offer._id}`)),
          ({ offer }) => ({
            title: `(${id}) ${offer.name}`,
            value: `${id}&${offer._id}`,
          })
        )
      )
    );
  }, [ordersByTables]);

  const msgValidator = useMemoizedFn(async (_: any, value: string) => {
    if (!value?.trim()) {
      throw new Error(t('msg_error_send_chat_message_empty_field'));
    }

    const [tables, msg] = parseMessage(value);
    if (tables && msg) {
      return;
    }
    throw new Error(t('msg_error_send_chat_message_empty_textarea_field'));
  });

  const handleAddMsg = useMemoizedFn((msg: string) => {
    const [tables] = parseMessage(form.getFieldValue('message'));
    setMessage(form, tables, msg);
  });

  const handleTableChange = useMemoizedFn((_, options: DefaultOptionType | DefaultOptionType[]) => {
    if (Array.isArray(options)) {
      setSelectOrdersDisabled(!options.length);
      setOrdersByTables(orders.filter((order) => options.some(({ value }) => order.table.identifier === value)));
      const [, msg] = parseMessage(form.getFieldValue('message'));
      const msgTables = options.map(({ title }) => title).join('\n');
      setMessage(form, msgTables, msg);
    }
  });

  const handleOffersChange = useMemoizedFn((_, options: DefaultOptionType | DefaultOptionType[]) => {
    if (Array.isArray(options)) {
      const tables: string[] = form.getFieldValue('tables');
      const msgTables = tables
        .map((table) => {
          const offers = getOffersByTable(ordersByTables, table).filter((offer) =>
            options.some(({ title }) => title === `(${table}) ${offer}`)
          );
          return offers.length === 0 ? table : showTableWithOffers(table, offers);
        })
        .join('\n')
        .trim();
      const [, msg] = parseMessage(form.getFieldValue('message'));
      setMessage(form, msgTables, msg);
    }
  });

  const handleModalClose = useMemoizedFn(async (values?: ILiveChatMessage) => {
    if (values && !Object.hasOwn(values, 'tables') && !Object.hasOwn(values, 'offers')) {
      onClose();
      form.resetFields();
      return;
    }

    try {
      const values: ILiveChatMessage = await form.validateFields(['to', 'tables', 'message']);
      if (values) {
        values.offers = values.offers?.map((offer) => offer.split('&')[1]) ?? [];
        onConfirm?.(values);
        onClose();
        form.resetFields();
      }
    } catch {
      return;
    }
  });

  return (
    <Modal title={t('send_chat_message_title')} open={show} position="center" onClose={handleModalClose}>
      <Form
        className="padding-content"
        style={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
        }}
        onFinish={handleModalClose}
        form={form}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="to" rules={[{ required: true, message: t('msg_error_send_chat_message_empty_field') }]}>
              <InputSelect
                choices={employees.map(({ firstName, lastName, userId }) => ({
                  title: `${firstName} ${lastName}`,
                  value: userId,
                }))}
                mode="multiple"
                allowClear
                placeholder={t('send_chat_message_recipient_placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              name="tables"
              rules={[{ required: true, message: t('msg_error_send_chat_message_empty_field') }]}
            >
              <InputSelect
                choices={tables.map(({ identifier }) => ({
                  title: identifier,
                  value: identifier,
                }))}
                allowClear
                mode="multiple"
                placeholder={t('send_chat_message_table_placeholder')}
                onChange={handleTableChange}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="offers">
              <InputSelect
                choices={pendingOffers}
                allowClear
                disabled={selectOrdersDisabled}
                mode="multiple"
                placeholder={t('send_chat_message_offer_placeholder')}
                onChange={handleOffersChange}
              />
            </Form.Item>
          </Col>
        </Row>

        {defaultMessages?.length ? (
          <Row>
            <Col span={24}>
              <Form.Item>
                {defaultMessages?.map(({ label, message }, i) => (
                  <CustomMessage key={i} label={label} message={message} onClicked={handleAddMsg} />
                ))}
              </Form.Item>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col span={24}>
            <Form.Item name="message" rules={[{ validator: msgValidator }]}>
              <TextArea rows={4} placeholder={t('send_chat_message_placeholder')}></TextArea>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">
                {t('send_chat_message_btn')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
