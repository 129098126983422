import Axios from "axios";
import BaseRepository, { HttpRequester } from "../base.repository";
import i18n from "i18n";

// INTERFACES
import { IEmployeeListItem } from "./employees";
import { IMenu, IMenuListItem } from "./menu.repository";

class TableRepository extends BaseRepository<
  ITable,
  ITableListItem,
  ITableForm
> {
  constructor(httpRequester: HttpRequester = Axios) {
    super(httpRequester, "admin/tables");
  }

  toForm(table: ITable | null, defaults = {}): ITableForm {
    if (table) {
      const { menus, employees } = table;

      return {
        ...table,
        menus: menus.map(({ _id }) => _id),
        employees: employees.map(({ _id }) => _id),
      };
    } else {
      return {
        isDeliveryTable: false,
        isInfoTable: false,
        identifier: "",
        menus: [],
        employees: [],
        capacity: 1,
        isAvailable: true,
        user: "",
        ...defaults,
      };
    }
  }

  async getMenus(tableId: string): Promise<IMenu[]> {
    try {
      const { data } = await this.httpRequester.get(
        `${this.endpoint}/${tableId}/menus`
      );
      return data;
    } catch (err) {
      throw new Error(i18n.t("msg_error_menu_list"));
    }
  }

  getPendingOrders = (tableId: string) => {
    return this.httpRequester
      .get(`${this.endpoint}/pending-orders/${tableId}`)
      .then(({ data }) => data)
      .catch(console.error);
  };

  generateBill = (
    tableId: string,
    sessionId: string,
    approved: boolean,
    discount = 0,
    includedTip?: number,
    discountNote = "",
    orderMark = 0
  ): Promise<IBill> => {
    return this.httpRequester
      .post(this.endpoint + `/generate-bill/${tableId}`, {
        sessionId: sessionId || "notifyAllTableSessions",
        approved,
        discount,
        discountNote,
        orderMark,
        includedTip,
      })
      .then(({ data }) => data)
      .catch(console.error);
  };
}

export type ITableRepository = TableRepository;

export interface ITableForm {
  _id?: string;
  identifier: string;
  isDeliveryTable: boolean;
  isInfoTable: boolean;
  menus: string[];
  employees?: string[];
  capacity: number;
  isAvailable?: boolean;
  user: string;
}

export interface ITable {
  _id: string;
  identifier: string;
  menus: IMenuListItem[];
  employees: IEmployeeListItem[];
  capacity: number;
  isDeliveryTable: boolean;
  isInfoTable: boolean;
  isAvailable?: boolean;
  user: string;
  qrURL: string;
}

export interface ITableListItem {
  _id: string;
  identifier: string;
  isAvailable?: boolean;
  menus: IMenuListItem[];
  isDeliveryTable: boolean;
  capacity: number;
  employees: IEmployeeListItem[];
  user?: string;
}

export interface IBill {
  id: string;
  totalPrice?: { [key: string]: number };
  totalItems: number;
  items?: { [s: string]: IBillItems };
  companyName?: string;
  logo?: string;
  includedTip?: number;
  companyPhone?: string;
  discount?: number;
  discountNote?: string;
  table?: string;
  subTotalPrice: number;
  paymentsAllowed: string[];
}

export interface IBillItems {
  items?: number;
  totalPrice?: number;
  offerPrice?: number;
  currency?: {
    shortName: string;
  };
}

export default TableRepository;
