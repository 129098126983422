// GLOBAL IMPORTS
import React from 'react'

// LOCAL IMPORTS
import publicRoutes from './public.routes'
import Routes, { IRoutesProps } from '../Routes'

// HOOKS
import { PublicNamespaceContext, usePublic } from 'hooks/public.hook'

const PublicRoutes = ({ layout, notFoundUrl, forbiddenUrl }: IPublicRoutesProps) => {
  const publicContext = usePublic()

  return (
    <PublicNamespaceContext.Provider value={publicContext}>
      <Routes
        layout={layout}
        forbiddenUrl={forbiddenUrl}
        notFoundUrl={notFoundUrl}
        loginUrl={'/thankyou'}
        routes={publicRoutes}
      />
    </PublicNamespaceContext.Provider>
  )
}

export type IPublicRoutesProps = Pick<IRoutesProps, 'notFoundUrl' | 'forbiddenUrl' | 'layout'>

export default PublicRoutes
