import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

// INTERFACES
interface ILoaderProps {
  loading?: boolean
  children: React.ReactNode
}

// STYLED COMPONENT
const LoaderContainer = styled.div`
  display: flex;
  flex: 1 !important;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
`
// FUNCTIONAL COMPONENTS
const Loader = ({ children: Children, loading }: ILoaderProps) => {
  return (
    <LoaderContainer className="loader-container">
      <Spin spinning={!!loading} wrapperClassName="loader">
        {Children}
      </Spin>
    </LoaderContainer>
  )
}

export default Loader
