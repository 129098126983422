import { HttpRequester } from "models/base.repository";
import { IClientOrder } from "models/public/orders";
import { IMenu } from "models/admin/menu.repository";
import { ITableData, ITableExtra } from "./types";
import Axios from "axios";

class TableRepository {
  public endpoint: string;

  constructor(public httpRequester: HttpRequester = Axios) {
    this.endpoint = "/tables";
  }

  async login(tableName: string): Promise<ITableData> {
    const { data } = await this.httpRequester.get(
      `${this.endpoint}/${tableName}`
    );
    return data;
  }

  async getMenu(tableId: string): Promise<IMenu[]> {
    return this.httpRequester
      .get(`${this.endpoint}/${tableId}/menus`)
      .then(({ data }: { data: IMenu[] }) => data);
  }

  getMenuById = async (menuId: string): Promise<IMenu> => {
    return this.httpRequester
      .get(`menus/${menuId}`)
      .then(({ data }: { data: IMenu }) => data);
  };

  async getOrders(tableId: string): Promise<IClientOrder[]> {
    try {
      const { data: orders } = await this.httpRequester.get(
        `${this.endpoint}/${tableId}/orders`
      );
      return orders;
    } catch (err) {
      throw new Error(`Error getting orders for table ${tableId}`);
    }
  }

  checkCanOrder = async (
    sessionId: string
  ): Promise<{ orderMark: number | null; canOrder: boolean }> => {
    return this.httpRequester
      .get(`sessions/${sessionId}/can-order`)
      .then(({ data }) => data);
  };

  async getExtraData(_tableId?: string): Promise<ITableExtra> {
    return this.httpRequester
      .get(`${this.endpoint}/extra`)
      .then(({ data }: { data: ITableExtra }) => data);
  }
}

export default TableRepository;
