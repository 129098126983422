// REACT
import React, { useCallback, useMemo } from "react";
// GLOBAL IMPORTS
import styled from "styled-components";
// LOCAL IMPORTS
import {
  EOrderOfferStatus,
  EOrderStatus,
  IOrderOfferItem,
  TOrderOfferStatus,
} from "../../../models/admin/orders";
import { ERoles } from "../../../types";
import { Icons, ListItem } from "../../common";
import {
  IOrderOfferStatusResponse,
  OrderOfferStatusPopover,
} from "./OrderOfferStatus";
import { useAuth } from "hooks/auth.hook";
import { useTranslation } from "react-i18next";
import OrderOfferItem from "./OrderOfferItem";

const { IconInProgress, IconDelivered, IconRejected } = Icons;

const offerStatusIconSize = "1.5em";

const OrderOfferListItem = ({
  item,
  actions,
  onChange,
  billApproved,
  orderStatus,
}: IOrderOfferListItemProps) => {
  // STATE
  const { offer, note, amount, pending, delivered, rejected } = item;
  const { name } = offer;
  const { user } = useAuth() || {};

  const handleChangeStatus = useMemo(() => {
    return onChange
      ? (response: IOrderOfferStatusResponse) => {
          onChange(response);
        }
      : undefined;
  }, [onChange]);

  const renderCounterItem = useCallback(
    (
      icon: React.ReactNode,
      title: string,
      field: TOrderOfferStatus,
      count?: number,
      showPopover = true
    ) => {
      if (!showPopover) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <OrderOfferStatusPopover
              icon={icon}
              title={title}
              field={field}
              count={count}
            />
          </div>
        );
      }
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <OrderOfferStatusPopover
            icon={icon}
            title={title}
            field={field}
            count={count}
            onSubmit={handleChangeStatus}
          />
        </div>
      );
    },
    [handleChangeStatus]
  );
  const { t } = useTranslation();

  const counter = useMemo(() => {
    const showPopover =
      !(billApproved && !user?.roles?.includes(ERoles.ADMIN))  &&
      orderStatus != EOrderStatus.PAID &&
      orderStatus != EOrderStatus.REJECTED;
    return (
      <CounterContainer>
        {renderCounterItem(
          <IconInProgress size={offerStatusIconSize} />,
          t("status_pending"),
          EOrderOfferStatus.PENDING,
          pending !== undefined ? pending : amount,
          showPopover
        )}
        {renderCounterItem(
          <IconDelivered size={28} />,
          t("status_delivered"),
          EOrderOfferStatus.DELIVERED,
          delivered !== undefined ? delivered : 0,
          showPopover
        )}
        {renderCounterItem(
          <IconRejected size={offerStatusIconSize} />,
          t("status_rejected"),
          EOrderOfferStatus.REJECTED,
          rejected !== undefined ? rejected : 0,
          showPopover
        )}
      </CounterContainer>
    );
  }, [
    amount,
    delivered,
    pending,
    rejected,
    renderCounterItem,
    billApproved,
    user,
    t,
    orderStatus,
  ]);

  return (
    <ListItem>
      <OrderOfferItem
        name={name}
        counter={actions || counter}
        summary={undefined}
        notes={note}
      />
    </ListItem>
  );
};

const CounterContainer = styled.div`
  display: flex;
  & > :not(:first-child) {
    margin-left: 1em;
  }
`;
export interface IOrderOfferListItemProps {
  item: IOrderOfferItem;
  actions?: React.ReactNode;
  onClick?: () => unknown;
  onChange?: (countResponse: IOrderOfferStatusResponse) => unknown;
  billApproved?: boolean;
  groupByPending?: boolean;
  orderStatus?: EOrderStatus;
}

export type { IOrderOfferStatusResponse };

export default React.memo(OrderOfferListItem);
