// REACT
import React from 'react'
// GLOBAL
import { TooltipPlacement } from 'antd/lib/tooltip'
import styled from 'styled-components'
// LOCAL
import Tooltip from './Tooltip'

const UnitSpan = styled.span`
  font-size: 0.8em;
`

const AmountSpan = styled.span`
  font-size: 1.4em;
  font-weight: 600;
`

const PrefixSpan = styled.span``

const CountTagWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  & > :not(:first-child) {
    margin-left: 0.2em;
  }
  font-weight: 600;
`

const CountTag = ({ amount, unit, prefix, help, helpPlacement, ...props }: ICountProps) => {
  return (
    <Tooltip title={help} placement={helpPlacement}>
      <CountTagWrapper className="text-primary" {...props}>
        {prefix ? <PrefixSpan>{prefix}</PrefixSpan> : null}
        <AmountSpan className="--counter-amount">{amount}</AmountSpan>
        {unit ? <UnitSpan className="--counter-unit">{unit}</UnitSpan> : null}
      </CountTagWrapper>
    </Tooltip>
  )
}

export interface ICountProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'prefix'> {
  amount: number | string
  unit?: string
  prefix?: React.ReactNode
  help?: string
  helpPlacement?: TooltipPlacement
}

export default CountTag
