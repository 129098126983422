import { ArgsProps } from "antd/lib/notification";
import { notification } from "antd";

const NOTIFICATION_PLACEMENT = "topRight";

notification.config({
  placement: NOTIFICATION_PLACEMENT,
  duration: 3,
});

const { success, error, warning, info, open } = notification;

type Args = ArgsProps;


export function showSuccess(args: Args) {
  success(args);
}

export function showError(args: Args) {
  error(args);
}

export function showWarning(args: Args) {
  warning(args);
}

export function showInfo(args: Args) {
  info(args);
}

export function showNotification(args: Args) {
  open(args);
}
