// REACT
import React, { useCallback, useMemo } from 'react'

// GLOBAL IMPORTS
import debounce from 'lodash.debounce'
import styled from 'styled-components'
// LOCAL IMPORTS
import { ButtonAdd } from '../common/Buttons'
import { InputSearch } from '../common/Inputs'
import ActionBar from './ActionBar'
import ViewLayout from './ViewLayout'

// HOOKS
import { useSearch } from 'hooks/search.hook'

// FUNCTIONAL COMPONENTS
const ActionBarList = ({ onAdd }: IActionBarListProps) => {
  const handleAdd = () => {
    if (onAdd) {
      onAdd()
    }
  }
  return (
    <ActionBar>
      <ButtonAdd type="primary" onClick={handleAdd} />
    </ActionBar>
  )
}

const ViewList = <T,>({
  items,
  children,
  searchable,
  actions,
  messageBtn,
  onAdd,
  loading,
}: IViewListProps<T>) => {
  // HOOKS
  const { search, searchQuery, setSearchQuery } = useSearch<T>()
  // STATE
  const listChildren = useMemo(() => React.Children.toArray(children) || [], [children])

  const firstChildrenElement = useMemo(() => (listChildren.length ? listChildren[0] : null), [
    listChildren,
  ])

  const ChildrenClone = useMemo(() => {
    if (!firstChildrenElement) {
      return null
    }
    return React.cloneElement(firstChildrenElement as React.ReactElement<any>, {
      items: items && search(searchQuery, items),
    })
  }, [firstChildrenElement, items, search, searchQuery])

  const debouncedSearch = useMemo(
    () =>
      debounce((query = '') => {
        setSearchQuery(query)
      }, 500),
    [setSearchQuery],
  )

  const handleSearchQueryChange = useCallback(
    (inputEvent: React.ChangeEvent<HTMLInputElement>) => {
      inputEvent.persist()

      debouncedSearch(inputEvent?.target?.value)
    },
    [debouncedSearch],
  )

  const TopBar = useMemo(
    () =>
      searchable || (actions && actions.length) ? (
        <TopBarContainer>
          <TopBarActionContainer>
            {messageBtn ? <ActionContainer visible={!!messageBtn}>{messageBtn}</ActionContainer> : null}
            <SearchContainer visible={searchable}>
              <InputSearch onChange={handleSearchQueryChange} />
            </SearchContainer>
            <ActionContainer visible={!!actions?.length}>{actions?.map((action) => action)}</ActionContainer>
          </TopBarActionContainer>
        </TopBarContainer>
      ) : null,
    [actions, messageBtn, handleSearchQueryChange, searchable]
  );

  const ActionBar = useMemo(() => (onAdd ? <ActionBarList onAdd={onAdd} /> : null), [onAdd])

  return (
    <ViewLayout loading={loading} topBar={TopBar} actionBar={ActionBar}>
      {ChildrenClone}
    </ViewLayout>
  )
}

const TopBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`
const SearchContainer = styled.div<{ visible: boolean | undefined }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-grow: 1;
`

const ActionContainer = styled.div<{ visible?: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-shrink: 1;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`

const TopBarActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`

// INTERFACES
interface IViewListProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  items?: T[];
  searchable?: boolean;
  actions?: React.ReactNode[];
  messageBtn?: React.ReactNode;
  onAdd?: () => void;
  loading?: boolean;
}

// ACTION BAR LIST
interface IActionBarListProps {
  onAdd: () => void
}

export default ViewList
