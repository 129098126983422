// GLOBAL IMPORTS
import { Layout } from 'antd'
import React, { useMemo } from 'react'
import styled from 'styled-components'

// LOCAL IMPORTS
import { INavItem, NavMenu } from '../common/nav'
import { ViewLayout } from '../view-layout'
import AppDrawer from './AppDrawer'

// CUSTOM HOOKS
import { Devices, useDevice } from '../../hooks/device.hook'
import { useViewport } from 'hooks/viewport.hook'
const { Sider } = Layout

// CONSTANTS
const AppSidebarContainer = styled.div`
  overflow: hidden;
  box-shadow: 0 2px 8px #f0f1f2;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex: 1 !important;
  &:hover {
    overflow: auto;
  }
`

const AppSidebar = ({
  title,
  navItems,
  theme = 'light',
  width = 300,
  visible,
  header,
  footer,
  activeNavItem,
  hideOnDevices = [Devices.TABLET, Devices.MOBILE],
  onClose,
  onClickItem,
}: IAppSidebarProps) => {
  const device = useDevice()
  const [fullHeight] = useViewport()

  const handleClickItem = useMemo(() => {
    return onClickItem
      ? (id: string) => {
          if (onClose) {
            onClose()
          }
          onClickItem(id)
        }
      : undefined
  }, [onClickItem, onClose])

  const siderStyle = useMemo(() => {
    return {
      height: fullHeight,
      display: 'flex',
      justifyContent: 'stretch',
      alignItems: 'stretch',
    }
  }, [fullHeight])

  const SidebarContent = useMemo(
    () => (
      <AppSidebarContainer>
        <ViewLayout topBar={header} actionBar={footer}>
          <NavMenu onClickLink={handleClickItem} navItems={navItems || []} active={activeNavItem} />
        </ViewLayout>
      </AppSidebarContainer>
    ),
    [activeNavItem, footer, handleClickItem, header, navItems],
  )

  return hideOnDevices.includes(device) ? (
    <AppDrawer title={title} position="right" visible={visible} width={width} onClose={onClose}>
      {SidebarContent}
    </AppDrawer>
  ) : (
    <Sider theme={theme} width={width} style={siderStyle}>
      {SidebarContent}
    </Sider>
  )
}

export type SidebarPosition = 'left' | 'right'
export type SidebarMode = 'fixed' | 'drawer'

export interface IAppSidebarProps {
  title?: string
  theme?: 'light' | 'dark'
  visible?: boolean
  width?: string | number
  position?: SidebarPosition
  mode?: SidebarMode
  navItems?: INavItem[]
  footer?: React.ReactNode
  header?: React.ReactNode
  hideOnDevices?: Devices[]
  activeNavItem?: string | null
  onClose?: () => unknown
  onClickItem?: (id: string) => unknown
}

export interface ISidebarContentProps {
  navItems: INavItem[]
  onClickLink: () => unknown
}

export type { INavItem }

export default AppSidebar
