// REACT
import React from 'react'

// GLOBAL
import { Button as ADButton } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'

// LOCAL
import Tooltip, { TooltipPlacement } from './Tooltip'

export const PushButton = ({
  children,
  iconOnly,
  help,
  helpPlacement,
  ...props
}: IPushButtonProps): JSX.Element => {
  const buttonContent = iconOnly ? null : children

  const button = <ADButton {...props}>{buttonContent}</ADButton>
  return help ? (
    <Tooltip title={help} placement={helpPlacement}>
      {button}
    </Tooltip>
  ) : (
    button
  )
}

export interface IPushButtonProps extends ButtonProps {
  children?: React.ReactNode
  iconOnly?: boolean
  help?: string
  helpPlacement?: TooltipPlacement
}

export default PushButton
