// REACT
import React from 'react'

// GLOBAL IMPORTS
import { Tabs as ADTabs } from 'antd'
import { TabPaneProps, TabsProps } from 'antd/lib/tabs'

const { TabPane: ADTabPane } = ADTabs

export const Tab = ({ ...props }: ITabsProps) => {
  return <ADTabs {...props} />
}
export const TabPane = ({ ...props }) => {
  return <ADTabPane {...props} />
}

export type ITabPaneProps = TabPaneProps

export type ITabsProps = TabsProps
