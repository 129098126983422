import { FixedSizeList, ListChildComponentProps, VariableSizeList } from 'react-window'
import { InfiniteList } from './InfiniteScrollList'
import { Tabs } from 'antd'
import AutoSizer from 'react-virtualized-auto-sizer'
import React, { useCallback, useMemo } from 'react'

const { TabPane } = Tabs

interface IVirtualList {
  itemSize?: number
  items?: any[]
  renderItem: TRenderItem
  bottomOffset?: number
  sortAsc?: boolean
  onTabChange?(item: string): void
  getItemSize?(item: any): number
  groupBy?(items: any[]): { [key: string]: number[] }
}

/**
 * ## Examples:
 *
 *  *** to use a fixed size list: ***
 * ```
 * <VirtualList renderItem={renderItem} itemSize={140} />
 * ```
 *
 * *** to use a dynamic size list: ***
 * ```
 * <VirtualList renderItem={renderItem} getItemSize={getItemSize} />
 * ```
 */
export const VirtualList = React.memo((props: IVirtualList) => {
  // <!-- PROPS -->
  const {
    itemSize,
    getItemSize,
    renderItem,
    sortAsc = false,
    items = [],
    groupBy,
    bottomOffset,
    onTabChange,
  } = props

  // <!-- CACHED LIST && INVALIDATION UTILITY -->
  const renderStore = useMemo(() => {
    function refreshRender(index: number) {
      renderStore[index] = renderItem(items[index], index, refreshRender)
    }

    return items.map((item, index) => renderItem(item, index, refreshRender))
  }, [items, renderItem])

  // <!-- RENDER LIST ITEM POSITION -->
  const _renderItem = useCallback(
    ({ style, index }: ListChildComponentProps) => {
      if (!itemSize && !getItemSize) {
        return renderStore[index]
      }

      return (
        <div key={items[index]?._id || index} style={style}>
          {renderStore[index]}
        </div>
      )
    },
    [renderStore, items, itemSize, getItemSize],
  )

  // <!-- RER -->
  const _getItemSize = useCallback(
    (index: number) => {
      return getItemSize?.(items[index]) || 0
    },
    [items, getItemSize],
  )

  const groups = useMemo(() => {
    if (!groupBy) {
      return {}
    }
    return groupBy(items)
  }, [groupBy, items])

  const handleTabClick = useCallback(
    (index: string) => {
      onTabChange?.(Object.keys(groups)[+index])
    },
    [groups, onTabChange],
  )

  const tableSort = (a: Array<any>, b: Array<any>) => {
    if (sortAsc) {
      if (a[0] > b[0]) return 1
      if (a[0] < b[0]) return -1

      return 0
    }

    return 0
  }

  // <!-- Render Grouped List -->
  if (groupBy) {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Tabs
            defaultActiveKey="0"
            style={{ width, height, margin: '0 16px' }}
            onTabClick={handleTabClick}
          >
            {Object.entries(groups)
              .sort(tableSort)
              .map(([name, indexes], i) => {
                const renderGroupedItem = ({ style, index }: ListChildComponentProps) => {
                  return (
                    <div key={items[indexes[index]]?._id || indexes[index]} style={style}>
                      {renderStore[indexes[index]]}
                    </div>
                  )
                }

                const infiniteListItems = indexes.map((_v, i) => {
                  return { index: i, style: {} }
                })

                return (
                  <TabPane
                    tab={name}
                    key={i}
                    style={{ width, height: height - 46, margin: '-16px' }}
                  >
                    {
                      // <!-- Render Fixed Size List -->
                      (itemSize && (
                        <FixedList
                          itemsCount={indexes.length}
                          renderItem={renderGroupedItem}
                          itemsSize={itemSize}
                        />
                      )) ||
                        // <!-- Render Variable Size List -->
                        (getItemSize && (
                          <VariableList
                            itemsCount={indexes.length}
                            renderItem={renderGroupedItem}
                            getItemSize={_getItemSize}
                          />
                        )) || (
                          // <!-- Render an Infinite List -->
                          <InfiniteList
                            renderItem={renderGroupedItem}
                            items={infiniteListItems}
                            bottomOffset={bottomOffset}
                          />
                        )
                    }
                  </TabPane>
                )
              })}
          </Tabs>
        )}
      </AutoSizer>
    )
  }

  // <!-- Render Fixed Size List -->
  if (itemSize) {
    return <FixedList itemsCount={items.length} renderItem={_renderItem} itemsSize={itemSize} />
  }

  // <!-- Render Variable Size List -->
  if (getItemSize) {
    return (
      <VariableList itemsCount={items.length} renderItem={_renderItem} getItemSize={_getItemSize} />
    )
  }

  // <!-- Render an Infinite List -->
  return (
    <InfiniteList
      renderItem={_renderItem}
      items={items.map((_v, i) => ({ index: i, style: {} }))}
    />
  )
})

interface FixedListProps {
  itemsCount: number
  itemsSize: number
  renderItem: ({ style, index }: ListChildComponentProps) => JSX.Element
}

const FixedList = React.memo((props: FixedListProps) => (
  <AutoSizer>
    {({ height, width }) => {
      return (
        <FixedSizeList
          height={height}
          itemCount={props.itemsCount}
          itemSize={props.itemsSize}
          width={width}
        >
          {props.renderItem}
        </FixedSizeList>
      )
    }}
  </AutoSizer>
))

interface VariableListProps {
  itemsCount: number
  getItemSize: (index: number) => number
  renderItem: ({ style, index }: ListChildComponentProps) => JSX.Element
}

const VariableList = React.memo((props: VariableListProps) => (
  <AutoSizer>
    {({ height, width }) => (
      <VariableSizeList
        height={height}
        itemCount={props.itemsCount}
        itemSize={props.getItemSize}
        width={width}
      >
        {props.renderItem}
      </VariableSizeList>
    )}
  </AutoSizer>
))

export type TRenderItem = (
  item: any,
  index: number,
  refreshRender: (index: number) => void,
) => JSX.Element
