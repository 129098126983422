import Axios from "axios";
import BaseRepository, { HttpRequester } from "../../base.repository";

class StripeRepository extends BaseRepository {
  constructor(httpRequester: HttpRequester = Axios) {
    super(httpRequester, "stripe/");
  }

  generateCheckout(billId = "") {
    return this.httpRequester
      .post(`${this.endpoint}create-checkout`, {
        billId,
      })
      .then(({ data }) => data)
      .catch(console.error);
  }
}

export default StripeRepository;
