import { HttpRequester } from "./http.interface";

class RestApiCRUD<
  I = any,
  LI = I,
  IF = I,
  R extends HttpRequester = HttpRequester
> {
  _httpRequester: R;
  endpoint: string;
  _settings: IRestApiCrudSettings;

  get httpRequester(): R {
    return this._httpRequester;
  }

  get settings() {
    return this._settings;
  }

  constructor(
    httpRequester: R,
    endpoint = "",
    settings: IRestApiCrudSettings = {
      list: true,
      get: true,
      insert: true,
      update: true,
      partialUpdate: true,
      delete: true,
    }
  ) {
    this._httpRequester = httpRequester;
    this.endpoint = endpoint;
    this._settings = settings;
  }

  get baseUrl() {
    return this._httpRequester.defaults.baseURL;
  }

  async list(filters: Record<string, string> | undefined = {}): Promise<LI[]> {
    if (this.settings.list === false) {
      throw new Error("Method not allowed");
    }
    const { data }: { data: LI[] } = await this._httpRequester.get(
      this.endpoint,
      {
        params: filters,
      }
    );
    return data;
  }

  async get(id: string): Promise<I> {
    if (this.settings.get === false) {
      throw new Error("Method not allowed");
    }
    const { data }: { data: I } = await this._httpRequester.get(
      `${this.endpoint}/${id}`
    );
    return data;
  }

  async insert(newItemData: IF): Promise<I> {
    if (this.settings.insert === false) {
      throw new Error("Method not allowed");
    }
    const { data }: { data: I } = await this._httpRequester.post(
      `${this.endpoint}`,
      newItemData
    );
    return data;
  }

  async update(id: string, itemData: IF, partial = false): Promise<I> {
    if (
      this.settings.update === false ||
      (partial === true && this.settings.partialUpdate === false)
    ) {
      throw new Error("Method not allowed");
    }
    const { data }: { data: I } = await this._httpRequester[
      partial ? "patch" : "put"
    ](`${this.endpoint}/${id}`, itemData);

    return data;
  }

  async patch(id: string, itemData: IF): Promise<I> {
    return this.update(id, itemData, true);
  }

  async delete(id: string): Promise<void> {
    if (this.settings.delete === false) {
      throw new Error("Method not allowed");
    }
    await this._httpRequester.delete(`${this.endpoint}/${id}`);
  }
}

export interface IRestApiCrudSettings {
  list?: boolean;
  get?: boolean;
  insert?: boolean;
  update?: boolean;
  partialUpdate?: boolean;
  delete?: boolean;
}
export default RestApiCRUD;
