import { useState } from 'react'

export const useSearch = <T = any>() => {
  const [searchQuery, setSearchQuery] = useState<string>('')

  const search = (searchQuery?: string, items?: T[]): T[] => {
    if (searchQuery && items) {
      // Update matching locations with those matching the searchQuery
      let matches = items
      const searchTerms = searchQuery.toLowerCase().split(' ')

      searchTerms.forEach((searchTerm) => {
        matches = matches.filter((item) => {
          const itemAsString: string =
            typeof item === 'object'
              ? JSON.stringify(Object.values(item)).toLowerCase()
              : `${item}`.toLowerCase() // Convert Item into string for making a full search
          return itemAsString.includes(searchTerm)
        })
      })
      return [...matches]
    } else {
      // If searchQuery is empty return the full items array
      return items || []
    }
  }
  return {
    search,
    searchQuery,
    setSearchQuery,
  }
}
