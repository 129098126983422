// GLOBAL IMPORTS
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import React from 'react'

// LOCAL IMPORTS
import { concatPaths, useRoutes } from '../hooks/route.hook'
import { IRoute } from './types'

// Make path relative to router
const formatRoute = (baseUrl: string, route: IRoute): IRoute => {
  return {
    ...route,
    path: concatPaths(baseUrl, route.path),
  }
}
const formatRoutes = (baseUrl: string, routes: IRoute[]): IRoute[] => {
  return routes.map((route) => formatRoute(baseUrl, route))
}

const Routes = ({
  routes,
  forbiddenUrl = '/security/forbidden',
  loginUrl = '/security/login',
  notFoundUrl = '/not-found',
  layout: Layout,
}: IRoutesProps) => {
  const { path } = useRouteMatch()

  const { authorizedRoutes, deniedRoutes, securedRoutes, activeRoute } = useRoutes(
    formatRoutes(path, routes),
  )

  return (
    <Layout
      authorizedRoutes={authorizedRoutes}
      deniedRoutes={deniedRoutes}
      activeRoute={activeRoute}
    >
      <Switch>
        {/* SHOULD LOGIN */}
        {securedRoutes.map(({ id, path }) => (
          <Route key={id} path={path}>
            <Redirect key={id} to={loginUrl} />
          </Route>
        ))}

        {/* DENIED */}
        {deniedRoutes.map(({ id, path }) => (
          <Route key={id} path={path}>
            <Redirect key={id} to={forbiddenUrl} />
          </Route>
        ))}

        {/* AUTHORIZED */}
        {authorizedRoutes.map(({ id, path, page }) => (
          <Route key={id} path={path} component={page} exact />
        ))}

        <Route path="*">
          <Redirect to={notFoundUrl} />
        </Route>
      </Switch>
    </Layout>
  )
}

export interface IRoutesProps {
  routes: IRoute[]
  notFoundUrl?: string
  forbiddenUrl?: string
  loginUrl?: string
  layout: (props: {
    children?: React.ReactNode
    authorizedRoutes: IRoute[]
    deniedRoutes: IRoute[]
    activeRoute?: IRoute
  }) => JSX.Element
}

export default Routes
