import { Button, notification, Row } from "antd";
import { CommandCenterData } from "global";
import { IBill } from "models/admin/table.repository";
import { IOrder } from "models/admin/orders";
import { IOrderTicket } from "models/public/orders/types";
import { ISMessage, useSocketConfirmation } from "./socket.storage";
import { PaymentBill } from "components/payment/PaymentBill";
import {
  showInfo,
  showNotification,
  showWarning,
} from "components/common/Notification";
import { useAuth } from "hooks/auth.hook";
import { usePointer } from "hooks/state.hook";
import { useTranslation } from "react-i18next";
import CustomerRepository from "models/public/customer";
import React, { useEffect, useGlobal, useMemo } from "reactn";
import { handleCheckout } from "models/public/stripe/stripe";
import { BillHasDeleted } from "components/payment/BillHasDeleted";
// import { ActionBar } from "components/view-layout";
// import { ButtonRequestPayment } from "components/common/Buttons";
// //import { useCallback } from "react";
// import i18n from "i18n";

const customerRepo = new CustomerRepository();

export enum EOrderScope {
  FULL = "full",
  PARTIAL = "partial",
}

export function NotificationStorage() {
  const [socket] = useGlobal("socket");
  const setNotified = useGlobal("notified")[1];
  const setCmdData = useGlobal("commandCenter")[1];
  const setCanOrder = useGlobal("canOrder")[1];
  const { user, setUser, setRefreshToken } = useAuth() || {};

  const sendConfirmation = useSocketConfirmation(socket);
  const $sessionId = usePointer(user?.sessionId || "");
  const $setUser = usePointer(setUser);
  const $setRefreshToken = usePointer(setRefreshToken);
  //LANGUAGE TRANSLATION
  const { t } = useTranslation();

  const listeners = useMemo(
    () => ({
      pong1(data: string) {
        console.info(data);
      },

      callWaitress(data: ISMessage<any>) {
        sendConfirmation(data.messageId);
        setNotified(true);
        showWarning({
          message: t("msg_table_calling_waitress", {
            id: data.table.identifier,
          }),
          duration: 0,
        });
      },

      liveChat(data: ISMessage<string>) {
        sendConfirmation(data.messageId);
        setNotified(true);
        const [tables, msg] = data.message.split('\n\n');
        const tableParts = tables.split(/\n/g)
        showInfo({
          message: t("send_chat_message_title"),
          description: (
            <>
              {tableParts.reduce((texts, text, i) => {
                texts.push(text, <br key={i} />);
                return texts;
              }, [] as React.ReactNode[])}
              <strong>{msg}</strong>
            </>
          ),
          duration: 0,
        });
      },

      orderTicket({ messageId, message }: ISMessage<IOrderTicket>) {
        sendConfirmation(messageId);
        setNotified(true);

        showOrderTicketResponse(message, t);
      },

      newOrder({ message: order, messageId }: ISMessage<IOrder>) {
        sendConfirmation(messageId);
        console.log("newOrder");
        const message = t("newOrderNot", {
          identifier: order.table.identifier,
        });
        showInfo({ message });
      },

      billPayment(props: ISMessage<string>) {
        sendConfirmation(props.messageId);
        setNotified(true).then();
        const key = `open${Date.now()}`;
        const btn = (
          <Button type="primary" size="small">
            {t("process")}
          </Button>
        );
        const message = t("msg_table_requesting_bill", {
          id: props.table.identifier,
        });
        showWarning({
          key,
          btn,
          style: { cursor: "pointer" },
          message,
          duration: 0,
          onClick: () => {
            setCmdData({ note: props, billType: "full" }).then();
            notification.close(key);
          },
        });
      },

      partialBillPayment(props: ISMessage<string>) {
        sendConfirmation(props.messageId);
        setNotified(true).then();
        const btn = (
          <Button type="primary" size="small">
            {t("process")}
          </Button>
        );
        const key = `open${Date.now()}`;
        const message = t("msg_client_from_table_requestin_bill", {
          cId: props.clientNumber,
          id: props.table?.identifier,
        });
        showWarning({
          key,
          btn,
          style: { cursor: "pointer" },
          message: message,
          duration: 0,
          onClick: () => {
            setCmdData({
              note: props,
              billType: "partial",
            } as CommandCenterData).then();
            notification.close(key);
          },
        });
      },

      billResponse({ message, messageId }: ISMessage<IBill>) {
        sendConfirmation(messageId);
        setNotified(true).then();
        showBillResponse(message);
      },

      canOrderResponse({
        message,
        messageId,
      }: ISMessage<{ canOrder: boolean; orderMark: number | null }>) {
        const _message = message.canOrder
          ? t("successOrderPermissionResp")
          : t("deniedOrderPermissionResp");

        sendConfirmation(messageId);
        setNotified(true).then();

        setCanOrder(message).then();
        showInfo({
          message: _message,
        });
      },

      permissionToOrder(res: ISMessage<string>) {
        sendConfirmation(res.messageId);
        setNotified(true).then();
        const key = `open${Date.now()}`;
        const btn = (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                notification.close(key);
                customerRepo
                  .allowToOrder(res.sessionId, true)
                  .catch(console.error);
              }}
            >
              {t("approveTxt")}
            </Button>{" "}
            <Button
              type="primary"
              size="small"
              onClick={() => {
                notification.close(key);
                customerRepo
                  .allowToOrder(res.sessionId, false)
                  .catch(console.error);
              }}
            >
              {t("deniedTxt")}
            </Button>
          </>
        );
        console.log("newOrder");
        showWarning({
          message: t("msg_table_permission_to_order", {
            id: res.table.identifier,
          }),
          btn,
          key,
          duration: 0,
        });
      },

      orderStats(data: ISMessage<unknown>) {
        sendConfirmation(data.messageId);
      },

      orderStatusUpdated(data: ISMessage<unknown>) {
        sendConfirmation(data.messageId);
      },

      orderOfferStatusUpdated(data: ISMessage<unknown>) {
        sendConfirmation(data.messageId);
      },

      updateTableId({
        message: { refreshToken, tableId, token },
        messageId,
      }: ISMessage<{
        tableId: string;
        token: string;
        refreshToken: string;
      }>) {
        sendConfirmation(messageId);

        const data = {
          sessionId: $sessionId.current,
          token,
          tableId,
          permissions: {
            tableMenus: ["read"],
            tableOrders: ["read"],
            tableOffers: ["read", "write"],
          },
          refreshToken,
          expTimeStamp: Date.now(),
        };
        $setUser.current?.(data);
        $setRefreshToken.current?.(refreshToken);
      },

      billApproved(data: ISMessage<unknown>) {
        sendConfirmation(data.messageId);
      },

      offerMinimalExistence({
        messageId,
        message,
      }: ISMessage<{ offerName: string; amount: number }>) {
        sendConfirmation(messageId);
        showWarning({
          message: t("minimalExistence", {
            amount: message.amount,
            offerName: message.offerName,
          }),
          duration: 0,
        });
      },

      offerAlertAfter({
        messageId,
        message,
      }: ISMessage<{ offerName: string; amount: number }>) {
        sendConfirmation(messageId);
        showWarning({
          message: t("alertAfter", {
            amount: message.amount,
            offerName: message.offerName,
          }),
          duration: 0,
        });
      },

      offerNonExistent({
        messageId,
        message,
      }: ISMessage<{ offerName: string }>) {
        sendConfirmation(messageId);
        showWarning({
          message: t("nonExistent", { offerName: message.offerName }),
          duration: 0,
        });
      },

      orderNoTotalOffers({
        messageId,
        message,
      }: ISMessage<{ offer: string; amount: number }[]>) {
        sendConfirmation(messageId);
        showWarning({
          message: (
            <>
              {t("noTotalOffers")}
              {message.map((item, index) => (
                <div key={index}>
                  <span>{item.offer}: </span>
                  {item.amount}
                </div>
              ))}
            </>
          ),
          duration: 0,
        });
      },
    }),
    [
      setNotified,
      setCmdData,
      sendConfirmation,
      setCanOrder,
      $sessionId,
      $setRefreshToken,
      $setUser,
      t,
    ]
  );

  useEffect(() => {
    if (socket) {
      Object.entries(listeners).forEach(([listener, controller]) => {
        socket.on(listener, controller);
      });

      return () => {
        Object.entries(listeners).forEach(([listeners, controller]) => {
          socket.off(listeners, controller);
        });
      };
    }
  }, [socket, listeners]);

  return null;
}

export function showBillResponse(bill: IBill) {
  const stripe = bill && bill.paymentsAllowed.includes("stripe");

  {
    bill
      ? showNotification({
          message: (
            <>
              <div style={{ marginTop: "-16px" }}>
                <PaymentBill bill={bill} discount={null} />
              </div>
              {stripe ? (
                <div style={{ width: "100%", marginTop: "0.5em" }}>
                  <Row justify="end">
                    <Button
                      type="primary"
                      onClick={() => handleCheckout(bill.id)}
                    >
                      {"Stripe"}
                    </Button>
                  </Row>
                </div>
              ) : null}
            </>
          ),
          duration: 0,
          style: {
            width: "auto",
            padding: "24px 0 24px 24px",
            minWidth: 304,
          },
        })
      : showNotification({
          message: (
            <>
              <BillHasDeleted />
            </>
          ),
          duration: 5,
        });
  }
}

export function showOrderTicketResponse(
  { email, orderShortId, phone, bill }: IOrderTicket,
  t: any
) {
  showInfo({
    message: (
      <span>
        <h4>
          <b>
            {t("order_ticket")}:{" "}
            <span className="text-primary">{orderShortId?.toUpperCase()}</span>
          </b>
        </h4>
        <h4>
          <b>
            {t("priceTxt")}:{" "}
            <span className="text-primary">
              {Object.entries(bill.totalPrice || {}).map(
                ([currency, amount], index) => {
                  return (
                    <div key={index}>
                      {amount.toFixed(2)} {currency}
                    </div>
                  );
                }
              )}
            </span>
          </b>
        </h4>
        {Object.keys(bill.deliveryPrice || {}).length ? (
          <h4>
            <b>
              {t("delivery_service")}:{" "}
              <span className="text-primary">
                {Object.entries(bill.deliveryPrice || {}).map(
                  ([currency, amount], index) => {
                    return (
                      <div key={index}>
                        {amount.toFixed(2)} {currency}
                      </div>
                    );
                  }
                )}
              </span>
            </b>
          </h4>
        ) : null}
        {email || phone ? (
          <div>
            <br />
            <h4>{t("contact")}:</h4>

            {email ? (
              <div>
                {t("email")}: <a href={`mailto:${email}`}>{email}</a>
              </div>
            ) : null}

            {phone ? (
              <div>
                {t("phone")}: <a href={`tel:${phone}`}>{phone}</a>
              </div>
            ) : null}
          </div>
        ) : null}
      </span>
    ),
    duration: 0,
  });
}
