// GLOBAL IMPORTS
import React, { useLayoutEffect, useState } from 'react'

// LOCAL IMPORTS
import CountTag from './CountTag'

const TimeCounter = ({ initialDate, updateInterval = 60000, unit = 'min' }: ITimeCounterProps) => {
  const [time, setTime] = useState<number>()

  const getTimeInProgress = () => {
    return (new Date().getTime() - new Date(initialDate).getTime()) / updateInterval
  }

  useLayoutEffect(() => {
    setTime(getTimeInProgress())

    const interval = setInterval(() => {
      setTime(getTimeInProgress())
    }, updateInterval)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, updateInterval])

  return <CountTag amount={(time || 0).toFixed(0)} unit={unit} />
}

export interface ITimeCounterProps {
  initialDate: string
  updateInterval?: number
  unit?: string
}
export default TimeCounter
