import { ActionButton } from '../notifications/NotificationAlert'
import { ActionConfirmation } from 'components/common/ActionConfirmation'
import { ButtonStatusUpdate } from 'components/common/Buttons'
import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import React from 'react'

export interface OrderStatusCtrlProps {
  onUpdate(status: string): void
}

export function OrderStatusCtrl(props: OrderStatusCtrlProps) {
  const { onUpdate } = props
  const { t } = useTranslation()

  return (
    <Popover
      trigger={['click']}
      style={{ padding: 0 }}
      content={
        <div style={{ display: 'flex', gap: 5, margin: -8 }}>
          <ActionConfirmation
            title={t('msg_confirm_state_change')}
            message={t('msg_change_paid_only_if_client')}
            onConfirm={() => onUpdate('paid')}
            onDiscard={() => {
              /*noop*/
            }}
          >
            <ActionButton>{t('paids')}</ActionButton>
          </ActionConfirmation>
          <ActionConfirmation
            title={t('msg_confirm_state_change')}
            message={t('msg_change_order_status_rejected')}
            onConfirm={() => onUpdate('rejected')}
            onDiscard={() => {
              /*noop*/
            }}
          >
            <ActionButton>{t('rejecteds')}</ActionButton>
          </ActionConfirmation>
        </div>
      }
      title={false}
    >
      <ButtonStatusUpdate
        key="status-update"
        style={{ border: 'none' }}
        className="text-primary"
        help={t('msg_change_order_status')}
        helpPlacement={'bottom'}
        iconOnly={true}
      />
    </Popover>
  )
}
