import { EAdminSections } from 'types'

import {
  AreaPage,
  CategoryPage,
  ConversionPage,
  Dashboard,
  EmployeePage,
  MenuPage,
  OfferExistencePage,
  OfferPage,
  OrderPage,
  ProductPage,
  ReportsPage,
  ReviewPage,
  SettingPage,
  TablePage,
  TagPage,
  UnitsOfMeasurePage,
} from '../../pages/admin'

// INTERFACES
import { IRoute } from '../types'

const routes: IRoute[] = [
  {
    id: EAdminSections.DASHBOARD,
    path: '',
    page: Dashboard,
    permissions: EAdminSections.DASHBOARD,
  },
  {
    id: EAdminSections.MENUS,
    path: '/menus',
    page: MenuPage,
    permissions: EAdminSections.MENUS,
  },
  {
    id: EAdminSections.OFFERS,
    path: '/offers',
    page: OfferPage,
    permissions: EAdminSections.OFFERS,
  },
  {
    id: EAdminSections.PRODUCTS,
    path: '/products',
    page: ProductPage,
    permissions: EAdminSections.PRODUCTS,
  },
  {
    id: EAdminSections.CATEGORIES,
    path: '/categories',
    page: CategoryPage,
    permissions: EAdminSections.CATEGORIES,
  },
  {
    id: EAdminSections.TAGS,
    path: '/tags',
    page: TagPage,
    permissions: EAdminSections.TAGS,
  },
  {
    id: EAdminSections.ORDERS,
    path: '/orders/:orderStatus',
    page: OrderPage,
    permissions: EAdminSections.ORDERS,
  },
  {
    id: EAdminSections.AREAS,
    path: '/areas',
    page: AreaPage,
    permissions: EAdminSections.AREAS,
  },
  {
    id: EAdminSections.TABLES,
    path: '/tables',
    page: TablePage,
    permissions: EAdminSections.TABLES,
  },
  {
    id: EAdminSections.EMPLOYEES,
    path: '/employees',
    page: EmployeePage,
    permissions: EAdminSections.EMPLOYEES,
  },
  {
    id: EAdminSections.SETTINGS,
    path: '/settings',
    page: SettingPage,
    permissions: EAdminSections.SETTINGS,
  },
  {
    id: EAdminSections.UNITS_MEASURE,
    path: '/units_measure',
    page: UnitsOfMeasurePage,
    permissions: EAdminSections.UNITS_MEASURE,
  },
  {
    id: EAdminSections.CONVERSIONS,
    path: '/conversions',
    page: ConversionPage,
    permissions: EAdminSections.CONVERSIONS,
  },
  {
    id: EAdminSections.REPORTS,
    path: '/reports/:reportType',
    page: ReportsPage,
    permissions: EAdminSections.REPORTS,
  },
  {
    id: EAdminSections.OFFERS_EXISTENCE,
    path: '/offers-existence',
    page: OfferExistencePage,
    permissions: EAdminSections.OFFERS_EXISTENCE,
  },
  {
    id: EAdminSections.REVIEWS,
    path: '/reviews',
    page: ReviewPage,
    permissions: EAdminSections.REVIEWS,
  },
]

export default routes
