import { useLayoutEffect, useState } from 'react'

export const useViewport = () => {
  const [fullHeight, setFullHeight] = useState<number>(window.innerHeight)
  const [fullWidth, setFullWidth] = useState<number>(window.innerWidth)

  useLayoutEffect(() => {
    const resizeEvent = () => {
      setFullHeight(window.innerHeight)
      setFullWidth(window.innerWidth)
    }

    window.addEventListener('resize', resizeEvent)

    return () => window.removeEventListener('resize', resizeEvent)
  }, [])

  return [fullHeight, fullWidth]
}
