// GLOBAL IMPORTS
import React from 'react'
import styled from 'styled-components'

// LOCAL IMPORTS
import { Loader } from '../../common'
import { ViewLayout } from '../../view-layout'
import PageHeader from './PageHeader'
// STYLED
export const PageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
`
// FUNCTIONAL
const Page = ({ title, actions, onBack, children, loading }: IPageProps) => {
  return (
    <Loader loading={loading}>
      <PageContainer className="page-container">
        <ViewLayout topBar={<PageHeader title={title} actions={actions} onBack={onBack} />}>
          {children}
        </ViewLayout>
      </PageContainer>
    </Loader>
  )
}

export interface IPageProps {
  title?: React.ReactNode
  onBack?: () => unknown
  loading?: boolean
  actions?: React.ReactNode[]
  children: React.ReactNode
}

export default Page
