// REACT
import { Select } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

// Tranlsations
import i18n from 'i18n'
// GLOBAL IMPORTS
import { Layout, Typography } from 'antd'
import styled from /*--------------------*/ 'styled-components'

// LOCAL IMPORTS
import { ActionBar } from /*------------------------------*/ 'components/view-layout'
import { ButtonClearSystem, ButtonLogout, ButtonMenu } from 'components/common/Buttons'
import { EAdminSections, ERoles } from /*-------------------------*/ 'types'
import { ViewNotifications } from /*----------------------*/ 'components/notifications/NotificationsSidePanel'
import AppSidebar from /*---------------------------------*/ 'components/layout/AppSidebar'
// CUSTOM HOOKS
import { CommandCenter } from 'components/payment/PaymentModal'
import { Devices, useDevice } from 'hooks/device.hook'
import {
  INavDefinition,
  INavItemDefinition,
  IRoute,
  useHash,
  useLocation,
  useLogin,
  useRoute2Nav,
  useRoutes,
} from /*-----------------------*/ 'hooks/route.hook'
import { NotificationButton } from 'components/notifications/NotificationsLayoutButton'
import { PageMode } from 'pages/admin'
import { SessionsRepository } from 'models/admin/sessions.repository'
import { showError, showSuccess } from 'components/common/Notification'
import { useAuth } from /*------*/ 'hooks/auth.hook'
import { useTranslation } from 'react-i18next'
import { useViewport } from /*--*/ 'hooks/viewport.hook'
import moment from 'moment'

const { Content } = Layout
const { Title } = Typography

const sessionsRepo = new SessionsRepository()

const ContentContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const AdminLayout = ({ children, authorizedRoutes = [] }: IAdminLayoutProps) => {
  const ADMIN_NAV_DEFINITION: INavDefinition = {
    dashboard: {
      label: i18n.t("sidebar_dashboard"),
    },
    menuSection: {
      label: i18n.t("sidebar_menu_configuration"),
      children: {
        [EAdminSections.MENUS]: {
          label: i18n.t("sidebar_menu"),
        },
        [EAdminSections.OFFERS]: {
          label: i18n.t("sidebar_offers"),
        },
        [EAdminSections.OFFERS_EXISTENCE]: {
          label: i18n.t("sidebar_offerstock"),
        },
        [EAdminSections.PRODUCTS]: {
          label: i18n.t("sidebar_products"),
        },
        [EAdminSections.CATEGORIES]: {
          label: i18n.t("sidebar_categories"),
        },
        [EAdminSections.TAGS]: {
          label: i18n.t("sidebar_tags"),
        },
      },
    },
    orderSection: {
      label: i18n.t("sidebar_orders"),
      permissions: EAdminSections.ORDERS,
      children: {
        ordersInProgress: {
          label: i18n.t("sidebar_inprocess"),
          link: "/admin/orders/inProgress",
        },
        ordersPaid: {
          label: i18n.t("sidebar_paid"),
          link: "/admin/orders/paid",
        },
        ordersRejected: {
          label: i18n.t("sidebar_rejected"),
          link: "/admin/orders/rejected",
        },
      },
    },
    [EAdminSections.AREAS]: {
      label: i18n.t("sidebar_areas"),
    },
    [EAdminSections.TABLES]: {
      label: i18n.t("sidebar_tables"),
    },
    [EAdminSections.EMPLOYEES]: {
      label: i18n.t("sidebar_employees"),
    },
    [EAdminSections.REVIEWS]: {
      label: i18n.t("sidebar_reviews"),
    },
    reportsSection: {
      label: i18n.t("sidebar_reports"),
      permissions: EAdminSections.REPORTS,
      children: {
        inventory: {
          label: i18n.t("sidebar_inventory"),
          link: "/admin/reports/inventory",
        },
        orderStats: {
          label: i18n.t("sidebar_orderstats"),
          link: "/admin/reports/statistics",
        },
        billReport: {
          label: i18n.t("bill_report"),
          link: "/admin/reports/bill_report",
        },
        dailyReport: {
          label: i18n.t("sidebar_dailyreport"),
          link: "/admin/reports/daily_report",
        },
        logReport: {
          label: i18n.t("log_report"),
          link: "/admin/reports/log_report",
        },
      },
    },
    settingsSection: {
      label: i18n.t("sidebar_settings"),
      children: {
        [EAdminSections.SETTINGS]: {
          label: i18n.t("sidebar_administration"),
        },
        [EAdminSections.UNITS_MEASURE]: {
          label: i18n.t("sidebar_units"),
        },
        [EAdminSections.CONVERSIONS]: {
          label: i18n.t("sidebar_conversions"),
        },
      },
    },
  };

  // HOOKS
  const { logout, user } /*-------------*/ = useAuth() || {}
  const navItems /*---------------*/ = useRoute2Nav(authorizedRoutes, ADMIN_NAV_DEFINITION)
  const device /*-----------------*/ = useDevice()
  const { goLogin } /*------------*/ = useLogin()
  const { activeRoute } /*--------*/ = useRoutes(authorizedRoutes)
  const { pathname } /*-----------*/ = useLocation()
  const [fullHeight, fullWidth] /**/ = useViewport()
  const { hash, setHash } /*--------------*/ = useHash()

  // STATE
  const [activeNavItem, setActiveNavItem] /*--*/ = useState<string>('')
  const [menuVisibility, setMenuVisibility] /**/ = useState(false)

  // MEMOS
  const mode = useMemo((): PageMode => {
    return (hash || PageMode.LISTING) as PageMode
  }, [hash])

  // CALLBACKS
  const setMode = useCallback(
    (mode: PageMode) => {
      setHash(mode)
    },
    [setHash],
  )

  const showNotifications = useCallback(() => {
    setMode(PageMode.NOTIFICATIONS)
  }, [setMode])

  const hideNotifications = useCallback(() => {
    setMode(PageMode.LISTING)
  }, [setMode])

  const handleShowMenu = () => {
    setMenuVisibility(true)
  }

  const handleHideMenu = () => {
    setMenuVisibility(false)
  }

  const handleLogout = logout
    ? () => {
        logout()
        goLogin()
      }
    : undefined

  const handleClearSystem = () => {
    sessionsRepo
      .clearSystem()
      .then(() => {
        showSuccess({ message: t('msg_success_clearing_system') })
      })
      .catch((_e) => {
        showError({
          message: t('msg_error_cleaning_system'),
        })
      })
  }

  const handleNavItemClicked = (itemId: string) => {
    setActiveNavItem(itemId)
  }

  //LANGUAGE TRANSLATION
  const { t } = useTranslation()

  const layoutStyle = {
    minHeight: fullHeight,
    height: fullHeight,
    width: fullWidth,
    backgroundColor: '#fff',
  }

  useEffect(() => {
    if (activeRoute?.id) {
      // Hack for allowing accessing order by status not defined as route
      if (activeRoute.id === 'orders') {
        const orderStatusNavItem = Object.entries(
          ADMIN_NAV_DEFINITION?.orderSection?.children || {},
        ).find(([_id, definition]) => (definition as INavItemDefinition).link === pathname)

        if (orderStatusNavItem) {
          setActiveNavItem(orderStatusNavItem[0])
        }
      } else {
        setActiveNavItem(activeRoute.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cmd = useMemo(() => <CommandCenter />, [])

  const { Option } = Select

  const langs = ['en', 'es']

  const changeAppLang = (lang: string) => {
    i18n.changeLanguage(lang)
    moment.locale(lang)
    localStorage.setItem('lang', lang)
  }

  return (
    <Layout className="admin-layout" style={layoutStyle}>
      <ViewNotifications open={mode === PageMode.NOTIFICATIONS} onClose={hideNotifications} />
      <AppSidebar
        navItems={navItems}
        activeNavItem={activeNavItem}
        onClose={handleHideMenu}
        visible={menuVisibility}
        onClickItem={handleNavItemClicked}
        header={
          <HeaderContainer>
            <Title level={5}>À La Carte</Title>
          </HeaderContainer>
        }
        footer={
          <ActionBar>
            <div style={{ width: 40 }} />
            <ButtonLogout help={t('leaveTxt')} onClick={handleLogout} />
            {(user?.roles?.includes(ERoles.ACCOUNTANT) || user?.roles?.includes(ERoles.ADMIN)) && (
              <ButtonClearSystem onConfirm={handleClearSystem} />
            )}
            <Select
              defaultValue={i18n.languages[0]}
              style={{ width: 60 }}
              onChange={(lang) => changeAppLang(lang)}
            >
              {langs.map((lang) => (
                <Option key={lang} value={lang}>
                  {lang.toUpperCase()}
                </Option>
              ))}
            </Select>
          </ActionBar>
        }
      />
      <Layout
        className="admin-content-layout"
        style={{ backgroundColor: '#fff', height: fullHeight, width: fullWidth }}
      >
        <Content style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch' }}>
          {[Devices.MOBILE, Devices.TABLET].includes(device) ? (
            <ButtonMenu onClick={handleShowMenu} className="button-menu" />
          ) : null}
          <ContentContainer>{children}</ContentContainer>
        </Content>
      </Layout>
      <NotificationButton onClick={showNotifications} />
      {cmd}
    </Layout>
  )
}
export interface IAdminLayoutProps {
  children?: React.ReactNode
  authorizedRoutes?: IRoute[]
  deniedRoutes?: IRoute[]
  activeRoute?: IRoute
}

export default AdminLayout
