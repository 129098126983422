// REACT
import React, { useCallback, useMemo, useState } from 'react'

// GLOBAL IMPORTS

// LOCAL IMPORTS
import { INavItem, NavBar } from './nav'
import { ViewLayout } from '../view-layout'
import ScrollSpy from './ScrollSpy'

const SmartTabs = ({ children, sectionTabs, activeTab, onSectionChange }: ISmartTabsProps) => {
  const [innerActiveTab, setInnerActiveTab] = useState<string | null>(null)

  const currentTab = useMemo(() => {
    return activeTab && onSectionChange ? activeTab : innerActiveTab
  }, [activeTab, onSectionChange, innerActiveTab])

  const handleSectionChange = useCallback(
    (sectionId: string) => {
      setInnerActiveTab(sectionId)
      if (onSectionChange) {
        onSectionChange(sectionId)
      }
    },
    [onSectionChange],
  )

  return (
    <ViewLayout
      topBar={
        <NavBar items={sectionTabs} activeItem={currentTab} onClickItem={handleSectionChange} />
      }
    >
      {children && (
        <ScrollSpy offset={60} activeSection={currentTab} onChange={handleSectionChange}>
          {children}
        </ScrollSpy>
      )}
    </ViewLayout>
  )
}

export interface ITabsProps {
  tabs?: INavItem[]
  activeTab?: string | null
  onTabChange?: (key: string) => void | Promise<void>
}

export interface ISmartTabsProps extends React.HTMLAttributes<HTMLDivElement> {
  sectionTabs: INavItem[]
  activeTab?: string | null
  children?: React.ReactNode
  onSectionChange?: (key: string) => void | Promise<void>
}
export default SmartTabs
