import {
  Carousel as ADCarousel,
  Col,
  message,
  Row,
  Skeleton,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useRef, useState } from "react";
import styled, { css, CSSProperties } from "styled-components";

// LOCAL IMPORTS
import { ButtonAdd, ButtonDelete } from "../common/Buttons";
import { CarouselRef } from "antd/lib/carousel";
import { decodeImageFile } from "../../services/image/image.service";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Carousel = (props: ICarouselProps) => {
  const { t } = useTranslation();
  const {
    onAdd,
    onDelete,
    limit = 99,
    images = [],
    aspectRatio = 16 / 9,
  } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const carousel = useRef<CarouselRef | null>(null);
  const uploadBtn = useRef<HTMLElement>(null);

  const boxHeight = 100 / aspectRatio + "%";

  function next(evt: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    evt.stopPropagation();
    carousel?.current?.next();
  }

  function prev(evt: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    evt.stopPropagation();
    carousel?.current?.prev();
  }

  function handleDeleteImage() {
    onDelete?.(currentImage);
  }

  function triggerUploadImage() {
    if (onAdd && images.length < limit) {
      uploadBtn?.current?.click?.();
    }
  }

  function handleSlideChange(from: number, to: number) {
    setCurrentImage(to);
  }

  function handleAddImage(file: File) {
    decodeImageFile(file)
      .then(onAdd)
      .catch((e) => {
        message.error(e.message);
      });
    return false;
  }

  const CarouselSlides = images.length ? (
    images.map((image, i) => (
      <div key={i} style={{ width: "100%" }}>
        <ImageBox height={boxHeight}>
          {image !== null ? (
            <img
              style={imageStyle}
              src={
                ["http", "https", "data"].includes(image.split(":")[0])
                  ? image
                  : formatInlineImage(image)
              }
              alt=""
            />
          ) : (
            EmptySlide
          )}
        </ImageBox>
      </div>
    ))
  ) : (
    <div style={{ width: "100%" }}>
      <ImageBox height={boxHeight}>{EmptySlide}</ImageBox>
    </div>
  );

  return (
    <CarouselWrapper className="carousel-wrapper">
      <ImageWrapper
        cursor={onAdd && images.length < limit ? "pointer" : "unset"}
        onClick={triggerUploadImage}
      >
        <FrameArrow onClick={prev} side={"left"} visible={images?.length > 1}>
          <LeftOutlined />
        </FrameArrow>
        <FrameArrow onClick={next} side={"right"} visible={images?.length > 1}>
          <RightOutlined />
        </FrameArrow>
        <CarouselContainer>
          <ADCarousel
            autoplay={!onAdd}
            ref={carousel}
            beforeChange={handleSlideChange}
            dots={true}
            effect="fade"
          >
            {CarouselSlides}
          </ADCarousel>
        </CarouselContainer>
      </ImageWrapper>
      {onAdd || onDelete ? (
        <Row style={{ marginTop: "1em" }} gutter={8}>
          <Col span={12}>
            {onAdd && images.length < limit && (
              <ButtonAdd
                type="ghost"
                className="text-primary"
                block
                onClick={triggerUploadImage}
              />
            )}
          </Col>

          <Col span={12}>
            {(images?.length && onDelete && (
              <ButtonDelete
                type="ghost"
                className="text-primary"
                block
                onClick={handleDeleteImage}
              />
            )) ||
              null}
          </Col>
          <ImgCrop
            aspect={aspectRatio}
            grid
            modalTitle={t("msg_image_adjust")}
            modalOk={t("ready")}
            modalCancel={t("cancel")}
          >
            <Upload
              beforeUpload={handleAddImage}
              fileList={[]}
              style={{ width: "100%" }}
            >
              <span ref={uploadBtn}></span>
            </Upload>
          </ImgCrop>
        </Row>
      ) : null}
    </CarouselWrapper>
  );
};

interface FrameArrowProps {
  side?: "left" | "right";
  visible?: boolean;
}
const FrameArrow = styled.span<FrameArrowProps>`
  position: absolute;
  top: 50%;
  margin-top: -5%;
  color: white;
  background: #00000078;
  z-index: 1;
  cursor: pointer;
  font-size: 25px;
  ${({ visible = true }) =>
    !visible &&
    css`
      display: none;
    `}
  ${({ side = true }) =>
    (side === "left" &&
      css`
        left: 0;
        border-radius: 0 8px 8px 0;
      `) ||
    css`
      right: 0;
      border-radius: 8px 0 0 8px;
    `}
`;

interface ICarouselProps {
  aspectRatio?: number;
  images?: Array<string | null>;
  onAdd?: (imageBase64Url: string) => void;
  onDelete?: (currentSlide: number) => void;
  onChange?: (currentSlide: number) => void;
  limit?: number;
  editable?: boolean;
}

interface ImageBoxProps {
  height?: string;
  bordered?: boolean;
}

const ImageBox = styled.div<ImageBoxProps>`
  position: relative;
  width: 100%;
  padding-top: ${(props: any) => props?.height || "50%"};
  border: ${(props: any) => (props.bordered ? "dashed 1px gray" : "none")};
`;

const CarouselWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;
const ImageWrapper = styled.div<{ cursor: "pointer" | "unset" }>`
  display: block;
  position: relative;
  width: 100%;
  cursor: ${({ cursor }) => cursor || "pointer"};
`;
const CarouselContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

const imageStyle: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

const EmptySlide = <Skeleton.Image style={imageStyle} />;

// <!-- UTILITY -->

export interface ImageSpec {
  url?: string;
  content?: string;
}

export function imgUrlParser(image: ImageSpec): string {
  return image?.content || (image?.url as string);
}

export const ImageOpHandler = (
  frames: ImageSpec[],
  setFrames: (frames: ImageSpec[]) => void,
  initialImages?: ImageSpec[]
): [(base64ImageUrl: string) => void, (index: number) => void, () => void] => [
  (base64ImageUrl: string) => {
    setFrames([...frames, { content: base64ImageUrl }]);
  },

  (index: number) => {
    const _frames = [...frames];
    _frames.splice(index, 1);
    setFrames(_frames);
  },

  () => {
    if (initialImages) {
      setFrames(initialImages);
    } else {
      setFrames([]);
    }
  },
];

export const formatImage =
  (baseUrl: string): any =>
  <T extends { image?: { url: string } }>(item: T) => {
    const { image } = item;
    if (image) {
      image.url = baseUrl + image.url;
    }
    return item;
  };

export const formatImages =
  (baseUrl: string): any =>
  <T extends { images?: Array<{ url: string }> }>(item: T) => {
    const { images } = item;
    if (images) {
      images.forEach((image) => {
        image.url = baseUrl + image.url;
      });
    }
    return item;
  };

export const formatInlineImage = (url: string): string => {
  return `/backend` + url;
};

export default Carousel;
