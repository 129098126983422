// GLOBAL IMPORTS
import React from 'react'

// LOCAL IMPORTS
import Drawer, { IDrawerProps } from '../common/Drawer'

const drawerStyle = {
  padding: 0,
  display: 'flex',
  flex: '1 !important',
  alignItems: 'stretch',
  justifyContent: 'stretch',
}

const AppDrawer = ({ children, position = 'right', width = '80%', ...props }: IAppDrawerProps) => {
  return (
    <Drawer position={position} bodyStyle={drawerStyle} width={width} {...props}>
      {children}
    </Drawer>
  )
}

export interface IAppDrawerProps extends IDrawerProps {
  position: 'left' | 'right'
}

export default AppDrawer
