// REACT
import React from 'react'

// GLOBAL IMPORTS
import { Tooltip as ADTooltip } from 'antd'
import { TooltipPlacement as ADTooltipPlacement, TooltipProps } from 'antd/lib/tooltip'

const Tooltip = ({ trigger, ...props }: ITooltipProps) => {
  // trigger ||= ['focus', 'hover']
  if (!trigger) {
    trigger = ['focus', 'hover']
  }
  return <ADTooltip {...props} trigger={trigger} />
}

export type ITooltipProps = TooltipProps
export declare type TooltipPlacement = ADTooltipPlacement
export default Tooltip
