import { List } from 'antd'
import { useRender } from '../../hooks/state.hook'
import InfiniteScroll from 'react-infinite-scroller'
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

export interface InfiniteListProps<T> {
  items: T[]
  renderItem: (item: T) => JSX.Element
  bottomOffset?: number
}

export const InfiniteList = React.memo(function (props: InfiniteListProps<any>) {
  const $visibleItems = useRef<any[]>([])
  const $storedItems = useRef<any[]>([])

  const nextRender = useRender()

  const { items } = props

  useLayoutEffect(() => {
    $storedItems.current = [...items]
    const initialChunk = []
    for (let i = 0; i < 10; i++) {
      const item = $storedItems.current.shift()
      if (item) initialChunk.push(item)
    }
    $visibleItems.current = initialChunk
    nextRender()
  }, [items, nextRender])

  const handleInfiniteOnLoad = useCallback(() => {
    if (!$storedItems.current.length) {
      return
    }

    const chunk = [...$visibleItems.current]

    for (let i = 0; i < 5; i++) {
      const item = $storedItems.current.shift()
      if (!item) break
      chunk.push(item)
    }

    $visibleItems.current = chunk
    nextRender()
  }, [nextRender])

  const storedItems = $storedItems.current
  const visibleItems = $visibleItems.current

  const hasMore = useMemo(() => {
    return !!storedItems.length
  }, [storedItems])

  return (
    <InfiniteContainer>
      <InfiniteScroll
        initialLoad={true}
        pageStart={0}
        loadMore={handleInfiniteOnLoad}
        hasMore={hasMore}
        useWindow={false}
      >
        <List
          dataSource={visibleItems}
          renderItem={props.renderItem}
          style={{ paddingBottom: props.bottomOffset || 0 }}
        />
      </InfiniteScroll>
    </InfiniteContainer>
  )
})

const InfiniteContainer = styled.div`
  overflow: auto;
  height: 100%;
`
