// GLOBAL IMPORTS
import { DownCircleOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'

// LOCAL IMPORTS
import { INavItem } from './types'

const NavBar = ({
  items,
  activeItem,
  onClickItem,
  fullWidth = true,
  horizontal = true,
}: INavBarProps) => {
  const [innerActiveItem, setInnerActiveItem] = useState<string | null>(null)

  const navBarStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: fullWidth ? 'stretch' : 'flex-start',
  }
  const MenuItems = items
    ? items.map((item) => {
        const { id, icon, label, link } = item
        return (
          <Menu.Item key={id} icon={icon}>
            {link ? <a href={link}>{label}</a> : label}
          </Menu.Item>
        )
      })
    : null

  const handleItemClicked = onClickItem
    ? ({ key }: { key: React.ReactText }) => {
        onClickItem(key as string)
      }
    : undefined

  useEffect(() => {
    if (items && activeItem && Array.isArray(items) && items.length) {
      setInnerActiveItem(activeItem || items[0].id)
      if (!activeItem && handleItemClicked) {
        handleItemClicked({ key: items[0].id })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, activeItem])
  return (
    <Menu
      selectedKeys={innerActiveItem ? [innerActiveItem] : []}
      mode={horizontal ? 'horizontal' : 'inline'}
      style={navBarStyle}
      overflowedIndicator={
        <DownCircleOutlined style={{ margin: 0, fontSize: '20px', verticalAlign: 'middle' }} />
      }
      onClick={handleItemClicked}
    >
      {MenuItems}
    </Menu>
  )
}

export interface INavBarProps {
  items: INavItem[]
  activeItem?: string | null
  onClickItem?: (itemKey: string) => void | Promise<void>
  horizontal?: boolean
  fullWidth?: boolean
}

export default NavBar
