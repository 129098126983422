// GLOBAL IMPORTS
import React, { useEffect, useState } from 'react'

// LOCAL IMPORTS
import { List } from '../common'
import { ViewForm, ViewList } from '../view-layout'
import SelectableListItem from './SelectableListItem'

const ListSelector = ({
  values,
  itemKey = '_id',
  items,
  renderItem,
  onDiscard,
  onSelect,
}: IListSelectorProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  // HANDLE INDIVIDUAL ITEM SELECTION
  const handleSelectionChange = onSelect
    ? (itemId: string) => {
        if (selectedItems.includes(itemId)) {
          setSelectedItems(selectedItems.filter((item) => item !== itemId))
        } else {
          setSelectedItems([...selectedItems, itemId])
        }
      }
    : undefined

  // HANDLE CLICK DONE BUTTON, COMPLETE THE SELECTION
  const handleDoneSelecting = onSelect
    ? () => {
        onSelect(selectedItems)
      }
    : undefined

  const handleRenderItem = (item: any) => {
    const isSelected = selectedItems.includes(item[itemKey])
    const handleItemSelectionChange = handleSelectionChange
      ? () => {
          handleSelectionChange(item[itemKey])
        }
      : undefined
    return (
      <SelectableListItem isSelected={isSelected} onSelect={handleItemSelectionChange}>
        {renderItem(item)}
      </SelectableListItem>
    )
  }

  useEffect(() => {
    if (values) {
      setSelectedItems(values)
    }
  }, [values])

  return (
    <ViewForm onDone={handleDoneSelecting} onDiscard={onDiscard}>
      <ViewList items={items} searchable={true}>
        <List renderItem={handleRenderItem} />
      </ViewList>
    </ViewForm>
  )
}

export interface IListSelectorProps {
  values?: string[]
  items: any[]
  selectedItems?: string[]
  itemKey?: string
  renderItem: (item: any) => JSX.Element
  onDiscard?: () => void | Promise<void>
  onSelect?: (selectedItems: string[]) => void | Promise<void>
}

export default ListSelector
