// REACT
import React from 'react'

// GLOBAL IMPORTS
import { Typography } from 'antd'
import styled from 'styled-components'

// LOCAL IMPORTS
import { ActionBar } from '../view-layout'
import { ButtonDelete, ButtonDiscard } from './Buttons'
import Popover, { IPopoverProps } from './Popover'

const { Title } = Typography

const DeleteConfirmation = ({
  title,
  message,
  visible,
  onConfirm,
  onDiscard,
  children,
}: IDeleteConfirmationProps) => {
  const footer = (onConfirm || onDiscard) && (
    <ActionBar>
      {onConfirm && <ButtonDiscard style={{ flex: '1 1 auto' }} onClick={onDiscard} />}
      {onDiscard && (
        <ButtonDelete type="primary" style={{ flex: '1 1 auto' }} onClick={onConfirm} />
      )}
    </ActionBar>
  )
  const content = (
    <Container>
      <TitleBar className="padding-content">
        <Title className="text-primary" level={4}>
          {title}
        </Title>{' '}
      </TitleBar>
      <Content className="padding-content">{message}</Content>
      <Footer>{footer}</Footer>
    </Container>
  )

  const extra: { visible?: boolean } = {}

  if (visible !== undefined) {
    extra.visible = visible
  }

  return (
    <Popover content={content} {...extra} trigger="click">
      {children}
    </Popover>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 0.9);
  max-width: 300px;
`

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
`
const Content = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Footer = styled.div`
  display: flex;
  justify-content: stretch;
`

export interface IDeleteConfirmationProps extends Pick<IPopoverProps, 'visible' | 'children'> {
  title: string
  message: string
  // EVENT HANDLERS
  onConfirm?: () => unknown
  onDiscard?: () => unknown
}
export default DeleteConfirmation
